import text from "../../../shared/textContent.json";
import { abbreviateNumber } from "../../../shared/formatNumber";

export const formatVelocity = (velocity: number) => {
  const {
    main: { points, point, hours }
  } = text;

  const form = velocity === 1 ? point : points;

  const formattedValue = abbreviateNumber(velocity);

  return `+${formattedValue} ${form}/${hours}`;
};
