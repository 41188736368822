import styled from "styled-components";
import { ThemeType } from "../../../../shared/theme";

export const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;
  height: 100%; // Ensure it takes full height of its container
`;

export const Title = styled.div`
  font-size: 1.5rem;
  line-height: 1.75rem;
  font-weight: 700;
`;

export const ScrollableWrapper = styled.div`
  flex: 1;
  overflow-y: auto;
  width: 100%;
  padding-right: 1rem;
`;

export const WrapperList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.875rem;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.75rem;
`;

export const BoxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  width: 100%;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

export const FriendName = styled.span`
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.25rem;
  text-align: start;
`;

export const Bonus = styled.span`
  font-size: 0.825rem;
  line-height: 1rem;
  text-align: start;
  font-weight: bold;
  opacity: 0.75;
`;

export const Avatar = styled.span`
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.buttonSecondaryVariant};
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
`;

export const SvgIcon = styled.svg<{ color: ThemeType }>`
  color: ${({ color }) => color};
`;

export const NumberInTop = styled.div`
  width: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 1.125rem;
`;
