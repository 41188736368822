import { useSelector } from "react-redux";
import { selectBoosters } from "../../../modules/user/user.selectors.ts";
import { BuildListItem } from "../ui/build-list";
import { formatNumber } from "../../../shared/formatNumber";

export const useBoosters = () => {
  const boosters = useSelector(selectBoosters) ?? [];

  const sortedBoosters = [...boosters].sort((boosterA, boosterB) => {
    const itemToMoveLast = 4; //The id of Make it rain booster;

    if (boosterA.booster.id === itemToMoveLast) {
      return 1;
    }
    if (boosterB.booster.id === itemToMoveLast) {
      return -1;
    }
    return boosterA.booster.id - boosterB.booster.id;
  }).reduce((acc, booster) => {
    if (booster.booster.type === 'free') {
      acc.free.push(booster);
    } else {
      acc.paid.push(booster);
    }
    return acc;
  }, { free: [], paid: [] });

  const convertArrayFree = (data): BuildListItem[] => {
    return data.map(item => {
      return {
        id: item.booster.id,
        title: item.booster.name,
        description: `${item.count_per_day}/3`, // 3 is constant
        icon: item.booster.image_base64,
        price: "free",
        boostValueDescription: item.boost_value_description
      }
    });
  };

  const convertArrayUpgrades = (data): BuildListItem[] => {
    return data.map(item => {
      let effectName = '';

      if (item.booster.click_value_boost) {
        effectName = "Click";
      } else if (item.booster.background_earning_boost) {
        effectName = "Earning";
      } else if (item.booster.max_energy_boost) {
        effectName = "Energy";
      }

      const price = item.lvl === 0 ? item.booster.price : item.booster.price * Math.pow(item.booster.procent_per_lvl, item.lvl);

      return {
        id: item.booster.id,
        title: item.booster.name,
        description: `${item.booster.description} | LVL ${item.lvl + 1}`,
        icon: item.icon,
        price: formatNumber(price),
        lvl: item.lvl + 1,
        effectName: effectName,
        boostValueDescription: formatNumber(item.boost_value_description),
        disabled: item.lvl === item.booster.max_level
      };
    });
  };

  const paidBoosters = convertArrayUpgrades(sortedBoosters.paid);
  const freeBoosters= convertArrayFree(sortedBoosters.free);

  return {paidBoosters, freeBoosters}


}