export type NextCheckinInfo = {
  now: boolean;
  available_at: string;
  next_checkin_reward_points: number;
};

export type GameSession = {
  started_at: string;
  ended_at: string;
  points_earned: number;
  user_id: number;
};

export type Referee = {
  platform: string;
  id: number;
};

export type Booster = {
  booster: {
    name: string;
    type: string;
    duration: number;
    price: number;
    procent_per_lvl: number;
    background_earning_boost: number;
    click_value_boost: number;
    max_energy_boost: number;
    description?: string;
    max_level: number;
    id: number;
  };
  lvl: number;
  activated_at: string;
  expires_at: string;
  user_id: number;
  boost_value_description: string;
};

export enum TASK_STATUSES {
  TODO = "to_do",
  INPROGRESS = "in_progress",
  COMPLETED = "completed"
}

export enum TASK_TYPES {
  Follow = "follow",
  Connect = "connect",
  Explore = "explore",
  Hold = "hold"
}

export enum PLATFORMS {
  Twitter = "twitter",
  Tg = "telegram",
  Sui = "sui"
}

export type RegularTask = {
  regular_task: {
    id: number;
    platform: PLATFORMS;
    reward: number;
    description: string;
    reward_tickets: number;
    repeat_interval_minutes: null | number;
    type: TASK_TYPES;
    type_partner: string;
    sponsored: boolean;
    url: string;
  };
  available_now: boolean;
  next_time_available_at: null | string;
  completed_at: null | string;
  id: number;
  status: TASK_STATUSES;
};

export type Stats = {
  id: number;
  total_earned_points: number;
  total_click_count: number;
  total_background_points: number;
};

export type TelegramAuthRequest = {
  id: string;
  authorized_at: string;
};

export type TelegramWebAuth = {
  telegram_auth_request_id: string;
};

export type TelegramAuthResponse = {
  jwt: string;
  front_authorization_url: string;
};

export type BackgroundEarningInfo = {
  can_claim_now: boolean;
  points_to_claim: number;
  last_background_earning: string;
  new_last_background_earning_after_claim: string;
  period: string;
  claim_will_available_at: string;
};

export type MeResponse = {
  id: number;
  username: string;
  auth_provider: string;
  points: number;
  points_per_click: number;
  background_earning_rate: number;
  energy_remaining: number;
  max_energy: number;
  last_checkin: string;
  last_tap: string;
  last_background_earning: string;
  energy_updated_at: string;
  telegram_auth_requests: TelegramAuthRequest[];
  daily_game_highest_score: number;
  last_daily_game_played_at: string;
  game_sessions: GameSession[];
  referees: Referee[];
  referred: Referee[];
  boosters: Booster[];
  regular_tasks: RegularTask[];
  remaining_points_to_claim: number;
  point_per_clicks_with_booster: number;
  stats: Stats;
  next_checkin_info: NextCheckinInfo;
  current_checkin_days: number;
  can_play_daily_game_now: boolean;
  background_earning_info: BackgroundEarningInfo;
  level: number;
  next_daily_game_available_at: string;
  next_daily_cipher_available_at: string;
  session_id: string;
  onboarding_completed: boolean;
  sui_wallet_address: string | null;
};

export type TapResponse = {
  points_earned: number;
  user: MeResponse;
};

export type UserState = {
  loading: boolean;
  count: number; //points
  countPerTime: number;
  energy: number; //energy_remaining;
  maxEnergy: number;
  id: number;
  username: string;
  authProvider: string;
  backgroundEarningRate: number;
  lastBackgroundEarning: string;
  lastTap: string;
  lastCheckIn: string;
  energyUpdatedAt: string;
  dailyGameHighestScore: number;
  lastDailyGamePlayedAt: null | string;
  nextDailyGameAvailableAt: null | string;
  gameSessions: GameSession[];
  referees: Referee[];
  referred: Referee[];
  telegramAuthRequests: TelegramAuthRequest[];
  boosters: Booster[];
  regularTasks: RegularTask[];
  stats: Stats | null;
  remainingPointsToClaim: number;
  pointPerClicksWithBooster: number;
  currentCheckinDays: number;
  nextCheckinInfo: NextCheckinInfo | null;
  canPlayDailyGameNow: boolean;
  isBackgroundClaimModalShowed: boolean;
  backgroundEarningInfo: BackgroundEarningInfo | null;
  level: number;
  nextDailyCipherAvailableAt: string | null;
  canDoDailyCipher: boolean;
  sessionId: string;
  onboardingCompleted: boolean;
  wallet: string | null;
};

export type CorbadoRequest = {
  token: string;
  referral_id: string;
};
