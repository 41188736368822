import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { WebAppInitData } from "@twa-dev/types";
import {
  CorbadoRequest,
  MeResponse,
  TapResponse,
  TelegramAuthResponse,
  TelegramWebAuth
} from "./user/types.ts";
import { LeaderboardResponse } from "./leaderboard/types.ts";
import { getQueryVariable } from "./auth/utils.ts";

import { DailyCipherResponse } from "./daily_cipher/types.ts";
import { GameResponse, GameRequest } from "./games/types.ts";
import { PostRequest, WalletActionRequest } from "./types.ts";

const BASE_URL = import.meta.env.VITE_BASE_URL;

export const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    mode: "cors",
    prepareHeaders: (headers, { getState }) => {
      let token = getState().auth.token;
      if (!token) {
        token = localStorage.getItem("cbo_short_session");
      }
      if (!token) {
        token = localStorage.getItem("jwt");
      }
      if (!token) {
        token = getQueryVariable("jwt");
      }

      headers.set("Content-Type", "application/json");
      headers.set("Access-Control-Allow-Origin", "*");
      headers.set("Accept", "application/json");

      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    }
  }),
  endpoints: (builder) => ({
    getMe: builder.query<MeResponse, void>({
      query: (queryArg) => ({ url: `/me`, method: "GET" })
      //providesTags: ["Me"]
    }),
    postTaps: builder.mutation<TapResponse, PostRequest>({
      query: (queryArg) => ({ url: `/tap`, method: "POST", body: queryArg }),
      invalidatesTags: ["Taps"]
    }),
    authWithTwitter: builder.query<string, void>({
      query: () => ({ url: `/get_twitter_auth_url`, method: "GET" })
    }),
    authWithTwitterv2: builder.query<string, void>({
      query: () => ({ url: `/get_twitter_auth_urlv2`, method: "GET" })
    }),
    authWithTelegram: builder.mutation<string, WebAppInitData>({
      query: (queryArg) => ({
        url: `/telegram_webapp_auth_get_url`,
        method: "POST",
        body: queryArg
      })
    }),
    authWithWebTelegram: builder.query<TelegramAuthResponse, TelegramWebAuth>({
      query: (queryArg) => ({
        url: `/telegram_auth_request_check_status`,
        method: "GET",
        params: queryArg
      })
    }),
    postBooster: builder.mutation<MeResponse, PostRequest>({
      query: (queryArg) => ({ url: `/booster`, method: "POST", body: queryArg }),
      invalidatesTags: ["Taps"]
    }),
    postDailyGameScore: builder.mutation<MeResponse, PostRequest>({
      query: (queryArg: PostRequest) => ({
        url: `/daily_game`,
        method: "POST",
        body: queryArg
      })
    }),
    postGameScore: builder.mutation<GameResponse, PostRequest>({
      query: (queryArg: GameRequest) => ({
        url: `/game_played/${queryArg.gameId}`,
        method: "POST",
        body: queryArg.body
      })
    }),
    postDailyCheckin: builder.mutation<MeResponse, PostRequest>({
      query: (queryArg) => ({
        url: `/daily_checkin`,
        method: "POST",
        body: queryArg
      })
    }),
    postClaimBgPoints: builder.mutation<MeResponse, PostRequest>({
      query: (queryArg) => ({
        url: `/claim_background_points`,
        method: "POST",
        body: queryArg
      })
    }),
    corbadoVerify: builder.query<void, CorbadoRequest>({
      query: (params) => ({
        url: `/corbado_verify`,
        method: "GET",
        params
      })
    }),
    getFriendById: builder.query<MeResponse, void>({
      query: (params) => ({
        url: `/friend/${params.friend_id}`,
        method: "GET"
      })
    }),
    postRegularTask: builder.mutation<MeResponse, PostRequest>({
      query: (queryArg) => ({
        url: `/check_regular_task`,
        method: "POST",
        body: queryArg
      })
    }),
    postLinkSuiWallet: builder.mutation<MeResponse, WalletActionRequest>({
      query: (queryArg) => ({
        url: "/link_sui_wallet",
        method: "POST",
        body: queryArg
      })
    }),
    deleteSuiWallet: builder.mutation<MeResponse, void>({
      query: () => ({
        url: "/link_sui_wallet",
        method: "DELETE"
      })
    }),
    getTelegramUuid: builder.query<string, void>({
      query: () => ({
        url: `/get_uuid_to_link_telegram`,
        method: "GET"
      })
    }),
    getDailyCipher: builder.query<DailyCipherResponse, void>({
      query: () => ({
        url: `/get_daily_cipher`,
        method: "GET"
      })
    }),
    postDailyCipher: builder.mutation<MeResponse, PostRequest>({
      query: (body) => ({
        url: `/solve_daily_cipher`,
        method: "POST",
        body
      })
    }),
    getLeaderboard: builder.query<LeaderboardResponse, void>({
      query: () => ({
        url: `/leaderboard`,
        method: "GET"
      })
    }),
    getGames: builder.query<GameResponse[], void>({
      query: () => ({
        url: `/games`,
        method: "GET"
      })
    }),
    buyGames: builder.mutation<string, GameRequest>({
      query: (params) => ({
        url: `/buy_game/${params.gameId}`,
        method: "POST",
        body: params.body
      })
    }),
    completeOnboarding: builder.mutation<void, PostRequest>({
      query: (queryArg) => ({
        url: `/complete_onboarding`,
        method: "POST",
        body: queryArg
      })
    })
  })
});

export const {
  usePostTapsMutation,
  usePostBoosterMutation,
  usePostDailyGameScoreMutation,
  usePostGameScoreMutation,
  usePostDailyCheckinMutation,
  usePostClaimBgPointsMutation,
  usePostRegularTaskMutation,
  usePostLinkSuiWalletMutation,
  useLazyGetTelegramUuidQuery,
  usePostDailyCipherMutation,
  useGetLeaderboardQuery,
  useBuyGamesMutation,
  useCompleteOnboardingMutation,
  useDeleteSuiWalletMutation
} = api;

export default api;
