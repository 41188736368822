import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  BackgroundEarningInfo,
  Booster,
  MeResponse,
  NextCheckinInfo,
  RegularTask,
  UserState
} from "./types.ts";
import { State } from "../store.ts";
import { showModal } from "../../shared/showModal";
import { ErrorModal } from "../../components/Modals/ErrorModal";
import api from "../api.ts";

const INITIAL_STATE: UserState = {
  loading: false,
  count: 0,
  countPerTime: 0,
  energy: 100,
  maxEnergy: 100,
  id: 0,
  username: "",
  authProvider: "",
  backgroundEarningRate: 0,
  lastBackgroundEarning: "",
  lastTap: "",
  energyUpdatedAt: "",
  dailyGameHighestScore: 0,
  lastDailyGamePlayedAt: null,
  gameSessions: [],
  referees: [],
  referred: [],
  telegramAuthRequests: [],
  boosters: [],
  regularTasks: [],
  stats: null,
  remainingPointsToClaim: 0,
  pointPerClicksWithBooster: 0,
  currentCheckinDays: 0,
  nextCheckinInfo: null,
  nextDailyGameAvailableAt: null,
  canPlayDailyGameNow: false,
  lastCheckIn: "",
  isBackgroundClaimModalShowed: false,
  backgroundEarningInfo: null,
  level: 0,
  nextDailyCipherAvailableAt: "",
  canDoDailyCipher: false,
  sessionId: "",
  onboardingCompleted: true,
  wallet: null
};

export const getUserData = createAsyncThunk<void, void, { state: State }>(
  "user/getUserData",
  async (_, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const result: MeResponse = await dispatch(api.endpoints.getMe.initiate()).unwrap();

      dispatch(setUser(result));
    } catch (error) {
      await showModal<void, { errorText: string }>(ErrorModal, {
        errorText: error.data.detail
      });
    } finally {
      dispatch(setLoading(false));
    }
  }
);

const userSlice = createSlice<UserState>({
  name: "user",
  initialState: INITIAL_STATE,
  reducers: {
    setUser: (state: UserState, { payload }: { payload: MeResponse }) => {
      const {
        points,
        max_energy,
        energy_remaining,
        auth_provider,
        background_earning_rate,
        last_background_earning,
        last_tap,
        energy_updated_at,
        daily_game_highest_score,
        last_daily_game_played_at,
        game_sessions,
        telegram_auth_requests,
        regular_tasks,
        remaining_points_to_claim,
        point_per_clicks_with_booster,
        current_checkin_days,
        next_checkin_info,
        can_play_daily_game_now,
        id,
        username,
        boosters,
        referees,
        stats,
        referred,
        last_checkin,
        background_earning_info,
        level,
        next_daily_game_available_at,
        next_daily_cipher_available_at,
        session_id,
        onboarding_completed,
        sui_wallet_address
      } = payload;

      state.id = id;
      state.username = username;
      state.boosters = boosters;
      state.referees = referees;
      state.referred = referred;
      state.lastCheckIn = last_checkin;
      state.stats = stats;
      state.count = points;
      state.energy = energy_remaining;
      state.maxEnergy = max_energy;
      state.authProvider = auth_provider;
      state.backgroundEarningRate = background_earning_rate;
      state.lastBackgroundEarning = last_background_earning;
      state.lastTap = last_tap;
      state.energyUpdatedAt = energy_updated_at;
      state.dailyGameHighestScore = daily_game_highest_score;
      state.lastDailyGamePlayedAt = last_daily_game_played_at;
      state.gameSessions = game_sessions;
      state.telegramAuthRequests = telegram_auth_requests;
      state.regularTasks = regular_tasks;
      state.remainingPointsToClaim = remaining_points_to_claim;
      state.pointPerClicksWithBooster = point_per_clicks_with_booster;
      state.currentCheckinDays = current_checkin_days;
      state.nextCheckinInfo = next_checkin_info;
      state.canPlayDailyGameNow = can_play_daily_game_now;
      state.backgroundEarningInfo = background_earning_info;
      state.level = level;
      state.nextDailyGameAvailableAt = next_daily_game_available_at;
      state.nextDailyCipherAvailableAt = next_daily_cipher_available_at;
      state.canDoDailyCipher = next_daily_cipher_available_at === null;
      state.sessionId = session_id;
      state.onboardingCompleted = onboarding_completed;
      state.wallet = sui_wallet_address;
    },
    setLoading: (state: UserState, { payload }: { payload: boolean }) => {
      state.loading = payload;
    },
    setCount: (state: UserState, { payload }: { payload: number }) => {
      state.count = payload;
    },
    setCountPerTime: (state: UserState, { payload }: { payload: number }) => {
      state.countPerTime = payload;
    },
    setEnergy: (state: UserState, { payload }: { payload: number }) => {
      state.energy = payload;
    },
    setMaxEnergy: (state: UserState, { payload }: { payload: number }) => {
      state.maxEnergy = payload;
    },
    setPointsPerClickWithBooster: (state: UserState, { payload }: { payload: number }) => {
      state.pointPerClicksWithBooster = payload;
    },
    setBackgroundEarningRate: (state: UserState, { payload }: { payload: number }) => {
      state.backgroundEarningRate = payload;
    },
    setNextCheckinInfo: (state: UserState, { payload }: { payload: NextCheckinInfo }) => {
      state.nextCheckinInfo = payload;
    },
    setBackgroundEarningInfo: (
      state: UserState,
      { payload }: { payload: BackgroundEarningInfo }
    ) => {
      state.backgroundEarningInfo = payload;
    },
    setCanPlayDailyGameNow: (state: UserState, { payload }: { payload: boolean }) => {
      state.canPlayDailyGameNow = payload;
    },
    setNextDailyGameAvailableAt: (state: UserState, { payload }: { payload: string }) => {
      state.nextDailyGameAvailableAt = payload;
    },
    setBgModalShowed: (state: UserState, { payload }: { payload: boolean }) => {
      state.isBackgroundClaimModalShowed = payload;
    },
    setBoosters: (state: UserState, { payload }: { payload: Booster[] }) => {
      state.boosters = payload;
    },
    setTasks: (state: UserState, { payload }: { payload: RegularTask[] }) => {
      state.regularTasks = payload;
    },
    setDailyCipherData: (state: UserState, { payload }: { payload: string | null }) => {
      state.nextDailyCipherAvailableAt = payload;
      state.canDoDailyCipher = payload === null;
    },
    setOnboardingCompleted: (state: UserState, { payload }: { payload: boolean }) => {
      state.onboardingCompleted = payload;
    },
    onTap: (
      state: UserState,
      { payload }: { payload: { count: number; countPerTime: number; energy: number } }
    ) => {
      state.count = payload.count;
      state.energy = payload.energy;
      state.countPerTime = payload.countPerTime;
    },
    setWallet: (state: UserState, { payload }: { payload: string | null }) => {
      state.wallet = payload;
    }
  }
});

export default userSlice.reducer;

export const {
  setCountPerTime,
  setEnergy,
  onTap,
  setUser,
  setLoading,
  setBgModalShowed,
  setCount,
  setMaxEnergy,
  setPointsPerClickWithBooster,
  setBackgroundEarningRate,
  setNextCheckinInfo,
  setCanPlayDailyGameNow,
  setNextDailyGameAvailableAt,
  setBackgroundEarningInfo,
  setBoosters,
  setTasks,
  setDailyCipherData,
  setOnboardingCompleted,
  setWallet
} = userSlice.actions;
