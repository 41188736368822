import React, { FC } from "react";
import * as S from "../friends-list.styles.tsx";
import { FriendIcon } from "../../../assets/FriendIcon.tsx";
import { abbreviateNumber } from "../../../../../shared/formatNumber";
import Number1 from "../../../../../shared/icons/number_1.svg";
import Number2 from "../../../../../shared/icons/number_2.svg";
import Number3 from "../../../../../shared/icons/number_3.svg";

type Props = {
  index?: number;
  username?: string;
  points?: number;
  photo_base64?: string | null;
};

const FriendRow: FC<Props> = ({ index, username, points, photo_base64 }) => {
  return (
    <S.Row>
      <S.Avatar>
        {photo_base64 ? (
          <img src={`data:image/png;base64,${photo_base64}`} alt="avatar" />
        ) : (
          <FriendIcon />
        )}
      </S.Avatar>
      <S.BoxWrapper>
        <S.TextContainer>
          <S.FriendName>{username}</S.FriendName>
          <S.Bonus>{abbreviateNumber(points)} points</S.Bonus>
        </S.TextContainer>
        <S.NumberInTop>
          {index > 0 && index < 4 ? (
            <img src={index === 1 ? Number1 : index === 2 ? Number2 : Number3} alt="number" />
          ) : (
            `#${index}`
          )}
        </S.NumberInTop>
      </S.BoxWrapper>
    </S.Row>
  );
};

export default FriendRow;
