import { FunctionComponent } from "react";
import * as S from "../../GameCard.styles.ts";
import { AnimatedText } from "../../../../../../components/AnimatedText";
import { DefaultText } from "../../../../../../components/DefaultText";
import { useCountdown } from "../../../../../../app/hooks/useCountdown.ts";
import { Countdown } from "../../GameCard.styles.ts";
import text from "../../../../../../shared/textContent.json";
import { TextItem } from "../TextItem";
import { formatNumber } from "../../../../../../shared/formatNumber";

type Props = {
  title: string;
  description: string;
  prizePool: number;
  willStartAt: string;
  id: number;
  icon: string;
  background: string;
};

export const NotStartedGame: FunctionComponent<Props> = ({
  title,
  description,
  prizePool,
  willStartAt,
  icon
}) => {
  const { hours, minutes, days } = useCountdown(willStartAt);
  const {
    games: { starts_in, prize_pool }
  } = text;

  const countdown = `${days ? `${days}D ` : ""} ${hours}H ${minutes}M`;
  const prizePoolValue = `$${formatNumber(prizePool)}`;

  return (
    <S.GameRoot>
      <S.Header>
        <S.TitleContainer>
          <S.Icon src={`data:image/png;base64,${icon}`} alt="icon" />
          <AnimatedText $fontSize="1.25rem" $lineHeight="1.75rem">
            {title}
          </AnimatedText>
        </S.TitleContainer>
        <S.TextWrapper dangerouslySetInnerHTML={{ __html: description }} />
      </S.Header>
      <S.Divider />
      <S.Body>
        <TextItem title={prize_pool} value={prizePoolValue} />
      </S.Body>
      <S.Footer>
        <DefaultText>{starts_in}</DefaultText>
        <Countdown>{countdown}</Countdown>
      </S.Footer>
    </S.GameRoot>
  );
};
