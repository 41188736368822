export const isWebView = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  const webViewIdentifiers = ["Instagram", "FBAN", "FBAV", "Twitter", "wv", "WebView"];
  const isUserAgentWebView = webViewIdentifiers.some((identifier) =>
    userAgent.includes(identifier)
  );

  const isIosWebkit = !isUserAgentWebView && !userAgent.includes("Safari");

  const isIOSStandalone = "standalone" in window.navigator && window.navigator.standalone;

  const isAppReferrer = /appname|appscheme/.test(document.referrer); // Adjust based on app-specific schemes

  return isUserAgentWebView || isAppReferrer || isIOSStandalone || isIosWebkit;
};
