import { createSelector } from "@reduxjs/toolkit";
import { DailyGameState, TileMap } from "./types.ts";
import { isNil } from "lodash";
import { State } from "../store.ts";

const selectDailyGameSliceState = (state: State) => state.daily_game as DailyGameState;
const selectTilesByIds = createSelector(
  [selectDailyGameSliceState],
  (dailyGame: DailyGameState) => dailyGame.tilesByIds
);
const selectTiles = createSelector(
  [selectDailyGameSliceState],
  (dailyGame: DailyGameState) => dailyGame.tiles
);
export const selectMappedTiles = createSelector(
  [selectTilesByIds, selectTiles],
  (tilesByIds: string[], tiles: TileMap) => tilesByIds.map((tileId) => tiles[tileId])
);
export const selectScore = createSelector(
  [selectDailyGameSliceState],
  (dailyGame: DailyGameState) => dailyGame.score
);
export const selectBoard = createSelector(
  [selectDailyGameSliceState],
  (dailyGame: DailyGameState) => dailyGame.board
);
export const selectEmptyCells = createSelector([selectBoard], (board: string[][]) =>
  board.reduce((acc: [number, number][], row, y) => {
    const emptyCellsInRow = row
      .map((cell, x) => (isNil(cell) ? [x, y] : null))
      .filter((cell) => cell !== null);
    return acc.concat(emptyCellsInRow as [number, number][]);
  }, [])
);
export const selectHasChanged = createSelector(
  [selectDailyGameSliceState],
  (dailyGame: DailyGameState) => dailyGame.hasChanged
);
export const selectCountdown = createSelector(
  [selectDailyGameSliceState],
  (dailyGame: DailyGameState) => dailyGame.countdown
);
export const selectIsStarted = createSelector(
  [selectDailyGameSliceState],
  (dailyGame: DailyGameState) => dailyGame.isStarted
);
export const selectShouldShowGameOver = createSelector(
  [selectDailyGameSliceState],
  (dailyGame: DailyGameState) => !dailyGame.isStarted && dailyGame.countdown === 0
);
export const selectStartedAt = createSelector(
  [selectDailyGameSliceState],
  (dailyGame: DailyGameState) => dailyGame.startedAt
);
