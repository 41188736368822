import "./PWABadge.css";

import { useRegisterSW } from "virtual:pwa-register/react";
import { useEffect } from "react";
import toast from "react-hot-toast";

function PWABadge() {
  // check for updates every 15 seconds
  const period = 15 * 1000;

  const {
    offlineReady: [offlineReady, setOfflineReady],
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker
  } = useRegisterSW({
    onRegisteredSW(swUrl, r) {
      if (period <= 0) return;
      if (r?.active?.state === "activated") {
        registerPeriodicSync(period, swUrl, r);
      } else if (r?.installing) {
        r.installing.addEventListener("statechange", (e) => {
          const sw = e.target as ServiceWorker;
          if (sw.state === "activated") registerPeriodicSync(period, swUrl, r);
        });
      }
    }
  });

  useEffect(() => {
    if (!needRefresh) return;
    toast(
      (t) => (
        <>
          <span>New content available, click on reload button to update. </span>
          <button
            onClick={() => {
              updateServiceWorker(true);
              setOfflineReady(false);
              setNeedRefresh(false);
              toast.dismiss(t.id);
            }}
          >
            Update
          </button>
        </>
      ),
      {
        duration: Infinity
      }
    );
  }, [needRefresh]);

  useEffect(() => {
    if (offlineReady)
      toast("App ready to work offline", {
        duration: 10000
      });
  }, [offlineReady]);

  function close() {
    setOfflineReady(false);
    setNeedRefresh(false);
  }

  return <div />;
}

export default PWABadge;

/**
 * This function will register a periodic sync check every hour, you can modify the interval as needed.
 */
function registerPeriodicSync(period: number, swUrl: string, r: ServiceWorkerRegistration) {
  if (period <= 0) return;

  setInterval(async () => {
    if ("onLine" in navigator && !navigator.onLine) return;

    const resp = await fetch(swUrl, {
      cache: "no-store",
      headers: {
        cache: "no-store",
        "cache-control": "no-cache"
      }
    });

    if (resp?.status === 200) await r.update();
  }, period);
}
