import styled from "styled-components";
import gridImage from "../../../../assets/grid.png";
import { Container } from "../../main.styles.ts";
import { CheckIcon } from "../../../../shared/icons/CheckIcon.tsx";

export const Root = styled.div`
  position: relative;
  width: 100%;
`;

export const DailyWidget = styled(Container)<{ $disabled?: boolean }>`
  align-items: center;
  border: ${({ theme }) => `2px solid ${theme.fontSecondary}`};
  border-radius: 0.75rem;
  padding: 0.5rem 1rem;
  background-image: url(${gridImage});
  background-position: center;
  background-size: cover;
  width: 100%;
  opacity: ${({ $disabled }) => ($disabled ? 0.5 : 1)};
  pointer-events: ${({ $disabled }) => ($disabled ? "none" : "auto")};
  color: ${({ theme }) => theme.fontPrimary};
`;

export const DailyWidgetIcon = styled.img<{ width?: string; height?: string }>`
  width: ${({ width }) => (width ? width : "40px")};
  height: ${({ height }) => (height ? height : "40px")};
  filter: ${({ theme }) => `drop-shadow(0 0 3px ${theme.fontSecondary})`};
  will-change: filter;
  transition: filter 0.2s ease-out;
  margin-bottom: 0.25rem;

  &:active {
    filter: ${({ theme }) => `drop-shadow(0 0 14px ${theme.fontContrast})`};
  }
`;

export const DailyWidgetText = styled.span`
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 1rem;
`;

export const DisabledIcon = styled(CheckIcon)`
  opacity: 1;
  color: ${({ theme }) => theme.borderModal};
  position: absolute;
  top: -0.5rem;
  right: -0.6rem;
  width: 2rem;
  height: 2rem;
`;
