import * as S from "./Price.styles.ts";
import { VelocityIcon } from "../../../../main/assets/VelocityIcon.tsx";
import { theme, ThemeType } from "../../../../../shared/theme";
import { FunctionComponent } from "react";
import { ItemCount } from "../../../../../components/SectionList/SectionList.styles.tsx";

type Props = {
  price?: number;
}

export const Price: FunctionComponent<Props> = ({price}) => {
  return (
    <S.ItemPriceButton>
      <VelocityIcon width={16} height={16} color={theme.fontPrimary as ThemeType} />
      <ItemCount> {price}</ItemCount>
    </S.ItemPriceButton>)
}