import { TILE_COUNTS_PER_DIMENSION } from "../constants.ts";

export const createBoard = () => {
  const board: string[][] = [];

  for (let i = 0; i < TILE_COUNTS_PER_DIMENSION; i += 1) {
    board[i] = new Array(TILE_COUNTS_PER_DIMENSION).fill(undefined);
  }

  return board;
};
