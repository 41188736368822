export const mapDailyCipherIndexes = (example: string, indexes: { [k in string]: number } = {}) => {
  const array = example.split("");

  return array.map((item) => {
    if (item.match(/[a-z]/)) {
      return indexes[item];
    }

    return item;
  });
};
