import { getRandom } from "../utils/getRandom.ts";

type ObstacleOptions = {
  obstacle: HTMLImageElement;
  x: number;
  y: number;
  context: CanvasRenderingContext2D;
  type: number;
  canvasWidth: number;
};

export class Obstacle {
  x: number;
  y: number;
  obstacle: HTMLImageElement;
  height: number;
  width: number;
  type: number;
  xSpeed: number;
  ySpeed: number;
  context: CanvasRenderingContext2D;
  canvasWidth: number;

  constructor({ obstacle, x, y, context, type, canvasWidth }: ObstacleOptions) {
    this.x = x;
    this.y = y;
    this.obstacle = obstacle;
    this.height = 100;
    this.width = 100;
    this.type = type;
    this.xSpeed = 2.5;
    this.ySpeed = -1;
    this.context = context;
    this.canvasWidth = canvasWidth;
  }

  drawObstacle() {
    this.context.drawImage(this.obstacle, this.x, this.y, this.width, this.height);
  }

  moveDownObstacle(value) {
    this.y += -value;
  }

  individualMotion() {
    if (this.type == 2) {
      this.x += this.xSpeed;

      if (this.x >= this.canvasWidth - this.width) {
        this.xSpeed = this.xSpeed * -1;
      }
      if (this.x <= 0) {
        this.xSpeed = this.xSpeed * -1;
      }
    }

    if (this.type == 3) {
      this.x += this.xSpeed;
      this.y += getRandom(-1, 3);
      if (this.x >= this.canvasWidth - this.width) {
        this.xSpeed = this.xSpeed * -1;
      }
      if (this.x <= 0) {
        this.xSpeed = this.xSpeed * -1;
      }
    }

    if (this.type == 4) {
      this.x += this.xSpeed;
      this.y += this.ySpeed;
      if (this.x >= this.canvasWidth - this.width) {
        this.xSpeed = this.xSpeed * -1;
        this.ySpeed = 1;
        this.y += this.ySpeed;
      }
      if (this.x <= 0) {
        this.ySpeed = -1;
        this.xSpeed = this.xSpeed * -1;
        this.y += this.ySpeed;
      }
    }
  }
}
