import { createSelector } from "@reduxjs/toolkit";
import { State } from "../store.ts";
import { UserState } from "./types.ts";

export const selectUserSliceState = (state: State) => state.user as UserState;

export const selectId = createSelector([selectUserSliceState], (userSlice) => userSlice.id);
export const selectReferees = createSelector(
  [selectUserSliceState],
  (userSlice) => userSlice.referees
);
export const selectCount = createSelector([selectUserSliceState], (userSlice) => userSlice.count);
export const selectPointsPerClickWithBooster = createSelector(
  [selectUserSliceState],
  (userSlice) => userSlice.pointPerClicksWithBooster
);
export const selectCountPerTime = createSelector(
  [selectUserSliceState],
  (userSlice) => userSlice.countPerTime
);
export const selectEnergy = createSelector([selectUserSliceState], (userSlice) => userSlice.energy);
export const selectMaxEnergy = createSelector(
  [selectUserSliceState],
  (userSlice) => userSlice.maxEnergy
);
export const selectIsLoading = createSelector(
  [selectUserSliceState],
  (userSlice) => userSlice.loading
);
export const selectUsername = createSelector(
  [selectUserSliceState],
  (userSlice) => userSlice.username
);
export const selectBackgroundEarningRate = createSelector(
  [selectUserSliceState],
  (userSlice) => userSlice.backgroundEarningRate
);
export const selectBoosters = createSelector(
  [selectUserSliceState],
  (userSlice) => userSlice.boosters
);
export const selectDailyGameHighestScore = createSelector(
  [selectUserSliceState],
  (userSlice) => userSlice.dailyGameHighestScore
);
export const selectCanPlayDailyGame = createSelector(
  [selectUserSliceState],
  (userSlice) => userSlice.canPlayDailyGameNow
);

export const selectDailyGameAvailableAt = createSelector(
  [selectUserSliceState],
  (userSlice) => userSlice.nextDailyGameAvailableAt
);

export const selectCurrentCheckinDays = createSelector(
  [selectUserSliceState],
  (userSlice) => userSlice.currentCheckinDays
);

export const selectIsCheckinNow = createSelector(
  [selectUserSliceState],
  (userSlice) => userSlice.nextCheckinInfo?.now
);

export const selectCheckinAvailableAt = createSelector(
  [selectUserSliceState],
  (userSlice) => userSlice.nextCheckinInfo?.available_at
);

export const shouldShowBackgroundClaimModal = createSelector(
  [selectUserSliceState],
  (userSlice) =>
    !userSlice.isBackgroundClaimModalShowed && userSlice.backgroundEarningInfo?.can_claim_now
);

export const selectBackgroundEarningInfoPointsToClaim = createSelector(
  [selectUserSliceState],
  (userSlice) => userSlice.backgroundEarningInfo?.points_to_claim
);

export const selectRegularTasks = createSelector(
  [selectUserSliceState],
  (userSlice) => userSlice.regularTasks
);

export const selectDailyCipherAvailableAt = createSelector(
  [selectUserSliceState],
  (userSlice) => userSlice.nextDailyCipherAvailableAt
);

export const selectCanDoDailyCipher = createSelector(
  [selectUserSliceState],
  (userSlice) => userSlice.canDoDailyCipher
);

export const selectSessionId = createSelector(
  [selectUserSliceState],
  (userSlice) => userSlice.sessionId
);

export const selectIsOnboardingCompleted = createSelector(
  [selectUserSliceState],
  (userSlice) => userSlice.onboardingCompleted
);

export const selectWallet = createSelector([selectUserSliceState], (userSlice) => userSlice.wallet);
