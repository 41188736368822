import * as S from "../sider.styles.ts";

export const DroppyIcon = () => (
  <S.Icon width={24} height={24} viewBox="0 0 24 24">
    <path
      d="M6.38604 6.63288L12.75 0.268921L19.114 6.63288C22.6287 10.1476 22.6287 15.8461 19.114 19.3608C15.5992 22.8755 9.90076 22.8755 6.38604 19.3608C2.87132 15.8461 2.87132 10.1476 6.38604 6.63288Z"
      fill="currentColor"
    />
  </S.Icon>
);
