import { OverlayCard } from "../../OverlayCard";
import { FunctionComponent } from "react";
import { BaseModalOptions } from "../../../shared/showModal";
import { Root, Box, Subtitle, Title } from "../Modals.styles.ts";
import { Button } from "../../Button";
import { usePostClaimBgPointsMutation } from "../../../modules/api.ts";
import {
  setBackgroundEarningInfo,
  setBgModalShowed,
  setCount
} from "../../../modules/user/user.slice.ts";
import { AppDispatch } from "../../../modules/store.ts";
import { useDispatch } from "react-redux";
import text from "../../../shared/textContent.json";
import { useThemedToaster } from "../../../app/hooks/useThemedToaster.ts";
import { useWasmEncrypt } from "../../../app/hooks/useWasmEncrypt.ts";
import { useErrorHandler } from "../../../app/hooks/useErrorHandler.ts";

export interface BackgroundClaimModalOptions extends BaseModalOptions {
  pointsToClaim: number;
}

export const BackgroundClaimModal: FunctionComponent<BackgroundClaimModalOptions> = ({
  onCancelAction,
  pointsToClaim,
  onConfirmAction
}) => {
  const [postClaimBgPoints, { isLoading }] = usePostClaimBgPointsMutation();
  const dispatch = useDispatch<AppDispatch>();
  const { encryptData } = useWasmEncrypt();
  const { showError } = useErrorHandler();
  const {
    modals: {
      background_claim: { subtitle, cta }
    }
  } = text;

  const { toastSuccess } = useThemedToaster();

  const handleClick = () => {
    const body = encryptData();
    postClaimBgPoints(body)
      .unwrap()
      .then((res) => {
        toastSuccess("Background points claimed!");
        dispatch(setCount(res.user.points));
        dispatch(setBackgroundEarningInfo(res.user.background_earning_info));
        dispatch(setBgModalShowed(true));
      })
      .catch((err) => {
        showError(err);
      })
      .finally(() => onCancelAction({}));
  };

  return (
    <OverlayCard onCancelAction={onCancelAction}>
      <Root>
        <Box marginBottom="2rem">
          <Title>{pointsToClaim}</Title>
        </Box>
        <Box marginBottom="2rem">
          <Subtitle marginBottom="0rem">{subtitle}</Subtitle>
        </Box>
        <Button onClick={isLoading ? null : handleClick} disabled={isLoading}>
          {cta}
        </Button>
      </Root>
    </OverlayCard>
  );
};
