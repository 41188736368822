import { PLATFORM_HEIGHT, PLATFORM_WIDTH } from "../constants.ts";
import { Sprites } from "../types.ts";

type PlatformOptions = {
  x: number;
  y: number;
  cxt: CanvasRenderingContext2D;
  type: number;
  sprites: Sprites;
  canvasWidth: number;
};

export class Platform {
  context: CanvasRenderingContext2D;
  xPosition: number;
  platformWidth: number;
  platformHeight: number;
  yPosition: number;
  xSpeed: number;
  platformType: number;
  array: any[];
  fakePlatDetect: number;
  goingTowards: string;
  greenPlatform: HTMLImageElement;
  movingPlatform: HTMLImageElement;
  breakingPlatform: HTMLImageElement;
  yellowPlatform: HTMLImageElement;
  index: number;
  canvasWidth: number;
  jumpCount: number;

  constructor({ x, y, cxt, type, sprites, canvasWidth }: PlatformOptions) {
    this.context = cxt;
    this.xPosition = x;
    this.jumpCount = 0;
    this.platformWidth = PLATFORM_WIDTH;
    this.platformHeight = PLATFORM_HEIGHT;
    this.yPosition = y;
    this.xSpeed = 2;
    this.platformType = type;
    this.array = [];
    this.fakePlatDetect = 0;
    this.goingTowards = "right";
    this.greenPlatform = sprites.greenPlatform;
    this.movingPlatform = sprites.movingPlatform;
    this.breakingPlatform = sprites.breakingPlatform;
    this.yellowPlatform = sprites.yellowPlatform;
    this.canvasWidth = canvasWidth;
    this.index = 0;
  }

  drawPlatform() {
    if (this.platformType == 1) {
      this.context.drawImage(
        this.greenPlatform,
        this.xPosition,
        this.yPosition,
        this.platformWidth,
        this.platformHeight
      );
    }
    if (this.platformType == 2) {
      this.context.drawImage(
        this.movingPlatform,
        this.xPosition,
        this.yPosition,
        this.platformWidth,
        this.platformHeight
      );
    }
    if (this.platformType == 3) {
      //this.context.fillStyle='red';
      this.context.drawImage(
        this.breakingPlatform,
        this.index * 50,
        0,
        50,
        28,
        this.xPosition,
        this.yPosition,
        this.platformWidth,
        28
      );
    }
    if (this.platformType == 4) {
      //this.context.fillStyle='brown';
      this.context.drawImage(
        this.yellowPlatform,
        this.xPosition,
        this.yPosition,
        this.platformWidth,
        this.platformHeight
      );
    }

    //this.context.fillRect(this.xPosition,this.yPosition,this.platformWidth,this.platformHeight);
  }

  movePlatform() {
    if (this.platformType == 2) {
      if (this.xPosition >= this.canvasWidth - this.platformWidth) {
        this.xSpeed = this.xSpeed * -1;
        this.goingTowards = "left";
      }
      if (this.xPosition <= 0) {
        this.xSpeed = this.xSpeed * -1;
        this.goingTowards = "right";
      }
      this.xPosition += this.xSpeed;
    }
  }

  platformSpeed(score) {
    if (this.platformType == 2) {
      if (this.goingTowards == "right") {
        if (score < 100) {
          this.xSpeed = 2;
        }
        if (score >= 100 && score < 250) {
          this.xSpeed = 4;
        }
        if (score >= 250 && score < 400) {
          this.xSpeed = 5;
        }
        if (score >= 400) {
          this.xSpeed = 6;
        }
      }

      if (this.goingTowards == "left") {
        if (score < 100) {
          this.xSpeed = -2;
        }
        if (score >= 100 && score < 250) {
          this.xSpeed = -4;
        }
        if (score >= 250 && score < 400) {
          this.xSpeed = -5;
        }
        if (score >= 400) {
          this.xSpeed = -6;
        }
      }
    }
  }
  removeOverlap(platformArray) {
    this.array = platformArray;
    if (this.array.length == 0) {
      return true;
    } else if (this.array.length != 0) {
      for (let j = 0; j < this.array.length; j++) {
        const ypos = this.array[j].yPosition;
        {
          if (
            (this.yPosition >= ypos && this.yPosition <= ypos + this.platformHeight) ||
            (this.yPosition + this.platformHeight >= ypos &&
              this.yPosition + this.platformHeight <= ypos + this.platformHeight)
          ) {
            return false;
          }
        }
      }
      return true;
    }
  }
}
