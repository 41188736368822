import { OverlayCard } from "../../OverlayCard";
import { FunctionComponent, useEffect } from "react";
import { BaseModalOptions } from "../../../shared/showModal";
import { Box, Root, Title } from "../Modals.styles.ts";
import text from "../../../shared/textContent.json";
import { useDispatch, useSelector } from "react-redux";
import {
  getDailyCipherData,
  setDefaultState
} from "../../../modules/daily_cipher/daily_cipher.slice.ts";
import { DroppyLoader } from "../../DroppyLoader";
import { selectDailyCipherIsLoading } from "../../../modules/daily_cipher/daily_cipher.slice.ts";

import { DailyCipher } from "./components/DailyCipher";

export const DailyCipherModal: FunctionComponent<BaseModalOptions> = ({
  onCancelAction,
  onConfirmAction
}) => {
  const {
    modals: {
      daily_cipher: { title }
    }
  } = text;

  const dispatch = useDispatch();
  const isLoading = useSelector(selectDailyCipherIsLoading);

  useEffect(() => {
    dispatch(getDailyCipherData());
  }, [dispatch]);

  const handleCancel = () => {
    dispatch(setDefaultState());
    onCancelAction({});
  };

  return (
    <OverlayCard onCancelAction={handleCancel}>
      <Root>
        <Box>
          <Box marginBottom="2rem">
            <Title>{title}</Title>
          </Box>
          {isLoading ? <DroppyLoader /> : <DailyCipher onCancel={handleCancel} />}
        </Box>
      </Root>
    </OverlayCard>
  );
};
