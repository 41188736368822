import styled from "styled-components";
import { Link } from "react-router-dom";

export const Routing = styled.div`
  position: fixed;
  z-index: 15;
  bottom: 0;
  max-width: 1280px;

  display: flex;
  justify-content: space-around;
  width: 100%;

  background: ${({ theme }) => theme.bgColor};

  padding: 16px 10px;
`;

export const LinkRouter = styled(Link)<{ active: boolean; $color?: string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${({ theme, $color }) => ($color ? $color : theme.fontSecondary)};
  opacity: ${({ active }) => (active ? 1 : 0.6)};
  filter: ${({ active }) => (active ? "drop-shadow(0 0 3px)" : "unset")};
  transition: opacity 0.2s ease-in-out;
`;

export const Icon = styled.svg`
  color: inherit;
  opacity: inherit;
  justify-self: center;
  margin: 0 auto;
  margin-bottom: 4px;
`;

export const Text = styled.span`
  text-transform: lowercase;
  font-weight: 600;
  font-size: 0.8rem;
  line-height: 1rem;
  color: inherit;
  opacity: inherit;
`;
