import { setCount, setTasks, setWallet } from "../../modules/user/user.slice.ts";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDeleteSuiWalletMutation, usePostLinkSuiWalletMutation } from "../../modules/api.ts";
import { useCurrentAccount, useCurrentWallet, useDisconnectWallet } from "@mysten/dapp-kit";
import { useThemedToaster } from "./useThemedToaster.ts";
import { useErrorHandler } from "./useErrorHandler.ts";
import { useWasmEncrypt } from "./useWasmEncrypt.ts";
import { selectWallet } from "../../modules/user/user.selectors.ts";

type Options = {
  onCancel?: () => void;
  connectedNotificationText?: string;
  disconnectedNotificationText?: string;
};

export const useSuiWallet = ({
  onCancel = () => {},
  connectedNotificationText = "Task completed",
  disconnectedNotificationText = "Wallet disconnected"
}: Options) => {
  const dispatch = useDispatch();
  const account = useCurrentAccount();
  const [postSuiWalletAddress, { isLoading: isWalletConnecting }] = usePostLinkSuiWalletMutation();
  const [deleteSuiWallet, { isLoading: isWalletDeleting }] = useDeleteSuiWalletMutation();
  const { mutate: disconnect } = useDisconnectWallet();
  const { isConnected, isConnecting } = useCurrentWallet();
  const { toastSuccess } = useThemedToaster();
  const { showError } = useErrorHandler();
  const { encryptData } = useWasmEncrypt();
  const dbAddress = useSelector(selectWallet);

  const address = account?.address;
  const isLoading = isWalletDeleting || isWalletConnecting;

  const handleConnectWallet = ({ onError, onCancel }) => {
    const body = encryptData(JSON.stringify(address));
    postSuiWalletAddress(body)
      .unwrap()
      .then((res) => {
        dispatch(setCount(res.user.points));
        dispatch(setTasks(res.user.regular_tasks));
        dispatch(setWallet(address));
        toastSuccess(connectedNotificationText);
      })
      .catch((err) => {
        showError(err, () => onError());
      })
      .finally(() => onCancel());
  };

  useEffect(() => {
    if (isConnected) {
      handleConnectWallet({ onError: disconnect, onCancel });
    }
  }, [isConnected]);

  const handleDisconnectWallet = () => {
    disconnect();

    deleteSuiWallet({})
      .unwrap()
      .then(() => {
        dispatch(setWallet(null));
        toastSuccess(disconnectedNotificationText);
      })
      .catch((err) => {
        showError(err);
      });
  };

  return {
    isConnecting,
    isConnected,
    handleDisconnectWallet,
    address: dbAddress,
    isLoading
  };
};
