import styled from "styled-components";
import { ConnectButton } from "@mysten/dapp-kit";

export const TaskValue = styled.p`
  text-align: center;
  font-size: 2rem;
  font-weight: 700;
  line-height: 2.2rem;
`;
export const TaskDescription = styled.p`
  text-align: center;
  font-size: 1rem;
  line-height: 1.2rem;
  font-weight: 700;
  margin: 0;
`;

export const LinkIMG = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 4px;
`;

export const TaskButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SuiButton = styled(ConnectButton)`
  cursor: pointer;
  width: 100%;
  border-radius: 10px !important;
  background-color: ${({ theme }) => `${theme.fontContrast} !important`};
  font-size: 1.25rem !important;
  font-weight: bold !important;
  line-height: 1.25rem !important;
  padding: 0.875rem 0 !important;
  text-align: center !important;
  margin-bottom: 0 !important;
  color: #fff !important;
  box-shadow:
    inset 0 4px 4px 0 #ffffff40,
    inset 0 -4px 4px 0 #00000040 !important;
`;
