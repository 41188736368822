import styled from "styled-components";

export const TitleContainer = styled.div`
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-align: center;
`;

export const SubtitleContainer = styled.div`
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
`;

export const Divider = styled.hr`
  height: 1px;
  color: #fabcff;
  opacity: 0.2;
  margin-left: -5%;
  margin-right: -5%;
  width: 110%;
`;

export const SvgIcon = styled.svg`
  color: #fabcff;
`;
