import styled from "styled-components";
import { theme } from "../../../../../shared/theme";

export const AchievedContainer = styled.div`
  display: flex;
  gap: 0.25rem;
  padding: 0.375rem;
  align-items: center;
  border: 2px solid ${theme.borderModal};
  color: ${theme.borderModal};
  border-radius: 0.25rem;
`;

export const AchievedText = styled.p`
  margin: 0;
`;
