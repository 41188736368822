import { ItemTitle } from "../../../../components/SectionList/SectionList.styles.tsx";
import styled from "styled-components";

export const Title = styled(ItemTitle)`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  align-self: center;
`;

export const ButtonContainer = styled.div`
  width: 50%;
`;
