export const theme = {
  gray: "#5B5B5B",
  lightGray: "#D9D9D9",
  aquamarine: "#00B3FF",
  fontPrimary: "#fff",
  fontSecondary: "#E8A9EF",
  fontContrast: "#EA05FE",
  sectionMain: "#190534",
  bgColor: "#0B001F",
  bgModalNightBlue: "#0B0217",
  borderModal: "#FABCFF",
  borderSecondary: "#EA05FE80",
  buttonSecondaryVariant: "#46034C",
  buttonAquamarine: "#1953ff",
  border: "#000",
  toastBackground: "#323236",
  switchBackground: "#24074A",
  switchActive: "#490E95"
};

export type ThemeType = typeof theme;
