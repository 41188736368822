import { FunctionComponent } from "react";
import { TILE_COUNTS_PER_DIMENSION } from "../../../modules/daily_game/constants";
import { Tile as TileProps } from "../../../modules/daily_game/types.ts";
import * as S from "./Game.styles.ts";

type Props = TileProps & {
  width: number;
  height: number;
};

export const Tile: FunctionComponent<Props> = ({ value, position, width, height }) => {
  const positionToPixelsWidth = (position: number) =>
    (position / TILE_COUNTS_PER_DIMENSION) * width + (8 - position * 2);
  const positionToPixelsHeight = (position: number) =>
    (position / TILE_COUNTS_PER_DIMENSION) * height + (8 - position * 2);

  const left = positionToPixelsWidth(position[0]);
  const top = positionToPixelsHeight(position[1]);

  return (
    <S.Tile $value={value} $left={left} $top={top} $zIndex={value}>
      {value}
    </S.Tile>
  );
};
