import styled, { keyframes } from "styled-components";

const switchShadow = keyframes`
    0% {
    filter: drop-shadow(-1px 2px 0 #000) drop-shadow(0 0 3px #E8A9EF);
    } 
    50% {
        filter:drop-shadow(-1px 2px 0 #000) drop-shadow(0 0 14px #E8A9EF);
    }
    100% {
        filter: drop-shadow(-1px 2px 0 #000) drop-shadow(0 0 3px #E8A9EF);
    }
`;

export const AnimatedText = styled.span<{ $fontSize?: string; $lineHeight?: string }>`
  font-size: ${({ $fontSize }) => ($fontSize ? $fontSize : "2.5rem")};
  line-height: ${({ $lineHeight }) => ($lineHeight ? $lineHeight : "3rem")};
  font-style: normal;
  font-family: "Orbitron", sans-serif;
  font-weight: 700;
  animation: ${switchShadow} 3s linear infinite;
  display: inline-block;
`;
