import styled, { keyframes } from "styled-components";
import droppy from "../../assets/DroppyStand.png";
import droppyStepOne from "../../assets/DroppyStepOne.png";
import droppyStepTwo from "../../assets/DroppyStepTwo.png";

const walk = keyframes`
  0% {
    background-image: url(${droppy});
  }

  25% {
    background-image: url(${droppyStepOne});
  }
  
  50% {
    background-image: url(${droppy});
  }

  75% {
    background-image: url(${droppyStepTwo});
  }
  
  100% {
    background-image: url(${droppy})
  }
`;

export const Root = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TinyDroppy = styled.div`
  width: 48px;
  height: 48px;
  background-image: url(${droppy});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  animation: ${walk} 1s step-start infinite;
`;
