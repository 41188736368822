export const PLATFORM_WIDTH = 100;
export const PLATFORM_HEIGHT = 15;
export const DOODLE_WIDTH = 40;
export const DOODLE_HEIGHT = 40;
export const BULLET_RADIUS = 5;

export enum Direction {
  Falling = "isFalling",
  Flying = "isFlying"
}

export enum Status {
  Doodle = "isDoodle",
  Enemy = "isEnemy"
}

export enum JoystickForce {
  FullRight = "DDD",
  TwoThirdsRight = "DD",
  OneThirdRight = "D",
  OneThirdLeft = "A",
  TwoThirdLeft = "AA",
  FullLeft = "AAA"
}
