import * as S from "../main.styles.ts";
import { FunctionComponent } from "react";
import { theme, ThemeType } from "../../../shared/theme";

type Props = {
  width?: number;
  height?: number;
  color?: ThemeType;
};

export const VelocityIcon: FunctionComponent<Props> = ({
  width = 20,
  height = 20,
  color = theme.fontSecondary
}) => {
  return (
    <S.BadgeIcon width={width} height={height} color={color} viewBox="0 0 20 20">
      <path
        d="M10.0041 18.3356C5.4017 18.3356 1.67073 14.6047 1.67073 10.0022C1.67073 5.3999 5.4017 1.66895 10.0041 1.66895C14.6064 1.66895 18.3374 5.3999 18.3374 10.0022C18.3374 14.6047 14.6064 18.3356 10.0041 18.3356ZM10.0041 6.46675L6.46853 10.0022L10.0041 13.5378L13.5396 10.0022L10.0041 6.46675Z"
        fill="currentColor"
      />
    </S.BadgeIcon>
  );
};
