import React, { FunctionComponent } from "react";
import text from "../../../../../shared/textContent.json";
import * as S from "./Achieved.styles.ts";
import { CheckIcon } from "../../../../../shared/icons/CheckIcon.tsx";
import { getHoursAndMinutesDiff } from "../utils/getHoursAndMinutesDiff.ts";

type Props = {
  isRepeatTask: boolean;
  repeatAt: string | null;
};

export const Achieved: FunctionComponent<Props> = ({ isRepeatTask, repeatAt }) => {
  const {
    tasks: { achieved, repeat }
  } = text;

  const { hours, minutes } = getHoursAndMinutesDiff(repeatAt);
  const iconText = isRepeatTask ? repeat.replace("${x}", `${hours}H ${minutes}M`) : achieved;

  return (
    <S.AchievedContainer>
      <CheckIcon />
      <S.AchievedText>{iconText}</S.AchievedText>
    </S.AchievedContainer>
  );
};
