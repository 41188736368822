import styled, { keyframes } from "styled-components";
import gridImage from "../../assets/grid.png";

const sectionItem = keyframes`
    0% {
    filter: drop-shadow(-1px 2px 0 #000) drop-shadow(0 0 3px #E8A9EF);
    } 
    50% {
        filter:drop-shadow(-1px 2px 0 #000) drop-shadow(0 0 8px #E8A9EF);
    }
    100% {
        filter: drop-shadow(-1px 2px 0 #000) drop-shadow(0 0 3px #E8A9EF);
    }
`;

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Title = styled.div`
  align-self: flex-start;
  color: #fff;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 700;
  line-height: 30px; /* 100% */
`;

export const WrapperList = styled.div<{ direction?: string }>`
  display: flex;
  flex-direction: ${({ direction }) => direction || "column"};
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 8px;
  gap: 20px;
  padding: 0 10px;
`;

export const Item = styled.div<{ alignTextItems?: string; $disabled?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: ${({ alignTextItems }) => alignTextItems || "flex-start"};
  width: 100%;
  gap: 0.5rem;
  padding: 12px 14px;
  border: 2px solid #fabcff;
  background: ${(props) => props.theme.sectionMain};
  border-radius: 20px;
  background-image: url(${gridImage});
  pointer-events: ${({ $disabled }) => ($disabled ? "none" : "auto")};
  justify-content: space-between;

  ${({ alignTextItems }) =>
    alignTextItems === "flex-end" &&
    `
    padding: 25px;
  `};
`;

export const ItemTitle = styled.h2`
  color: #ffffff;
  margin: 0;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 100% */
`;

export const ItemCount = styled.p`
  color: #ffffff;
  margin: 0;
  font-size: 1.2rem;
  font-style: normal;
  font-family: "Handjet", sans-serif;
  font-weight: 400;
  line-height: 30px; /* 100% */
`;

export const ItemBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
