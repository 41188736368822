import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { DailyCipherResponse, DailyCipherState } from "./types.ts";
import { State } from "../store.ts";

import { getRandomIndexes } from "../../shared/getRandomIndexes";
import api from "../api.ts";
import { showModal } from "../../shared/showModal";
import { ErrorModal } from "../../components/Modals/ErrorModal";

const INITIAL_STATE: DailyCipherState = {
  loading: true,
  cipher: "",
  examples: [],
  answer: 0,
  indexes: getRandomIndexes(),
  userAnswer: ""
};

export const getDailyCipherData = createAsyncThunk<void, void, { state: State }>(
  "daily_cipher/getDailyCipherData",
  async (_, { dispatch }) => {
    dispatch(setDailyCipherLoading(true));
    try {
      const result: DailyCipherResponse = await dispatch(
        api.endpoints.getDailyCipher.initiate()
      ).unwrap();

      dispatch(setDailyCipher(result));
    } catch (error) {
      await showModal<void, { errorText: string }>(ErrorModal, {
        errorText: error.data.detail as string
      });
    } finally {
      dispatch(setDailyCipherLoading(false));
    }
  }
);

const dailyCipherSlice = createSlice<DailyCipherState>({
  name: "daily_cipher",
  initialState: INITIAL_STATE,
  reducers: {
    setDailyCipherLoading: (state: DailyCipherState, { payload }: { payload: boolean }) => {
      state.loading = payload;
    },
    setDailyCipher: (state: DailyCipherState, { payload }: { payload: DailyCipherResponse }) => {
      state.cipher = payload.cipher;
      state.answer = payload.answer;
      state.examples = payload.examples;
    },
    setUserAnswer: (state: DailyCipherState, { payload }: { payload: string }) => {
      state.userAnswer = payload;
    },
    setDefaultState: (state: DailyCipherState) => {
      state.userAnswer = INITIAL_STATE.userAnswer;
    }
  }
});

export default dailyCipherSlice.reducer;

export const { setDailyCipherLoading, setDailyCipher, setUserAnswer, setDefaultState } =
  dailyCipherSlice.actions;

const selectDailyCipherSliceState = (state: State) => state.daily_cipher;

export const selectDailyCipherIsLoading = createSelector(
  [selectDailyCipherSliceState],
  (dailyCipher: DailyCipherState) => dailyCipher.loading
);

export const selectDailyCipherIndexes = createSelector(
  [selectDailyCipherSliceState],
  (dailyCipher: DailyCipherState) => dailyCipher.indexes
);

export const selectDailyCipherExamples = createSelector(
  [selectDailyCipherSliceState],
  (dailyCipher: DailyCipherState) => dailyCipher.examples
);

export const selectDailyCipherCipher = createSelector(
  [selectDailyCipherSliceState],
  (dailyCipher: DailyCipherState) => dailyCipher.cipher
);

export const selectDailyCipherAnswer = createSelector(
  [selectDailyCipherSliceState],
  (dailyCipher: DailyCipherState) => dailyCipher.answer
);

export const selectDailyCipherUserAnswer = createSelector(
  [selectDailyCipherSliceState],
  (dailyCipher: DailyCipherState) => dailyCipher.userAnswer
);
