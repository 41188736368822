import { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import type { Tile as TileModel } from "../../../modules/daily_game/types.ts";
import { Tile } from "./Tile.tsx";
import * as S from "./Game.styles.ts";
import { selectMappedTiles } from "../../../modules/daily_game/daily_game.selectors.ts";

type Props = {
  width: number;
  height: number;
};

export const Tiles: FunctionComponent<Props> = ({ width, height }) => {
  const tiles = useSelector(selectMappedTiles);

  return (
    <S.Tiles>
      {tiles.map(({ id, position, value }: TileModel) => (
        <Tile key={id} position={position} value={value} width={width} height={height} />
      ))}
    </S.Tiles>
  );
};
