export class Button {
  canvas: HTMLCanvasElement;
  ctx: CanvasRenderingContext2D;
  text: string;
  color: string;
  textColor: string;
  x: number;
  y: number;
  width: number;
  height: number;
  onclick: () => void;
  borderRadius: number;

  constructor(
    canvas: HTMLCanvasElement,
    ctx: CanvasRenderingContext2D,
    width: number,
    text: string,
    onclick: () => void,
    borderRadius: number = 10 // Добавляем радиус закругления
  ) {
    this.canvas = canvas;
    this.ctx = ctx;
    this.text = text;
    this.color = "#EA05FE";
    this.textColor = "#FFF";
    this.x = width / 2 - 150;
    this.y = 350 + 15;
    this.width = 300;
    this.height = 0;
    this.onclick = onclick;
    this.borderRadius = borderRadius; // Устанавливаем радиус закругления
    this.canvas.addEventListener("click", this.handleClick.bind(this));
    this.draw();
  }

  drawRoundedRect(x: number, y: number, width: number, height: number, radius: number) {
    this.ctx.beginPath();
    this.ctx.moveTo(x + radius, y);
    this.ctx.lineTo(x + width - radius, y);
    this.ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
    this.ctx.lineTo(x + width, y + height - radius);
    this.ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
    this.ctx.lineTo(x + radius, y + height);
    this.ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
    this.ctx.lineTo(x, y + radius);
    this.ctx.quadraticCurveTo(x, y, x + radius, y);
    this.ctx.closePath();
  }

  draw() {
    this.ctx.fillStyle = this.color;
    this.ctx.font = "700 1.25rem Handjet";

    const textMetrics = this.ctx.measureText(this.text);
    const textHeight = textMetrics.actualBoundingBoxAscent + textMetrics.actualBoundingBoxDescent;
    this.height = textHeight + 20; // Добавляем отступ

    // Рисуем закругленный прямоугольник
    this.drawRoundedRect(this.x, this.y, this.width, this.height, this.borderRadius);
    this.ctx.fill();

    // Рисуем текст кнопки
    this.ctx.fillStyle = this.textColor;
    this.ctx.textAlign = "center";
    this.ctx.textBaseline = "middle";
    this.ctx.fillText(this.text, this.x + this.width / 2, this.y + this.height / 2);
  }

  handleClick(event: MouseEvent) {
    const rect = this.canvas.getBoundingClientRect();
    const clickX = event.clientX - rect.left;
    const clickY = event.clientY - rect.top;
    const isClickInsideButtonOnXAxis = clickX >= this.x && clickX <= this.x + this.width;
    const isClickInsideButtonOnYAxis = clickY >= this.y && clickY <= this.y + this.height;

    if (isClickInsideButtonOnXAxis && isClickInsideButtonOnYAxis) {
      this.onclick();
    }
  }
}
