import { OverlayCard } from "../../OverlayCard";
import { FunctionComponent } from "react";
import { BaseModalOptions } from "../../../shared/showModal";
import { Root, Box, Title } from "../Modals.styles.ts";
import * as S from "./TaskModal.styles.ts";
import { LinkIcon } from "../../../shared/icons";
import { Button } from "../../Button";
import { ButtonMore } from "../../../pages/friends/friends.styles.tsx";
import text from "../../../shared/textContent.json";
import { setCount, setTasks } from "../../../modules/user/user.slice.ts";
import { useLazyGetTelegramUuidQuery, usePostRegularTaskMutation } from "../../../modules/api.ts";
import { useDispatch, useSelector } from "react-redux";
import { useThemedToaster } from "../../../app/hooks/useThemedToaster.ts";
import { openTgWithStartParams } from "../../../shared/openTgWithStartParams.ts";
import "@mysten/dapp-kit/dist/index.css";
import { selectIsTwa } from "../../../modules/app/app.slice.ts";
import { selectToken } from "../../../modules/auth/auth.slice.ts";
import { useWasmEncrypt } from "../../../app/hooks/useWasmEncrypt.ts";
import { useErrorHandler } from "../../../app/hooks/useErrorHandler.ts";
import { useSuiWallet } from "../../../app/hooks/useSuiWallet.tsx";

export interface TaskModalProps extends BaseModalOptions {
  id: number;
  title: string;
  description: number;
  icon?: JSX.Element;
  url: string;
  reward: string;
  isSui: boolean;
  isConnectTelegram: boolean;
  type: string;
}

/*
 * Для задания Connect Sui – всем пользователям кроме iOS+TMA мы показываем кнопку из либы @mysten/dapp-kit.
 * Что происходит после её нажатия нам неведомо.
 *
 * Если у пользователя iOS+TMA, то мы редиректим его на веб-версию приложения, потому что в либе есть код, который не дружит в webView Safari.
 * Чтобы пофиксить мы и добавили этот костыль. На веб-версии происходит сценарий из п.1.
 *
 * */

export const TaskModal: FunctionComponent<TaskModalProps> = ({
  onCancelAction,
  onConfirmAction,
  id,
  title,
  description,
  reward,
  icon,
  url,
  isSui,
  isConnectTelegram,
  type
}) => {
  const {
    modals: {
      task: { verify, connect }
    }
  } = text;

  const [postRegularTask, { isLoading: isPostRegularTaskLoading }] = usePostRegularTaskMutation();
  const [getTelegramUuidQuery, { isLoading: isGetTelegramUuidLoading }] =
    useLazyGetTelegramUuidQuery();

  const dispatch = useDispatch();
  const isTWA = useSelector(selectIsTwa);
  const jwt = useSelector(selectToken);
  const { encryptData } = useWasmEncrypt();
  const { showError } = useErrorHandler();
  const { isConnecting } = useSuiWallet({ onCancel: onCancelAction });

  const { toastSuccess } = useThemedToaster();

  const handleButtonClick = () => {
    window.open(url, "_blank").focus();
  };

  const handleConnectTg = async () => {
    try {
      const uuid = await getTelegramUuidQuery().unwrap();
      toastSuccess("Almost done! Redirecting to Telegram to finish the connection");

      setTimeout(() => openTgWithStartParams(uuid), 2000);
      setTimeout(() => window.location.reload(), 4000);
    } catch (error) {
      if (error.data.detail === "This account is already linked to Telegram") {
        await handleTaskClick();
      } else {
        showError(error);
      }
    } finally {
      onCancelAction({});
    }
  };

  const handleTaskClick = () => {
    const body = encryptData(JSON.stringify({ regular_task_id: id }));
    postRegularTask(body)
      .unwrap()
      .then((res) => {
        dispatch(setCount(res.user.points));
        dispatch(setTasks(res.user.regular_tasks));
        toastSuccess("Task completed");
      })
      .catch((err) => {
        showError(err);
      })
      .finally(() => onCancelAction({}));
  };

  const handleSuiTWAClick = () => {
    toastSuccess("You'll be redirected to web version of the app");

    setTimeout(
      () => window.Telegram.WebApp.openLink(`https://app.droppyonsui.xyz/?jwt=${jwt}`),
      2000
    );
  };

  const handleClick = isConnectTelegram
    ? handleConnectTg
    : isTWA && isSui
      ? handleSuiTWAClick
      : handleTaskClick;

  const buttonText = isConnectTelegram ? connect : verify;

  return (
    <OverlayCard onCancelAction={onCancelAction}>
      <Root>
        <Box marginBottom="3rem">
          {icon}
          <Box marginBottom="2rem">
            <Title marginBottom="2rem">{title}</Title>
            {description ? <S.TaskDescription>{description}</S.TaskDescription> : null}
          </Box>
          {isSui || isConnectTelegram ? null : (
            <Box marginBottom="3rem">
              <ButtonMore onClick={handleButtonClick}>
                <S.TaskButtonWrapper>
                  <S.LinkIMG src={LinkIcon as string} />
                  {type === "follow" ? "Follow" : "Go"}
                </S.TaskButtonWrapper>
              </ButtonMore>
            </Box>
          )}

          <S.TaskValue>{reward}</S.TaskValue>
        </Box>
        {isSui && !isTWA ? (
          <S.SuiButton connectText={buttonText} disabled={isConnecting} />
        ) : (
          <Button
            onClick={handleClick}
            disabled={isPostRegularTaskLoading || isGetTelegramUuidLoading}
          >
            {buttonText}
          </Button>
        )}
      </Root>
    </OverlayCard>
  );
};
