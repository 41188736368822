import * as S from "./OverlayCard.styles.tsx";

import type { FunctionComponent, ReactNode } from "react";
import { BaseModalOptions } from "../../shared/showModal";
import { SvgContainer } from "./OverlayCard.styles.tsx";

type Props = BaseModalOptions & {
  children: ReactNode;
  fullScreen?: boolean;
};

export const OverlayCard: FunctionComponent<Props> = ({ children, onCancelAction, fullScreen }) => {
  return (
    <S.Root fullScreen={fullScreen}>
      <S.Body>
        {onCancelAction ? (
          <SvgContainer>
            <svg
              width="30"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={onCancelAction}
            >
              <rect width="30" height="30" rx="15" fill="#747480" fillOpacity="0.12" />
              <path
                d="M9.6377 20.3774C9.51595 20.2612 9.43571 20.1229 9.39697 19.9624C9.35824 19.7964 9.35824 19.6331 9.39697 19.4727C9.44124 19.3122 9.52148 19.1766 9.6377 19.0659L13.6802 15.0151L9.6377 10.9727C9.52148 10.862 9.44124 10.7264 9.39697 10.5659C9.35824 10.4054 9.35824 10.245 9.39697 10.0845C9.43571 9.91846 9.51595 9.77734 9.6377 9.66113C9.75944 9.53939 9.90055 9.45915 10.061 9.42041C10.2215 9.38167 10.382 9.38444 10.5425 9.42871C10.703 9.46745 10.8413 9.54215 10.9575 9.65283L15 13.7036L19.0425 9.65283C19.1587 9.53662 19.297 9.45915 19.4575 9.42041C19.618 9.38167 19.7757 9.38444 19.9307 9.42871C20.0911 9.46745 20.2323 9.54492 20.354 9.66113C20.4757 9.77734 20.556 9.91846 20.5947 10.0845C20.6335 10.245 20.6335 10.4054 20.5947 10.5659C20.556 10.7209 20.4757 10.8592 20.354 10.981L16.3198 15.0151L20.354 19.0659C20.4757 19.1821 20.556 19.3205 20.5947 19.481C20.6335 19.6359 20.6335 19.7964 20.5947 19.9624C20.556 20.1229 20.4757 20.2612 20.354 20.3774C20.2323 20.4937 20.0911 20.5739 19.9307 20.6182C19.7757 20.6569 19.618 20.6569 19.4575 20.6182C19.297 20.5794 19.1587 20.502 19.0425 20.3857L15 16.335L10.9575 20.3857C10.8413 20.502 10.703 20.5794 10.5425 20.6182C10.382 20.6569 10.2215 20.6569 10.061 20.6182C9.90055 20.5794 9.75944 20.4992 9.6377 20.3774Z"
                fill="#fff"
              />
            </svg>
          </SvgContainer>
        ) : null}
        {children}
      </S.Body>
    </S.Root>
  );
};
