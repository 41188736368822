import { FunctionComponent } from "react";
import { SectionListProps } from "../../../../components/SectionList/types.ts";
import {
  Item,
  ItemBox,
  ItemCount,
  ItemTitle,
  Root,
  Title,
  WrapperList
} from "../../../../components/SectionList/SectionList.styles.tsx";
import { Price } from "./Price";
import text from "../../../../shared/textContent.json";


export type BuildListItem = {
  id: number;
  title: string;
  description: string;
  icon?: string | JSX.Element;
  iconModal?: string | JSX.Element;
  price?: number;
  lvl?: number;
  effectName?: string;
  boostValueDescription?: string;
  disabled?: boolean;
};

export const BuildList: FunctionComponent<SectionListProps<BuildListItem>> = ({
  name,
  list,
  direction,
  alignTextItems,
  onClick = () => {}
}) => {
  const { build: { max_level } } = text;
  return (
    <Root>
      <Title> {name} </Title>
      <WrapperList direction={direction}>
        {list.map((item) => (
          <Item
            key={item.id}
            alignTextItems={alignTextItems}
            $disabled={item.disabled}
            onClick={() => {
              onClick({
                id: item.id,
                title: item.title,
                description: item.description,
                icon: item.iconModal,
                price: item.price,
                lvl: item.lvl,
                effectName: item.effectName,
                boostValueDescription: item.boostValueDescription
              });
            }}
          >
            {item?.icon}
            <ItemBox alignTextItems={alignTextItems}>
              <ItemTitle>{item.title}</ItemTitle>
              { item.disabled ? <span>{max_level}</span> : <ItemCount>{item.description}</ItemCount> }
            </ItemBox>
            {item.disabled ? null : <Price price={item.price} /> }
          </Item>
        ))}
      </WrapperList>
    </Root>
  );
};
