import styled from "styled-components";
import { AnimatedText } from "../../components/AnimatedText/AnimatedText.styles.ts";

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-items: center;
  gap: 1.5rem;
  margin-bottom: 76px; // height of the menu container
`;

export const WrapperBalance = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  width: 100%;
`;

export const BalanceTitle = styled.div`
  font-size: 1.4rem;
  font-weight: 700;

  margin-bottom: 0.5rem;
`;

export const Counter = styled(AnimatedText)`
  margin-bottom: 1.5rem;
`;

export const GuideButton = styled.button`
  width: 146px;
  border-radius: 10px;
  padding: 5px;
  font-size: 1.25rem;
  color: ${(props) => props.theme.fontPrimary};
  background: ${(props) => props.theme.fontContrast};
  filter: drop-shadow(0 0 2px #fabcff40);
  box-shadow: inset 0 4px 4px 0 #ffffff40;
`;

export const Icon = styled.img<{ width: number; height: number }>`
  width: ${({ width }) => width ?? "40px"};
  height: ${({ height }) => height ?? "40px"};
  border-radius: 50%;

  filter: ${({ theme }) => `drop-shadow(0 0 3px ${theme.fontSecondary})`};
  will-change: filter;
  transition: filter 0.2s ease-out;
  margin-bottom: 0.25rem;

  &:active {
    filter: ${({ theme }) => `drop-shadow(0 0 14px ${theme.fontContrast})`};
  }
`;
