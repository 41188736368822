import toast from "react-hot-toast";
import { theme } from "../../shared/theme";

const baseToastTheme = {
  padding: "12px 16px",
  fontSize: "16px",
  lineHeight: "18px",
  fontWeight: 800,
  color: theme.fontPrimary
};

const baseIconTheme = {
  primary: theme.fontPrimary
};

export const useThemedToaster = () => {
  const toastSuccess = (message: string) =>
    toast.success(message, {
      style: {
        ...baseToastTheme,
        backgroundColor: "#2ED504",
        filter: "drop-shadow(0 0 32px #2ED50426), drop-shadow(0 0 16px #2ED50480)"
      },
      iconTheme: {
        ...baseIconTheme,
        secondary: "#2ED504"
      }
    });

  const toastError = (message: string) =>
    toast.error(message, {
      style: {
        ...baseToastTheme,
        backgroundColor: theme.toastBackground
      },
      iconTheme: {
        ...baseIconTheme,
        secondary: theme.toastBackground
      }
    });

  return { toastSuccess, toastError };
};
