import { OverlayCard } from "../../OverlayCard";
import { FunctionComponent } from "react";
import { BaseModalOptions } from "../../../shared/showModal";
import { Box, Root, Subtitle, Title } from "../Modals.styles.ts";
import { Button } from "../../Button/";
import DaysList, { TDay } from "./DaysList/DaysList.tsx";
import { CoinIcon } from "../../../shared/icons";
import { useDispatch, useSelector } from "react-redux";
import { usePostDailyCheckinMutation } from "../../../modules/api.ts";

import {
  selectCurrentCheckinDays,
  selectIsCheckinNow
} from "../../../modules/user/user.selectors.ts";
import text from "../../../shared/textContent.json";
import { useThemedToaster } from "../../../app/hooks/useThemedToaster.ts";
import { setCount, setNextCheckinInfo } from "../../../modules/user/user.slice.ts";
import { useWasmEncrypt } from "../../../app/hooks/useWasmEncrypt.ts";
import { useErrorHandler } from "../../../app/hooks/useErrorHandler.ts";

const mockDays: TDay[] = [
  { id: 1, icon: <img src={CoinIcon as string} />, points: "500" },
  { id: 2, icon: <img src={CoinIcon as string} />, points: "750" },
  { id: 3, icon: <img src={CoinIcon as string} />, points: "1,120" },
  { id: 4, icon: <img src={CoinIcon as string} />, points: "1,690" },
  { id: 5, icon: <img src={CoinIcon as string} />, points: "2,530" },
  { id: 6, icon: <img src={CoinIcon as string} />, points: "3,800" },
  { id: 7, icon: <img src={CoinIcon as string} />, points: "5,700" },
  { id: 8, icon: <img src={CoinIcon as string} />, points: "8,540" },
  { id: 9, icon: <img src={CoinIcon as string} />, points: "12,810" },
  { id: 10, icon: <img src={CoinIcon as string} />, points: "19,220" },
  { id: 11, icon: <img src={CoinIcon as string} />, points: "50,000" }
];

export const DailyCheckinModal: FunctionComponent<BaseModalOptions> = ({
  onCancelAction,
  onConfirmAction
}) => {
  const currentCheckinDays = useSelector(selectCurrentCheckinDays);
  const isCheckinNow = useSelector(selectIsCheckinNow);
  const [postDailyCheckin] = usePostDailyCheckinMutation();
  const { encryptData } = useWasmEncrypt();
  const { showError } = useErrorHandler();

  const dispatch = useDispatch();

  const {
    modals: {
      daily_checkin: { title, subtitle, cta }
    }
  } = text;

  const { toastSuccess } = useThemedToaster();

  const handleClick = () => {
    if (!isCheckinNow) return;

    const body = encryptData();

    postDailyCheckin(body)
      .unwrap()
      .then((res) => {
        dispatch(setCount(res.user.points));
        dispatch(setNextCheckinInfo(res.user.next_checkin_info));
        toastSuccess("Claim success");
      })
      .catch((err) => {
        showError(err);
      })
      .finally(() => onCancelAction({}));
  };

  return (
    <OverlayCard onCancelAction={onCancelAction}>
      <Root>
        <Box>
          <Box marginBottom="1.5rem">
            <Title>{title}</Title>
            <Subtitle>{subtitle}</Subtitle>
          </Box>
          <DaysList days={mockDays} activeDay={currentCheckinDays} isCheckinNow={isCheckinNow} />
        </Box>
        <Button onClick={handleClick} disabled={!isCheckinNow}>
          {cta}
        </Button>
      </Root>
    </OverlayCard>
  );
};
