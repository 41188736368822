import React, { useState } from "react";
import * as S from "./friends.styles";
import FriendsList from "./ui/friends-list";
import dailyRewards from "../../shared/icons/DailyRewards.png";
import { Button } from "../../components/Button";
import text from "../../shared/textContent.json";
import { showModal } from "../../shared/showModal";
import {
  NotificationModal,
  NotificationModalProps
} from "../../components/Modals/NotificationModal";
import { useSelector } from "react-redux";
import {
  selectCount,
  selectId,
  selectReferees,
  selectUsername
} from "../../modules/user/user.selectors.ts";
import WebApp from "@twa-dev/sdk";
import { abbreviateNumber, formatNumber } from "../../shared/formatNumber";
import { useThemedToaster } from "../../app/hooks/useThemedToaster.ts";
import { useGetLeaderboardQuery } from "../../modules/api.ts";
import { LeaderboardResponse } from "../../modules/leaderboard/types.ts";
import { selectAuthStatus } from "../../modules/auth/auth.slice.ts";
import { Statuses } from "../../modules/auth/types.ts";
import { PageRoot } from "../../components/PageRoot";
import { DefaultText } from "../../components/DefaultText";

const FRONTEND_URL = import.meta.env.VITE_FRONTEND_URL;

const FriendsPage = () => {
  const {
    friends: {
      your_friends,
      invite,
      bonus_invite,
      learn_more: {
        learn_more_cta,
        modal: { title, subtitle, modal_cta }
      },
      cta,
      leaderboard
    }
  } = text;

  const referees = useSelector(selectReferees);
  const userId = useSelector(selectId);
  const count = useSelector(selectCount);
  const username = useSelector(selectUsername);
  const authStatus = useSelector(selectAuthStatus);

  const [isAllUsers, setIsAllUsers] = useState(true);
  const { data: leaderboardData, isLoading } = useGetLeaderboardQuery<LeaderboardResponse>(
    {},
    {
      skip: authStatus !== Statuses.Success
    }
  );

  const getUserPositionByPoints = (leaderboard, userPoints) => {
    if (!leaderboard || leaderboard.length === 0) return 0;
    const sortedLeaderboard = [...leaderboard].sort((a, b) => b.points - a.points);

    const position = sortedLeaderboard.findIndex((user) => user.points <= userPoints);

    return position !== -1 ? position + 1 : sortedLeaderboard.length + 1;
  };

  const adjustLeaderboardWithUser = (leaderboard, user) => {
    if (!leaderboard || leaderboard.length === 0) return [];
    const updatedLeaderboard = [...leaderboard, user];

    const sortedLeaderboard = updatedLeaderboard.sort((a, b) => b.points - a.points);

    return sortedLeaderboard.map((user, index) => ({
      ...user,
      position: index + 1
    }));
  };
  const position = getUserPositionByPoints(leaderboardData?.friends_leaderboard, count);

  const currentUserPosition = {
    id: userId,
    username: username,
    points: count,
    position: isAllUsers ? leaderboardData?.position : position,
    photo_base64: leaderboardData?.photo_base64 ?? null
  };

  const friendsLeaderboard = adjustLeaderboardWithUser(
    leaderboardData?.friends_leaderboard,
    currentUserPosition
  );

  const handleLearnMoreClick = () =>
    showModal<NotificationModal, NotificationModalProps>(NotificationModal, {
      title,
      subtitle,
      buttonText: modal_cta
    } as NotificationModalProps);

  const { toastError, toastSuccess } = useThemedToaster();

  const handleInviteClick = () => {
    if (WebApp.initData) {
      const tgLink = `https://t.me/droppy_sui_bot?start=${userId}`;

      navigator.clipboard
        .writeText(tgLink)
        .then(() => {
          toastSuccess("Text copied successfully!");
        })
        .catch((err) => {
          toastError("Failed to copy text:", err);
        });

      WebApp.openTelegramLink(`https://t.me/share/url?url=${encodeURI(tgLink)}`);
    } else {
      const textToCopy = `${FRONTEND_URL}?ref=${userId}`;
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          toastSuccess("Text copied successfully!");
        })
        .catch((err) => {
          toastError("Failed to copy text:", err);
        });
    }
  };

  return (
    <PageRoot>
      <S.WrapperFriends>
        <S.Title>{your_friends}</S.Title>
        <S.Counter>{formatNumber(referees.length)}</S.Counter>
      </S.WrapperFriends>

      <S.WrapperActions>
        <S.WrapperList>
          <S.Item>
            <S.ItemIcon src={dailyRewards as string} />
            <S.ItemTextContainer>
              <S.ItemTitle>{invite}</S.ItemTitle>
              <DefaultText>{bonus_invite}</DefaultText>
            </S.ItemTextContainer>
          </S.Item>
        </S.WrapperList>
        <S.ButtonWrapper>
          <S.ButtonMore onClick={handleLearnMoreClick}>{learn_more_cta}</S.ButtonMore>
          <Button padding="10.5px 0" onClick={handleInviteClick}>
            {cta}
          </Button>
        </S.ButtonWrapper>
      </S.WrapperActions>

      <S.Divider />

      <S.WrapperLeaderboard>
        <S.TitleLeaderboard>
          {leaderboard.title} - {abbreviateNumber(leaderboardData?.all_users_count ?? 0)} players
        </S.TitleLeaderboard>
        <S.SwitchWrapper>
          <S.SwitchButton isActive={isAllUsers} onClick={() => setIsAllUsers(true)}>
            {leaderboard.all}
          </S.SwitchButton>
          <S.SwitchButton isActive={!isAllUsers} onClick={() => setIsAllUsers(false)}>
            {leaderboard.friends}
          </S.SwitchButton>
        </S.SwitchWrapper>
      </S.WrapperLeaderboard>

      {isLoading ? (
        <S.Loader />
      ) : (
        <FriendsList
          friendsList={isAllUsers ? leaderboardData?.leaderboard : friendsLeaderboard}
          currentUser={isAllUsers ? currentUserPosition : currentUserPosition}
        />
      )}
    </PageRoot>
  );
};

export default FriendsPage;
