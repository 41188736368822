import React from "react";
import * as S from "./tasks.styles.ts";

import { showModal } from "../../shared/showModal";
import { TaskModal, TaskModalProps } from "../../components/Modals/TaskModal";
import { useSelector } from "react-redux";

import { selectCount } from "../../modules/user/user.selectors.ts";
import text from "../../shared/textContent.json";
import { TaskListItem, TasksList } from "./ui/tasks-list";
import {
  NotificationModal,
  NotificationModalProps
} from "../../components/Modals/NotificationModal";
import { formatNumber } from "../../shared/formatNumber";
import { PageRoot } from "../../components/PageRoot";
import { useTasks } from "./hooks/useTasks.ts";

const TasksPage = () => {
  const count = useSelector(selectCount);
  const { convertedSuiTasks, convertedSponsoredTasks } = useTasks();

  const {
    tasks: {
      balance,
      how_it_works: {
        how_it_works_cta,
        modal: { title, subtitle, cta }
      },
      section_1,
      section_2
    }
  } = text;

  const handleHowInWorksClick = () => {
    showModal<NotificationModal, NotificationModalProps>(NotificationModal, {
      title,
      subtitle,
      buttonText: cta
    } as NotificationModalProps);
  };

  const handleTaskClick = ({
    id,
    title,
    description,
    icon,
    url,
    isSui,
    isConnectTelegram,
    type,
    reward
  }: TaskListItem) =>
    showModal<never, TaskModalProps>(TaskModal, {
      id,
      title,
      description,
      icon,
      url,
      isSui,
      isConnectTelegram,
      type,
      reward
    } as TaskModalProps);

  return (
    <PageRoot>
      <S.WrapperBalance>
        <S.BalanceTitle>{balance}</S.BalanceTitle>
        <S.Counter>{formatNumber(count)}</S.Counter>
        <S.GuideButton onClick={handleHowInWorksClick}>{how_it_works_cta}</S.GuideButton>
      </S.WrapperBalance>

      {convertedSponsoredTasks.length ? (
        <TasksList
          name={section_1}
          list={convertedSponsoredTasks}
          onClick={handleTaskClick}
          alignTextItems={"center"}
        />
      ) : null}

      <TasksList
        name={section_2}
        list={convertedSuiTasks}
        onClick={handleTaskClick}
        alignTextItems={"center"}
      />
    </PageRoot>
  );
};

export default TasksPage;
