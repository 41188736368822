import { BoardProvider } from "./BoardProvider.tsx";
import { useBoard } from "../hooks/useBoard.ts";
import { GameGrid } from "./GameGrid.tsx";
import { Tiles } from "./Tiles.tsx";
import { MobileSwiper } from "../../MobileSwiper";
import * as S from "./Game.styles.ts";
import { useSelector } from "react-redux";
import { GameOver } from "./GameOver.tsx";
import { selectShouldShowGameOver } from "../../../modules/daily_game/daily_game.selectors.ts";
import { useEffect, useRef, useState } from "react";

export const Board = () => {
  const { handleSwipe } = useBoard();
  const isGameOver = useSelector(selectShouldShowGameOver);

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const ref = useRef(null);

  useEffect(() => {
    const updateDimensions = () => {
      if (ref.current) {
        setWidth(ref.current.offsetWidth);
        setHeight(ref.current.offsetHeight);
      }
    };

    const resizeObserver = new ResizeObserver(() => {
      updateDimensions();
    });

    if (ref.current) {
      updateDimensions();
      resizeObserver.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        resizeObserver.unobserve(ref.current);
      }
    };
  }, []);

  return (
    <BoardProvider>
      <MobileSwiper onSwipe={handleSwipe}>
        <S.Root>
          <S.Board ref={ref}>
            <Tiles width={width} height={height} />
            <GameGrid />
            {isGameOver ? <GameOver /> : null}
          </S.Board>
        </S.Root>
      </MobileSwiper>
    </BoardProvider>
  );
};
