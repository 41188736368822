import styled from "styled-components";
import { AnimatedText } from "../../components/AnimatedText/AnimatedText.styles.ts";

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  align-items: center;
  gap: 1.5rem;
  margin-bottom: 6rem;
`;

export const WrapperBalance = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

export const BalanceTitle = styled.div`
  font-size: 1.4rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
`;


export const GuideButton = styled.button`
  width: 146px;
  border-radius: 10px;
  padding: 5px;
  font-size: 1.25rem;
  color: ${(props) => props.theme.fontPrimary};
  background: ${(props) => props.theme.fontContrast};
  filter: drop-shadow(0 0 2px #fabcff40);
  box-shadow: inset 0 4px 4px 0 #ffffff40;
`;

export const MoneyCount = styled(AnimatedText)`
  margin-bottom: 1.5rem;
`;

export const SectionWrapper = styled.div`
  width: 100%;
`;

export const BoosterWrapper = styled.div`
  display: flex;
  gap: 0.75rem;
`;

export const SectionTitle = styled.h2`
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.75rem;
  margin-bottom: 0.5rem;
  margin-top: 0;
  text-align: start;
`;

export const BoosterTab = styled.div`
  padding: 0.75rem 0.875rem;
  gap: 0.5rem;
  display: flex;
  background-color: ${({theme}) => theme.sectionMain};
  box-shadow: inset 0 4px 4px 0 #FFFFFF26, inset 0 -4px 0 #24044F;
  border-radius: 10px;
  flex-grow: 1;
`;

export const BoosterIcon = styled.img`
  width: 40px;
  height: 40px;
`;

export const BoosterTitle = styled.h3`
  font-weight: 700;
  margin-top: 0;
  font-size: 1.125rem;
  line-height: 1.25rem;
  margin-bottom: 0.25rem;
`;
