import { GameProvider } from "./components/GameProvider.tsx";
import { Countdown } from "./components/Countdown.tsx";
import { Score } from "./components/Score.tsx";
import { Board } from "./components/Board.tsx";
import { FunctionComponent } from "react";

export const DailyGame: FunctionComponent = () => {
  return (
    <GameProvider>
      <Countdown />
      <Score />
      <Board />
    </GameProvider>
  );
};
