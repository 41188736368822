import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import "./index.css";
import { RootModal } from "./components/Modals";

import { Providers } from "./providers";
import * as Sentry from "@sentry/react";

const isProd = import.meta.env.PROD;

isProd
  ? Sentry.init({
      dsn: "https://cad2936d89d653058fc836c8a0f26721@o4508064405848064.ingest.us.sentry.io/4508064408862720",
      integrations: [Sentry.replayIntegration()],
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    })
  : null;

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Providers>
      <App />
      <RootModal />
    </Providers>
  </React.StrictMode>
);
