export const CheckIcon = ({ className }) => {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" fill="none" className={className}>
      <path
        d="M14 2.00016V14.0056C14 14.3708 13.7034 14.6668 13.3377 14.6668H2.66227C2.29651 14.6668 2 14.3633 2 14.0056V1.9947C2 1.62953 2.29663 1.3335 2.66227 1.3335H13.3333C13.7015 1.3335 14 1.63198 14 2.00016ZM7.5286 8.7477L5.87868 7.09776L4.93587 8.04063L7.5286 10.6333L11.2998 6.8621L10.357 5.91928L7.5286 8.7477Z"
        fill="currentColor"
      />
    </svg>
  );
};
