import styled, { keyframes } from "styled-components";
import horizon from "../../assets/horizon.png";

const grid = keyframes`
  from {
      background-position-x: -30rem;
  }
  to{
      background-position-x: 0;
  }
`;

export const GridRoot = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100vw;
  max-height: 330px;
  height: 100%;
`;

export const Grid = styled.div`
    perspective: 10rem;
    flex: 1 0 10rem;
    position: relative;
    bottom: 0;
    overflow: hidden;

    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 7rem;
        background-position-y: bottom;
        background-image: url(${horizon}), linear-gradient(to bottom, transparent 20%, #EA05FE 30%, #EA05FE00 100%);
    }

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: ${({ theme }) =>
          `linear-gradient(to right, ${theme.fontContrast} 7%, transparent 7%), linear-gradient(to bottom, ${theme.fontContrast} 3%, transparent 3%);`};

        background-size: 24px 40px,
        30px 21px;
        transform: rotateX(42deg) scale(2.2) translateZ(2.6rem);
        animation: ${grid} 36s linear infinite;

        box-shadow: 0 0 20px ${({ theme }) => theme.fontContrast},
        0 0 20px ${({ theme }) => theme.fontContrast};
        filter: brightness(1.5);
    }
}
`;
