import styled, { keyframes } from "styled-components";
import { AnimatedText } from "../../components/AnimatedText/AnimatedText.styles.ts";

export const WrapperActions = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1.25rem;
  width: 100%;
`;

export const WrapperFriends = styled.div``;

export const Title = styled.div`
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
`;

export const Counter = styled(AnimatedText)``;

export const WrapperList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0;

  width: 100%;
`;

export const Item = styled.div<{ alignTextItems?: string }>`
  display: flex;
  align-items: flex-start;
  width: 100%;
  gap: 0.5rem;
  padding: 0.75rem 0.875rem;
  background: ${({ theme }) => theme.sectionMain};
  border-radius: 10px;
  box-shadow:
    inset 0 4px 4px 0 #ffffff26,
    inset 0 -4px 4px 0 #24044f;
`;

export const ItemTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

export const ItemTitle = styled.span`
  font-size: 1.125rem;
  line-height: 1.25rem;
`;

export const ItemIcon = styled.img`
  width: 40px;
  height: 40px;
`;

export const ButtonMore = styled.button`
  border-radius: 10px;
  background: ${({ theme }) => theme.buttonSecondaryVariant};
  border: 1px solid ${({ theme }) => theme.fontContrast};
  padding: 0.5rem 1rem;
  cursor: pointer;
  box-shadow:
    inset 0 4px 4px 0 #ffffff40,
    inset 0 -4px 4px 0 #00000040;
  font-size: 1.25rem;
  font-weight: 700;
  color: ${({ theme }) => theme.fontPrimary};
  width: 100%;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: #fabcff33;
`;

export const WrapperLeaderboard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  align-items: flex-start;
`;

export const SwitchWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  background: ${({ theme }) => theme.switchBackground};
  padding: 0.125rem;
  border-radius: 1rem;
`;

export const SwitchButton = styled.div<{ isActive?: boolean }>`
  width: 100%;
  opacity: ${({ isActive }) => (isActive ? 1 : 0.5)};
  background: ${({ isActive, theme }) => (isActive ? theme.switchActive : theme.switchBackground)};
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  padding: 0.5rem;
  font-size: 1.4rem;
`;

export const TitleLeaderboard = styled.div`
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
`;

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Loader = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1); /* Цвет фона */
  border-top: 4px solid #3498db; /* Цвет активной части */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: ${spin} 1s linear infinite; /* Анимация вращения */
`;
