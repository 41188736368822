import { useEffect, useState } from "react";

import { TOGGLE_MODAL } from "../../shared/emitter/constants.ts";
import emitter from "../../shared/emitter/";

import type { ComponentType, FunctionComponent } from "react";

interface RootModalProps {
  isShow: boolean;
}

export interface ModalProps<T = object> extends RootModalProps {
  Component?: ComponentType<T>;
  options: T;
}

export const RootModal: FunctionComponent = () => {
  const [{ Component, isShow, options }, setModal] = useState<ModalProps>({
    Component: undefined,
    isShow: false,
    options: {}
  });

  useEffect(() => {
    emitter.addListener(TOGGLE_MODAL, setModal);
  }, []);

  const props = {
    ...options,
    isShow
  };

  return isShow && Component ? <Component {...props} /> : null;
};
