import { ScoreItem } from "./ScoreItem.tsx";
import * as S from "./Game.styles.ts";
import { useSelector } from "react-redux";
import { selectScore } from "../../../modules/daily_game/daily_game.selectors.ts";
import { selectDailyGameHighestScore } from "../../../modules/user/user.selectors.ts";

export const Score = () => {
  const score = useSelector(selectScore);
  const bestScore = useSelector(selectDailyGameHighestScore);

  return (
    <S.Score>
      <ScoreItem score={score} title="Your score" />
      <ScoreItem score={bestScore} title="Best score" />
    </S.Score>
  );
};
