import styled, { keyframes } from "styled-components";
import { ModalsStyles } from "../Modals.styles.ts";
import { AnimatedText } from "../../AnimatedText/AnimatedText.styles.ts";

export const BoostModalIcon = styled.img`
  width: 3.5rem;
  height: 3.5rem;
  margin-bottom: 0.75rem;
`;

export const BoostTitle = styled(AnimatedText)`
  margin-bottom: 0.75rem;
`;

export const Level = styled.h3`
  font-size: 2rem;
  font-weight: 700;
  line-height: 2.2rem;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
`;

export const Effect = styled.p<ModalsStyles>`
  text-align: center;
  font-size: 1.25rem;
  font-weight: ${({ fontWeight }) => fontWeight ?? "normal"};
  line-height: 1.25rem;
  margin: 0;
  margin-bottom: ${({ marginBottom }) => marginBottom ?? "0rem"};
`;
