import { FunctionComponent } from "react";
import * as S from "./Game.styles.ts";
import { DefaultText } from "../../DefaultText";

type Props = {
  score: number;
  title: string;
};

export const ScoreItem: FunctionComponent<Props> = ({ score, title }) => (
  <S.ScoreItem>
    <DefaultText>{title}</DefaultText>
    <S.ScoreValue>{score}</S.ScoreValue>
  </S.ScoreItem>
);
