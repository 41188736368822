import * as S from "../sider.styles.ts";

export const BuildIcon = () => (
  <S.Icon width="25px" height="24px" viewBox="0 0 25 24">
    <path
      d="M12.25 1L21.75 6.5V17.5L12.25 23L2.75 17.5V6.5L12.25 1ZM12.25 3.311L4.75 7.65311V16.3469L12.25 20.689L19.75 16.3469V7.65311L12.25 3.311ZM12.25 16C10.0409 16 8.25 14.2091 8.25 12C8.25 9.79086 10.0409 8 12.25 8C14.4591 8 16.25 9.79086 16.25 12C16.25 14.2091 14.4591 16 12.25 16ZM12.25 14C13.3546 14 14.25 13.1046 14.25 12C14.25 10.8954 13.3546 10 12.25 10C11.1454 10 10.25 10.8954 10.25 12C10.25 13.1046 11.1454 14 12.25 14Z"
      fill="currentColor" />

  </S.Icon>
);
