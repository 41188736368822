import styled from "styled-components";
import { theme } from "../../shared/theme";

export const Root = styled.div<{ fullScreen?: boolean }>`
  position: fixed;
  color: ${({ theme }) => theme.fontPrimary};
  z-index: 15;
  width: 100%;
  inset: 0;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
  margin: 0;
  background-color: rgba(0, 0, 0, 0.4);
  height: 100vh;
  display: flex;
  align-items: end;
`;

export const Body = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.modalBgColor};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  border: 3px solid ${theme.fontSecondary};
  border-radius: 1.5rem;
  margin: 5px;
  background-color: ${theme.bgModalNightBlue};
`;

export const SvgContainer = styled.div`
  position: absolute;
  top: 18px;
  right: 20px;
  width: 30px;
  height: 30px;
  z-index: 1;
`;
