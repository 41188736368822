import { BULLET_RADIUS, Status } from "../constants.ts";

type BulletOptions = {
  xCentre: number;
  yCentre: number;
  context: CanvasRenderingContext2D;
};

export class Bullet {
  xCentre: number;
  yCentre: number;
  context: CanvasRenderingContext2D;
  radius: number;
  count: number;

  constructor({ xCentre, yCentre, context }: BulletOptions) {
    this.xCentre = xCentre;
    this.yCentre = yCentre;
    this.context = context;
    this.radius = BULLET_RADIUS;
    this.count = 0;
  }

  bulletDraw(status: Status) {
    this.context.beginPath();
    this.context.arc(
      this.xCentre - this.radius,
      this.yCentre - this.radius,
      BULLET_RADIUS,
      0,
      2 * Math.PI
    );
    if (status == Status.Enemy) {
      this.context.fillStyle = "red";
    } else if (status == Status.Doodle) {
      this.context.fillStyle = "green";
    }
    this.context.fill();
    this.context.stroke();
    if (this.count == 0) {
      this.count = 1;
    }
  }

  bulletMove(theta: number, status: Status) {
    if (status == Status.Doodle) {
      if (this.yCentre + BULLET_RADIUS > 0) {
        this.yCentre += -9;
      }
    }
    if (status == Status.Doodle) {
      this.yCentre += 5 * Math.sin(theta);
      this.xCentre += 5 * Math.cos(theta);
    }
  }
}
