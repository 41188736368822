import { PostRequest } from "../types.ts";

export type GameRequest = {
  gameId: number;
  body: PostRequest;
};

export enum GameType {
  TwentyFortyEight = "twenty_fortyeight",
  DoodleJump = "doodle",
  Unknown = "unknown"
}

export enum GameStatus {
  NotStarted = "not_started",
  Active = "active",
  Finished = "finished",
  Archived = "archived"
}

export interface Game {
  id: number;
  type: GameType;
  title: string;
  description: string;
  prize_pool: number;
  will_start_at: string | null;
  will_end_at: string | null;
  results_published_at: string | null;
  price: number;
  icon: string;
  background: string;
  earned_tickets: number;
  status: GameStatus;
  current_available_game_uuid: string | null;
  highest_score: number;
  overall_tickets_earned: string;
}

interface GameObject {
  id: number;
  type: string;
  title: string;
  description: string;
  start_date: string | null; // ISO 8601 date string
  end_date: string | null; // ISO 8601 date string
  results_published_at: string | null; // ISO 8601 date string
  icon_base64: string;
  background_base64: string;
  first_price_points: number;
  price_increase: number;
  total_tickets: number;
  earned_tickets: number;
  points_for_ticket: number;
}

export interface GameResponse {
  game: GameObject;
  highest_score: number;
  times_played: number;
  tickets_earned: number;
  overall_tickets_earned: number;
  next_price: number;
  current_available_game_uuid: string | null;
}

export type GamesState = {
  loading: boolean;
  games: Game[];
  game: Game | null;
};
