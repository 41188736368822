import styled from "styled-components";

export const PageRoot = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5rem 1rem 0;
  align-items: center;
  gap: 1.5rem;
  margin-bottom: 6rem;
`;
