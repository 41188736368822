import { OverlayCard } from "../../OverlayCard";
import { FunctionComponent } from "react";
import { BaseModalOptions } from "../../../shared/showModal";
import { Root, Box, Subtitle } from "../Modals.styles.ts";
import * as S from "./BoostModal.styles.ts";
import dailyCipher from "../../../shared/icons/DailyCipher.png";
import { Button } from "../../Button";
import { usePostBoosterMutation } from "../../../modules/api.ts";
import {
  setBoosters,
  setCount,
  setPointsPerClickWithBooster
} from "../../../modules/user/user.slice.ts";
import { AppDispatch } from "../../../modules/store.ts";
import { useDispatch } from "react-redux";
import text from "../../../shared/textContent.json";
import { useThemedToaster } from "../../../app/hooks/useThemedToaster.ts";
import { useWasmEncrypt } from "../../../app/hooks/useWasmEncrypt.ts";
import { useErrorHandler } from "../../../app/hooks/useErrorHandler.ts";

export interface BoostModalProps extends BaseModalOptions {
  id: number;
  title: string;
  price: number;
  lvl: number;
  description: string;
  boostValueDescription: string;
}

//TODO add fetch boost from BE;
//TODO add post to BE;
//TODO add logic;

export const BoostModal: FunctionComponent<BoostModalProps> = ({
  onCancelAction,
  onConfirmAction,
  id,
  title,
  lvl,
  price,
  description,
  effectName,
  boostValueDescription
}) => {
  const [postBooster, { isLoading }] = usePostBoosterMutation();
  const dispatch = useDispatch<AppDispatch>();
  const { encryptData } = useWasmEncrypt();
  const { showError } = useErrorHandler();
  const {
    modals: {
      boost: { level, cta }
    }
  } = text;

  const { toastSuccess } = useThemedToaster();

  const [descr] = description.split(" | ");

  const handleClick = () => {
    const body = encryptData(JSON.stringify({ booster_id: id }));
    postBooster(body)
      .unwrap()
      .then((res) => {
        toastSuccess("Upgraded!");
        dispatch(setCount(res.user.points));
        dispatch(setBoosters(res.user.boosters));
        dispatch(setPointsPerClickWithBooster(res.user.point_per_clicks_with_booster));
      })
      .catch((err) => {
        showError(err);
      })
      .finally(() => onCancelAction({}));
  };

  return (
    <OverlayCard onCancelAction={onCancelAction}>
      <Root>
        <Box marginBottom="1rem">
          <S.BoostModalIcon src={dailyCipher} />
        </Box>
        <Box marginBottom="2rem">
          <S.BoostTitle>{title}</S.BoostTitle>
          {lvl && <S.Level>{`(${level} ${lvl})`}</S.Level>}
        </Box>
        <Box marginBottom="2rem">
          <Subtitle marginBottom="0rem">{descr}</Subtitle>
        </Box>
        <Box marginBottom="2rem">
          <S.Effect marginBottom="0.25rem">{effectName}</S.Effect>
          <S.Effect fontWeight="bold">{boostValueDescription}</S.Effect>
        </Box>
        <Button onClick={isLoading ? null : handleClick} disabled={isLoading}>
          {`${cta} [${price}]`}
        </Button>
      </Root>
    </OverlayCard>
  );
};
