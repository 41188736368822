import { useEffect } from "react";
import { useDispatch } from "react-redux";

type Options = {
  action: () => void;
};

export const useActionWhenPathnameChanges = ({ action }: Options) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const currentPath = location.pathname;

    return () => {
      if (location.pathname !== currentPath) {
        dispatch(action());
      }
    };
  }, [location.pathname, dispatch]);
};
