import React, { useEffect } from "react";
import PWABadge from "./PWABadge.tsx";
import "./App.css";

import { Toaster } from "react-hot-toast";
import { router } from "./app/router/routers.tsx";
import { RouterProvider } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "./components/Loader";
import { initApp, selectIsWebView } from "./modules/app/app.slice.ts";
import { WebView } from "./components/WebView";
import { FirebaseNotificationProvider } from "./components/FirebaseNotification";
import { useCorbado } from "@corbado/react";
import { handleLogout, selectAuthStatus } from "./modules/auth/auth.slice.ts";
import { Statuses } from "./modules/auth/types.ts";

function App() {
  const dispatch = useDispatch();
  const statusAuth = useSelector(selectAuthStatus);
  const isWebView = useSelector(selectIsWebView);
  const { isAuthenticated } = useCorbado();

  useEffect(() => {
    dispatch(initApp());
  }, [dispatch]);

  useEffect(() => {
    if (!isAuthenticated && statusAuth === Statuses.Success) {
      dispatch(handleLogout());
    }
  }, [isAuthenticated]);

  return (
    <Loader>
      <>
        <Toaster position={"top-center"} />

        {isWebView ? (
          <WebView />
        ) : (
          <>
            <RouterProvider router={router} />
            <PWABadge />
            <FirebaseNotificationProvider />
          </>
        )}
      </>
    </Loader>
  );
}

export default App;
