import { configureStore } from "@reduxjs/toolkit";
import { api } from "./api";
import authReducer from "./auth/auth.slice.ts";
import twaReducer from "./twa/twa.slice.ts";
import appReducer from "./app/app.slice.ts";
import { setupListeners } from "@reduxjs/toolkit/query";
import userReducer from "./user/user.slice.ts";
import dailyGameReducer from "./daily_game/daily_game.slice.ts";
import dailyCipherReducer from "./daily_cipher/daily_cipher.slice.ts";
import gamesReducer from "./games/games.slice.ts";

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    app: appReducer,
    auth: authReducer,
    twa: twaReducer,
    user: userReducer,
    daily_game: dailyGameReducer,
    daily_cipher: dailyCipherReducer,
    games: gamesReducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware)
});

setupListeners(store.dispatch);

export type State = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
