import styled from "styled-components";
import background from "../../assets/background.png";
import { ThemeType } from "../../shared/theme";
import { AnimatedText } from "../../components/AnimatedText/";

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-image: url(${background});
  background-position: center;
  background-size: cover;
  height: 90vh;
  position: relative;
  z-index: 1;
  user-select: none;
`;

export const WrapperWidget = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const AvatarContainer = styled.div`
  display: flex;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Text = styled.span`
  margin: 0;
  padding: 0;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  text-align: start;
  font-family: Orbitron, sans-serif;
`;

export const SubText = styled.span`
  margin: 0;
  padding: 0;
  font-size: 0.625rem;
  line-height: 1.25rem;
  font-weight: 500;
  text-align: start;
  font-family: Orbitron, sans-serif;
`;

export const BadgeContainer = styled(Container)`
  align-items: end;
`;

export const BadgeItem = styled.div`
  display: flex;
  align-items: center;
`;

export const BadgeIcon = styled.svg<{ color: ThemeType }>`
  color: ${({ color }) => color};
  margin-right: 0.5rem;
`;

export const WrappperDailyWidgets = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  gap: 1rem;

  @media (max-height: 590px) {
    margin-top: auto;
  }
`;

export const MoneyCountContainer = styled.div`
  margin: auto auto 0.25rem;
`;

export const MoneyCount = styled(AnimatedText)``;

export const Image = styled.div`
  margin-top: auto;
  img {
    width: 15.5rem;
    height: 100%;
    will-change: filter;
    transition: filter 0.2s ease-out;
    filter: ${({ theme }) => `drop-shadow(0 0 14px ${theme.fontContrast})`};
    user-select: none;

    &:active {
      filter: ${({ theme }) => `drop-shadow(0 0 3px ${theme.fontContrast})`};
    }
  }
`;

export const EnergyContainer = styled.div`
  margin: auto auto 0.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const BarContainer = styled.div`
  width: 50%;
  height: 30px;
  border-radius: 10px;
  border: 1px solid #e8a9ef40;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: stretch;
  padding: 0.125rem;
`;

export const BarFill = styled.div<{ width: number }>`
  background: ${({ theme }) => theme.fontContrast};
  width: ${({ width }) => width}%;
  border-radius: 10px;
  transition: width 0.3s;
  filter: drop-shadow(0 0 2px #fabcff40);
  box-shadow: inset 0 4px 4px 0 #ffffff40;
`;

export const Energy = styled.div`
  font-size: 0.875rem;
  line-height: 1rem;
  font-weight: 700;
  font-family: "Orbitron", sans-serif;
  color: ${({ theme }) => theme.fontPrimary};
  filter: ${({ theme }) => `drop-shadow(0 0 3px ${theme.fontContrast})`};
`;
