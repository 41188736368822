import styled from "styled-components";

export const Root = styled.div``;

export const ImageContainer = styled.div<{ $img: string }>`
  background-image: url(${({ $img }) => $img});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: calc(90vh - 6rem);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  margin: 0 auto;

  @media (min-width: 768px) {
    background-size: contain;
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 42%;
  margin: 0 auto 2rem;
`;

export const Controls = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;

export const Control = styled.span<{ $isCurrent: boolean }>`
  width: ${({ $isCurrent }) => ($isCurrent ? "17px" : "9px")};
  height: ${({ $isCurrent }) => ($isCurrent ? "17px" : "9px")};
  border-radius: 50%;
  background-color: ${({ theme, $isCurrent }) =>
    $isCurrent ? theme.fontContrast : theme.fontSecondary};
  position: relative;

  &::before {
    ${({ $isCurrent }) =>
      $isCurrent
        ? `
      content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 5px; 
  height: 5px;
  background-color: #0B001F;
  transform: translate(-50%, -50%) rotate(45deg);
    `
        : ""}
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  padding: 0 24px;
  margin-bottom: 6rem;
`;
