import styled from "styled-components";
import { AnimatedText } from "../AnimatedText/AnimatedText.styles.ts";

export const Root = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  height: 80vh;
`;
export const Title = styled(AnimatedText)`
  line-height: 3.2rem;
  text-align: center;
  margin: 0 0 1rem;
`;

export const Subtitle = styled.p`
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.5rem;
  margin: 0 0 1rem;
  text-align: center;
`;

export const Image = styled.div`
  margin-bottom: auto;
  img {
    width: 15.5rem;
    height: 100%;
    will-change: filter;
    transition: filter 0.2s ease-out;
    filter: ${({ theme }) => `drop-shadow(0 0 14px ${theme.fontContrast})`};
  }
`;
