import { FunctionComponent, ReactNode, useCallback, useEffect, useRef } from "react";
import { useBoard } from "../hooks/useBoard.ts";
import { Moves } from "../../../modules/daily_game/constants.ts";
import { useSelector } from "react-redux";

import { selectIsStarted } from "../../../modules/daily_game/daily_game.selectors.ts";

type Props = {
  children: ReactNode;
};

export const BoardProvider: FunctionComponent<Props> = ({ children }) => {
  const { moveTiles } = useBoard();
  const isRunning = useSelector(selectIsStarted);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      e.preventDefault();

      if (isRunning) {
        switch (e.code) {
          case "ArrowUp":
            moveTiles(Moves.Up);
            break;
          case "ArrowDown":
            moveTiles(Moves.Down);
            break;
          case "ArrowLeft":
            moveTiles(Moves.Left);
            break;
          case "ArrowRight":
            moveTiles(Moves.Right);
            break;
        }
      }
    },
    [moveTiles, isRunning]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  return <>{children}</>;
};
