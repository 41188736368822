import { Game, GameOptions } from "./game.ts";
import { Sprites } from "../types.ts";

type InitOptions = {
  element: HTMLCanvasElement;
  width: number;
  height: number;
  playButton: HTMLDivElement;
  bodyContainer: HTMLDivElement;
  startPage: HTMLDivElement;
  instructions: HTMLDivElement;
  mobileInstruction: HTMLDivElement;
  startPageImage: HTMLImageElement;
  gamepad: HTMLDivElement;
  onFinish: (score: number, startedAt: string) => void;
};

let assetsStillLoading = 0;
let assetsLoader;
let numAssets;
let loadedPercent;
const SPRITES: Sprites = {};

function assetsLoadingLoop(options: InitOptions, callback) {
  const {
    element,
    width,
    height,
    mobileInstruction,
    instructions,
    playButton,
    bodyContainer,
    startPage,
    startPageImage,
    gamepad,
    onFinish
  } = options;
  const ctx = element.getContext("2d");
  startPageImage.width = width;
  startPageImage.height = height;
  startPage.style.height = height;
  ctx.canvas.width = width;
  ctx.canvas.height = height;
  loadedPercent = ((numAssets - assetsStillLoading) / numAssets) * 100;
  ctx.beginPath();
  ctx.fillStyle = "black";
  ctx.fillRect(0, 0, width, height);
  ctx.textAlign = "center";
  ctx.fillStyle = "white";
  ctx.font = "30px Doodle";
  ctx.fillText("Loading.....", width / 2, height / 2);
  ctx.fillStyle = "#2dd";
  ctx.font = "30px Doodle";
  ctx.fillText(`${parseInt(loadedPercent)}%`, width / 2, height / 2 + 40);
  ctx.beginPath();

  if (assetsStillLoading === 0) {
    callback({
      element,
      canvasWidth: width,
      canvasHeight: height,
      sprites: SPRITES,
      playButton,
      bodyContainer,
      startPage,
      instructions,
      mobileInstruction,
      gamepad,
      onFinish
    });
    window.cancelAnimationFrame(assetsLoader);
  } else {
    assetsLoader = window.requestAnimationFrame(() => assetsLoadingLoop(options, callback));
  }
}

function loadAssets(options: InitOptions, callback) {
  //once this function finishes to load all assets this callback function gets activated

  function loadSprite(fileName) {
    assetsStillLoading++;

    const spriteImage = new Image();
    spriteImage.src = fileName;

    spriteImage.onload = function () {
      assetsStillLoading--;
    };

    spriteImage.onerror = function () {
      assetsStillLoading--;
    };

    return spriteImage;
  }

  //Sprite loading.
  SPRITES.background = loadSprite("/doodle/images/bck.png");
  SPRITES.potHole = loadSprite("/doodle/images/hole.png");
  SPRITES.player = loadSprite("/doodle/images/mascot-right.png");
  SPRITES.playerLeft = loadSprite("/doodle/images/mascot-left.png");
  SPRITES.playerUp = loadSprite("/doodle/images/mascot-up.png");
  SPRITES.uglyEnemy = loadSprite("/doodle/images/red_arrow_monster.png");
  SPRITES.flyingEnemy = loadSprite("/doodle/images/green_arrow_monster.png");
  SPRITES.purpleEnemy = loadSprite("/doodle/images/robomonster.png");
  SPRITES.booster3 = loadSprite("/doodle/images/jetpack.png");
  SPRITES.boosterJetpack = loadSprite("/doodle/images/mascot-w-jetpack.png");
  SPRITES.greenPlatform = loadSprite("/doodle/images/platform.png");
  SPRITES.movingPlatform = loadSprite("/doodle/images/moving-platform.png");
  SPRITES.breakingPlatform = loadSprite("/doodle/images/broken_platform.png");
  SPRITES.yellowPlatform = loadSprite("/doodle/images/platform_yellow.png");
  numAssets = assetsStillLoading;
  assetsLoadingLoop(options, callback);
}

export const init = (options: InitOptions) => {
  loadAssets(options, (options: GameOptions) => {
    new Game(options);
  });
};
