import { FunctionComponent, useCallback } from "react";
import * as S from "../../GameCard.styles.ts";
import { AnimatedText } from "../../../../../../components/AnimatedText/";
import { DefaultText } from "../../../../../../components/DefaultText";
import { TicketIcon } from "../../assets/TicketIcon.tsx";
import { useCountdown } from "../../../../../../app/hooks/useCountdown.ts";
import { abbreviateNumber, formatNumber } from "../../../../../../shared/formatNumber";
import { Button } from "../../../../../../components/Button";
import text from "../../../../../../shared/textContent.json";
import { TextItem } from "../TextItem";

type Props = {
  title: string;
  description: string;
  prizePool: number;
  willEndAt: string;
  price: number;
  icon: string;
  background: string;
  id: number;
  overallTickets: string;
  earnedTickets: number;
  gameUUID: string | null;
  onGameSelect: ({ gameId, gameUUID }: { gameId: number; gameUUID: string }) => void;
};

export const ActiveGame: FunctionComponent<Props> = ({
  title,
  description,
  prizePool,
  willEndAt,
  price,
  icon,
  background,
  earnedTickets,
  id,
  gameUUID,
  onGameSelect,
  overallTickets
}) => {
  const { hours, minutes, days } = useCountdown(willEndAt);
  const {
    games: { ends_in, play, play_for, prize_pool }
  } = text;

  const countdown = `${days ? `${days}D ` : ""} ${hours}H ${minutes}M`;
  const prizePoolValue = `$${formatNumber(prizePool)}`;

  const handleClick = useCallback(() => {
    onGameSelect({ gameId: id, gameUUID });
  }, [id, gameUUID]);

  const buttonText = gameUUID ? play : play_for.replace("${x}", abbreviateNumber(price));

  return (
    <S.GameRoot>
      <S.Header>
        <S.HeaderContainer>
          <S.TitleContainer>
            <S.Icon src={`data:image/png;base64,${icon}`} alt="icon" />
            <AnimatedText $fontSize="1.25rem" $lineHeight="1.75rem">
              {title}
            </AnimatedText>
          </S.TitleContainer>
          <S.TicketContainer>
            <TicketIcon />
            <DefaultText>{`${earnedTickets} / ${overallTickets}`}</DefaultText>
          </S.TicketContainer>
        </S.HeaderContainer>

        <S.TextWrapper dangerouslySetInnerHTML={{ __html: description }} />
      </S.Header>
      <S.Divider />
      <S.Body>
        <TextItem title={ends_in} value={countdown} />
        <TextItem title={prize_pool} value={prizePoolValue} />
      </S.Body>
      <S.Footer>
        <Button onClick={handleClick}>{buttonText}</Button>
      </S.Footer>
    </S.GameRoot>
  );
};
