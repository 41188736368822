import { FunctionComponent, ReactNode, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  appendRandomTile,
  cleanUp,
  setNewCountdownValue,
  initGame,
  toggleGame
} from "../../../modules/daily_game/daily_game.slice.ts";
import { MERGE_ANIMATION_DURATION } from "../../../modules/daily_game/constants.ts";
import {
  selectCountdown,
  selectHasChanged,
  selectIsStarted
} from "../../../modules/daily_game/daily_game.selectors.ts";

type Props = {
  children: ReactNode;
};
export const GameProvider: FunctionComponent<Props> = ({ children }) => {
  const hasChanged = useSelector(selectHasChanged);
  const isRunning = useSelector(selectIsStarted);
  const countdown = useSelector(selectCountdown);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initGame());
  }, []);

  useEffect(() => {
    if (countdown <= 0 && isRunning) {
      dispatch(toggleGame());
      return;
    }

    if (isRunning) {
      const interval = setInterval(() => {
        dispatch(setNewCountdownValue(1000));
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [dispatch, isRunning, countdown]);

  useEffect(() => {
    if (hasChanged) {
      setTimeout(() => {
        dispatch(cleanUp());
        dispatch(appendRandomTile());
      }, MERGE_ANIMATION_DURATION);
    }
  }, [hasChanged, dispatch]);

  return <>{children}</>;
};
