import React, { FunctionComponent } from "react";
import {
  Item,
  ItemBox,
  ItemCount,
  ItemTitle,
  Root,
  Title,
  WrapperList
} from "../../../../components/SectionList/SectionList.styles.tsx";

import { SectionListProps } from "../../../../components/SectionList/types.ts";
import { Achieved } from "./Achieved";

export type TaskListItem = {
  id: number;
  title: string;
  description: string;
  reward: string;
  icon?: string | JSX.Element;
  iconModal?: string | JSX.Element;
  url?: string;
  completed?: boolean;
  isSui: boolean;
  isConnectTelegram: boolean;
  type: string;
  isRepeatedTask: boolean;
  repeatAt: null | string;
};

export const TasksList: FunctionComponent<SectionListProps<TaskListItem>> = ({
  name,
  list,
  direction,
  alignTextItems,
  onClick = () => {}
}) => {
  return (
    <Root>
      <Title> {name} </Title>
      <WrapperList direction={direction}>
        {list.map((item) => (
          <Item
            key={item.id}
            alignTextItems={alignTextItems}
            onClick={() => {
              if (!item.completed) {
                onClick({
                  id: item.id,
                  title: item.title,
                  reward: item.reward,
                  description: item.description,
                  icon: item.iconModal,
                  url: item.url,
                  isSui: item.isSui,
                  isConnectTelegram: item.isConnectTelegram,
                  type: item.type,
                  repeatAt: item.repeatAt
                });
              }
            }}
          >
            {item?.icon}
            <ItemBox alignTextItems={alignTextItems}>
              <ItemTitle>{item.title}</ItemTitle>
              <ItemCount>{item.reward}</ItemCount>
            </ItemBox>
            {item.completed ? (
              <Achieved isRepeatTask={item.isRepeatedTask} repeatAt={item.repeatAt} />
            ) : null}
          </Item>
        ))}
      </WrapperList>
    </Root>
  );
};
