import React, { FC } from "react";
import * as S from "./DaysList.styles.ts";
import { CoinIcon } from "../../../../shared/icons";

export type TDay = {
  id: number;
  icon?: string | JSX.Element;
  points: string;
};

export type TDaysList = {
  days: TDay[];
  activeDay?: number;
  isCheckinNow?: boolean;
};

const DaysList: FC<TDaysList> = ({ days, activeDay, isCheckinNow }) => {
  return (
    <S.Root>
      <S.DaysWrapper>
        {days.map((item) => {
          const isOverTenthDay = activeDay > 10;
          const isActive = (item.id - 1 === activeDay || isOverTenthDay) && isCheckinNow;
          const isChecked = item.id - 1 < activeDay && item.id !== 11;
          const day = `Day ${item.id > 10 ? activeDay + 1 : item.id}`;

          return (
            <S.Days isActive={isActive} isChecked={isChecked} key={item.id}>
              {day}
              <S.DayIcon src={CoinIcon as string} />
              {item.points}
            </S.Days>
          );
        })}
      </S.DaysWrapper>
    </S.Root>
  );
};

export default DaysList;
