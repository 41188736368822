import styled, { css, keyframes } from "styled-components";
import { theme } from "../../../shared/theme";

export const PIXEL_SIZE = "0.5rem";
const TILE_BACKGROUND = "#26084E";
const BOARD_BACKGROUND = "#190534";

const CELL_BACKGROUND = "#1E053F";
const PRIMARY_TEXT_COLOR = "#FFF";

export const Root = styled.div`
  display: flex;
  justify-content: center;
`;

export const Board = styled.div`
  position: relative;
  width: 100%;
  min-height: 100%;

  @media (max-width: 512px) {
    width: 100%;
  }
`;

export const Tiles = styled.div`
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
`;

export const Tile = styled.div<{
  $value: number;
  $left: number;
  $top: number;
  $zIndex: number;
}>`
  position: absolute;
  font-family: "Orbitron", sans-serif;
  top: ${({ $top }) => `${$top}px`};
  left: ${({ $left }) => `${$left}px`};
  z-index: ${({ $zIndex }) => $zIndex};
  width: calc(25% - 10px);
  height: calc(25% - 10px);
  border-radius: calc(${PIXEL_SIZE} * 0.5);
  background: ${TILE_BACKGROUND};
  color: ${PRIMARY_TEXT_COLOR};
  font-size: calc(${PIXEL_SIZE} * 4);
  line-height: calc(${PIXEL_SIZE} * 8);
  font-weight: bold;
  text-align: center;
  transition-property: left, top, transform;
  transition-duration: 200ms, 200ms, 100ms;

  ${({ $value }) => {
    if ($value === 4) {
      return `  
        background: #310A66;
      `;
    }
    if ($value === 8) {
      return `
        background: #4E0A66;
      `;
    }
    if ($value === 16) {
      return `
        background: #660A60;
      `;
    }
    if ($value === 32) {
      return `
        background: #740B6D;
      `;
    }
    if ($value === 64) {
      return `
        background: #820D7A;
      `;
    }
    if ($value === 128) {
      return `
        background: #9E0F94;
      `;
    }
    if ($value === 256) {
      return `
        background: #BA12AF; 
      `;
    }
    if ($value === 512) {
      return `
        background: #DF16D2;
      `;
    }
    if ($value === 1024) {
      return `
        background: #EB33DF;
      `;
    }
    if ($value === 2048 || $value === 4096 || $value === 8192) {
      return `
        background: #F174E9;
      `;
    }
  }};

  @media (min-width: 512px) {
    width: calc(${PIXEL_SIZE} * 12.5);
    height: calc(${PIXEL_SIZE} * 12.5);
    font-size: calc(${PIXEL_SIZE} * 6);
    line-height: calc(${PIXEL_SIZE} * 12.5);
  }
`;

export const GameGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(64px, 1fr));
  grid-template-rows: repeat(4, minmax(64px, 1fr));
  background: ${BOARD_BACKGROUND};
  border: calc(${PIXEL_SIZE} * 1) solid ${BOARD_BACKGROUND};
  border-radius: calc(${PIXEL_SIZE} * 0.75);
  gap: 0.5rem;
`;

export const Cell = styled.div`
  width: 100%;
  height: 100%;
  border-radius: calc(${PIXEL_SIZE} * 0.5);
  background: ${CELL_BACKGROUND};

  @media (min-width: 512px) {
    width: calc(${PIXEL_SIZE} * 12.5);
    height: calc(${PIXEL_SIZE} * 12.5);
  }
`;

export const Score = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  margin-bottom: 0.5rem;
  gap: 0.5rem;
  width: 100%;
`;

export const ScoreItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem 0;
  background-color: ${theme.sectionMain};
  border-radius: 0.625rem;
  gap: 0.25rem;
  box-shadow:
      inset 0 4px 4px 0 #ffffff40,
      inset 0 -4px 4px 0 #24044F;
}
`;

export const ScoreValue = styled.span`
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.25rem;
`;

export const GameOver = styled.div`
  position: absolute;
  inset: 0;
  background: #190534e6;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
`;

const blink = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const animation = ({ isDanger, isRunning }) => {
  return isDanger && isRunning
    ? css`
        ${blink} 0.5s infinite ease-in-out
      `
    : css`"unset"`;
};

export const Countdown = styled.p<{ isDanger: boolean; isRunning: boolean }>`
  font-size: 3rem;
  font-weight: bold;
  line-height: 3.2rem;
  text-align: center;
  margin: 0 0 1.5rem;
  color: ${({ isDanger, theme, isRunning }) => (isDanger ? theme.fontContrast : "#fff")};
  animation: ${animation};
`;
