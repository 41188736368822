export type AuthState = {
  token: string | null;
  status: Statuses;
};

export enum AuthProviders {
  Twitter = "twitter",
  Telegram = "telegram",
  Corbado = "corbado"
}

export enum Statuses {
  Loading = "loading",
  Idle = "idle",
  Success = "success",
  Error = "error"
}
