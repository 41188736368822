import { useEffect, useRef } from "react";
import DropIMG from "../../assets/drop.png";
import DailyRewardsIcon from "../../shared/icons/DailyRewards.png";
import DailyGamesIcon from "../../shared/icons/DailyGames.png";
import DailyCipherIcon from "../../shared/icons/DailyCipher.png";
import * as S from "./main.styles";
import { showModal } from "../../shared/showModal";
import { usePostTapsMutation } from "../../modules/api.ts";
import { useDebouncedCallback } from "use-debounce";
import { useDispatch, useSelector } from "react-redux";
import text from "../../shared/textContent.json";
import { useTWAEvent } from "@tonsolutions/telemetree-react";
import { VelocityIcon } from "./assets/VelocityIcon.tsx";
import { AppDispatch } from "../../modules/store.ts";
import { handleLogout } from "../../modules/auth/auth.slice.ts";
import {
  onTap,
  setBackgroundEarningRate,
  setCanPlayDailyGameNow,
  setCount,
  setCountPerTime,
  setDailyCipherData,
  setEnergy,
  setMaxEnergy,
  setNextCheckinInfo,
  setNextDailyGameAvailableAt,
  setPointsPerClickWithBooster
} from "../../modules/user/user.slice.ts";
import { DailyGameModal, DailyCheckinModal, DailyCipherModal } from "../../components/Modals";
import {
  selectBackgroundEarningRate,
  selectCanDoDailyCipher,
  selectCanPlayDailyGame,
  selectCheckinAvailableAt,
  selectCount,
  selectCountPerTime,
  selectDailyCipherAvailableAt,
  selectDailyGameAvailableAt,
  selectEnergy,
  selectIsCheckinNow,
  selectMaxEnergy,
  selectPointsPerClickWithBooster,
  selectUsername
} from "../../modules/user/user.selectors.ts";
import { AnimatedGrid } from "../../components/AnimatedGrid";

import { formatVelocity } from "./utils/formatVelocity.ts";
import { formatNumber } from "../../shared/formatNumber";
import DailyWidget from "./ui/daily-widget";
import { useSignature } from "../../app/hooks/useSignature.ts";
import { useWasmEncrypt } from "../../app/hooks/useWasmEncrypt.ts";
import { useErrorHandler } from "../../app/hooks/useErrorHandler.ts";

const TAP_PRICE = import.meta.env.VITE_TAP_PRICE;

const MainPage = () => {
  const [postTaps, { isLoading }] = usePostTapsMutation();
  const dispatch = useDispatch<AppDispatch>();
  const firstTapTimestampRef = useRef<null | string>(null);
  const eventBuilder = useTWAEvent();
  const {
    main: {
      // level, rank,
      daily_rewards,
      daily_cipher,
      daily_games
    }
  } = text;

  const count = useSelector(selectCount);
  const countPerTime = useSelector(selectCountPerTime);
  const energy = useSelector(selectEnergy);
  const maxEnergy = useSelector(selectMaxEnergy);
  const username = useSelector(selectUsername);
  const backgroundEarningRate = useSelector(selectBackgroundEarningRate);
  const pointsPerClickWithBooster = useSelector(selectPointsPerClickWithBooster);
  const canPlayDailyGame = useSelector(selectCanPlayDailyGame);
  const canCheckinNow = useSelector(selectIsCheckinNow);
  const nextCheckinAvailableAt = useSelector(selectCheckinAvailableAt);
  const nextDailyGameAvailableAt = useSelector(selectDailyGameAvailableAt);
  const canDoDailyCipherNow = useSelector(selectCanDoDailyCipher);
  const nextDailyCipherAvailableAt = useSelector(selectDailyCipherAvailableAt);
  const { generateSignature } = useSignature();
  const { encryptData } = useWasmEncrypt();

  const { showError } = useErrorHandler();

  const onClick = () => {
    if (isLoading) {
      return;
    }
    if (energy > 0) {
      if (!firstTapTimestampRef.current) {
        firstTapTimestampRef.current = new Date().toISOString();
      }
      dispatch(
        onTap({
          count: count + pointsPerClickWithBooster,
          countPerTime: countPerTime + 1,
          energy: energy - TAP_PRICE
        })
      );
      debounced();
    }
  };

  const onDailyCheckinClick = () => showModal(DailyCheckinModal);
  const onDailyCipherModal = () => showModal(DailyCipherModal);
  const onDailyGamesClick = () => showModal(DailyGameModal);

  useEffect(() => {
    const interval = setInterval(() => {
      if (energy < maxEnergy) {
        dispatch(setEnergy(energy + 1));
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [maxEnergy, energy, dispatch]);

  const debounced = useDebouncedCallback(async () => {
    const key = await generateSignature({ taps: countPerTime });
    const body = encryptData(
      JSON.stringify({
        tap_input: { taps: countPerTime, first_tap_at: firstTapTimestampRef.current },
        key
      })
    );
    postTaps(body)
      .unwrap()
      .then((res) => {
        dispatch(setCountPerTime(0));
        dispatch(setCount(res.user.points));
        dispatch(setMaxEnergy(res.user.max_energy));
        dispatch(setPointsPerClickWithBooster(res.user.point_per_clicks_with_booster));
        dispatch(setBackgroundEarningRate(res.user.background_earning_rate));
        dispatch(setNextCheckinInfo(res.user.next_checkin_info));
        dispatch(setCanPlayDailyGameNow(res.user.can_play_daily_game_now));
        dispatch(setNextDailyGameAvailableAt(res.user.next_daily_game_available_at));
        dispatch(setDailyCipherData(res.user.next_daily_cipher_available_at));
      })
      .catch((err) => {
        showError(err);
      })
      .finally(() => {
        firstTapTimestampRef.current = null;
      });
  }, 1000);

  useEffect(() => {
    eventBuilder.track("Main page visit", {
      label: "label", // Additional info about the button
      stage: "stage" // Categorize the event
    });
  }, []);

  return (
    <>
      <S.Root>
        <S.WrapperWidget>
          <S.AvatarContainer>
            <S.Container>
              <S.Text onClick={() => dispatch(handleLogout())}>{username}</S.Text>
              {/*<S.SubText>{`${level} ${userLevel}`}</S.SubText>*/}
            </S.Container>
          </S.AvatarContainer>

          <S.BadgeContainer>
            <S.BadgeItem>
              <VelocityIcon />
              <S.Text>{formatVelocity(backgroundEarningRate)}</S.Text>
            </S.BadgeItem>
          </S.BadgeContainer>
        </S.WrapperWidget>

        <S.WrappperDailyWidgets>
          <DailyWidget
            onClick={onDailyCheckinClick}
            disabled={!canCheckinNow}
            widgetIcon={DailyRewardsIcon as string}
            text={daily_rewards}
            cooldown={nextCheckinAvailableAt}
          />

          <DailyWidget
            onClick={onDailyCipherModal}
            disabled={!canDoDailyCipherNow}
            widgetIcon={DailyCipherIcon as string}
            text={daily_cipher}
            cooldown={nextDailyCipherAvailableAt}
          />

          <DailyWidget
            onClick={onDailyGamesClick}
            disabled={!canPlayDailyGame}
            widgetIcon={DailyGamesIcon as string}
            text={daily_games}
            cooldown={nextDailyGameAvailableAt}
          />
        </S.WrappperDailyWidgets>

        <S.MoneyCountContainer>
          <S.MoneyCount>{formatNumber(count)}</S.MoneyCount>
        </S.MoneyCountContainer>

        <S.Image onClick={onClick}>
          <img src={DropIMG} alt={"no image"} />
        </S.Image>

        <S.EnergyContainer>
          <S.BarContainer>
            <S.BarFill width={Number(((energy / maxEnergy) * 100).toFixed(2))} />
          </S.BarContainer>
          <S.Energy>
            {formatNumber(energy)} / {formatNumber(maxEnergy)}
          </S.Energy>
        </S.EnergyContainer>
      </S.Root>

      <AnimatedGrid />
    </>
  );
};

export default MainPage;
