import { OverlayCard } from "../../OverlayCard";
import { FunctionComponent } from "react";
import { BaseModalOptions } from "../../../shared/showModal";
import { Root, Box, Title, Subtitle } from "../Modals.styles.ts";
import { Button } from "../../Button";
import CalendarIcon from "../../../shared/icons/DailyCipher.png";
import * as S from "./NotificationModal.styles.ts";

export interface NotificationModalProps extends BaseModalOptions {
  title: string;
  subtitle: string;
  buttonText: string;
}

export const NotificationModal: FunctionComponent<NotificationModalProps> = ({
  title,
  subtitle,
  buttonText,
  onCancelAction
}) => {
  return (
    <OverlayCard fullScreen>
      <Root>
        <Box marginBottom="1.5rem">
          <S.Icon src={CalendarIcon as string} />
        </Box>
        <Box marginBottom="1.5rem">
          <Title marginBottom="1rem">{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
        </Box>
        <Button onClick={() => onCancelAction({})}>{buttonText}</Button>
      </Root>
    </OverlayCard>
  );
};
