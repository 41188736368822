import { useSelector } from "react-redux";
import { selectId, selectSessionId } from "../../modules/user/user.selectors.ts";

const getISODate = (date: Date) =>
  new Date(
    Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours())
  )
    .toISOString()
    .split(".")[0] + "+00:00";

type Options = {
  score?: number;
  taps?: number;
  gameUuid?: string;
};

export const useSignature = () => {
  const userId = useSelector(selectId);
  const sessionId = useSelector(selectSessionId);

  const generateSignature = async ({ score, taps, gameUuid }?: Options = {}) => {
    const encoder = new TextEncoder();

    const date = new Date();
    const isoDate = getISODate(date);

    const dataToSign = [userId, isoDate, taps, score, gameUuid].filter((item) => item).join(":");

    const data = encoder.encode(dataToSign);
    const key = encoder.encode(sessionId);

    const cryptoKey = await crypto.subtle.importKey(
      "raw",
      key,
      { name: "HMAC", hash: "SHA-256" },
      false,
      ["sign"]
    );

    const signature = await crypto.subtle.sign("HMAC", cryptoKey, data);

    return Array.from(new Uint8Array(signature))
      .map((byte) => byte.toString(16).padStart(2, "0"))
      .join("");
  };

  return { generateSignature };
};
