import { FunctionComponent, ReactNode } from "react";
import * as S from "./Loader.styles.ts";
import { useSelector } from "react-redux";
import { selectIsLoading } from "../../modules/user/user.selectors.ts";
import { selectIsAuthLoading } from "../../modules/auth/auth.slice.ts";
import { AnimatedText } from "../AnimatedText/AnimatedText.styles.ts";
import text from "../../shared/textContent.json";
import { AnimatedGrid } from "../AnimatedGrid";
import { selectIsWebView } from "../../modules/app/app.slice.ts";

interface Props {
  children: ReactNode;
}

export const Loader: FunctionComponent<Props> = ({ children }) => {
  const isUserLoading = useSelector(selectIsLoading);
  const isAuthLoading = useSelector(selectIsAuthLoading);
  const isWebView = useSelector(selectIsWebView);

  const {
    loader: { loading }
  } = text;

  const isLoading = !isWebView && (isAuthLoading || isUserLoading);
  return (
    <S.Wrapper>
      <S.LoaderContainer isLoading={isLoading}>
        <S.TextContainer>
          <AnimatedText>{loading}</AnimatedText>
        </S.TextContainer>

        <AnimatedGrid />
      </S.LoaderContainer>

      <S.ChildrenContainer isLoading={isLoading}>{children}</S.ChildrenContainer>
    </S.Wrapper>
  );
};
