import { createSelector, createSlice } from "@reduxjs/toolkit";
import { TwaState } from "./types.ts";
import WebApp from "@twa-dev/sdk";
import { State } from "../store.ts";
import { UserState } from "../user/types.ts";

const INITIAL_STATE = {
  initData: null,
  platform: null
};

const twaSlice = createSlice({
  name: "twa",
  initialState: INITIAL_STATE,
  reducers: {
    initTWA: (state: TwaState) => {
      state.initData = WebApp.initDataUnsafe;
      state.platform = WebApp.platform;
      WebApp.ready();
    }
  }
});

export default twaSlice.reducer;

export const { initTWA } = twaSlice.actions;
