import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Game, GameResponse, GamesState } from "./types.ts";
import { State } from "../store.ts";

import api from "../api.ts";
import { showModal } from "../../shared/showModal";
import { ErrorModal } from "../../components/Modals/ErrorModal";
import { mapGameResponseToState } from "./utils/mapGameResponseToState.ts";

const INITIAL_STATE: GamesState = {
  loading: true,
  games: [],
  game: null
};

export const getGamesData = createAsyncThunk<void, void, { state: State }>(
  "games/getGamesData",
  async (_, { dispatch }) => {
    dispatch(setGamesLoading(true));
    try {
      const result: GameResponse[] = await dispatch(
        api.endpoints.getGames.initiate({}, { forceRefetch: true })
      ).unwrap();

      const mappedGames = mapGameResponseToState(result);

      dispatch(setGames(mappedGames));
    } catch (error) {
      await showModal<void, { errorText: string }>(ErrorModal, {
        errorText: error.data.detail as string
      });
    } finally {
      dispatch(setGamesLoading(false));
    }
  }
);

export const setGameByUuid = createAsyncThunk<void, string, { state: State }>(
  "games/setGameByUuid",
  async (uuid, { dispatch, getState }) => {
    const games = getState().games.games;

    const game = games.find((game) => game.current_available_game_uuid === uuid);

    dispatch(setGame(game));
  }
);

const gamesSlice = createSlice<GamesState>({
  name: "games",
  initialState: INITIAL_STATE,
  reducers: {
    setGamesLoading: (state: GamesState, { payload }: { payload: boolean }) => {
      state.loading = payload;
    },
    setGames: (state: GamesState, { payload }: { payload: Game[] }) => {
      state.games = payload;
    },
    setGame: (state: GamesState, { payload }: { payload: Game }) => {
      state.game = payload;
    },
    updateGameInGames: (state: GamesState, { payload }: { id: number; data: Game }) => {
      const { id, data } = payload;
      const games = state.games;

      state.games = games.map((game) => (game.id === id ? { ...game, ...data } : game));
    },
    removeGame: (state: GamesState) => {
      state.game = INITIAL_STATE.game;
    }
  }
});

export default gamesSlice.reducer;

export const { setGamesLoading, setGames, setGame, removeGame, updateGameInGames } =
  gamesSlice.actions;
