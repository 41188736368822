import * as S from "./Button.styles.ts";
import { theme } from "../../shared/theme";
import { FunctionComponent, ReactNode } from "react";

type Props = {
  children: ReactNode;
  variant?: string;
  onClick?: () => void;
  marginBottom?: string;
  disabled?: boolean;
  isCorbadoStyle?: boolean;
  padding?: string;
};
export const Button: FunctionComponent<Props> = ({
  children,
  variant = theme.fontContrast,
  onClick,
  marginBottom,
  disabled,
  isCorbadoStyle,
  padding
}) => (
  <S.Button
    variant={variant}
    onClick={onClick}
    marginBottom={marginBottom}
    disabled={disabled}
    isCorbadoStyle={isCorbadoStyle}
    padding={padding}
  >
    {children}
  </S.Button>
);
