import styled from "styled-components";

export const GameRoot = styled.div<{ $disabled?: boolean }>`
  display: flex;
  padding: 1rem;
  width: 100%;
  flex-direction: column;
  gap: 1rem;
  border: 3px solid #fabcff;
  border-radius: 1rem;
  opacity: ${({ $disabled }) => ($disabled ? 0.5 : 1)};
  pointer-events: ${({ $disabled }) => ($disabled ? "none" : "auto")};
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 1rem;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const Countdown = styled.span`
  font-size: 2.5rem;
  line-height: 3rem;
  font-family: "Orbitron", sans-serif;
  font-weight: 700;
`;

export const Icon = styled.img`
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: ${({ theme }) => theme.aquamarine};
  margin-right: 0.75rem;
`;

export const TicketContainer = styled.div`
  display: flex;
  gap: 0.25rem;
  align-items: center;
`;

export const Body = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
`;

export const TextContainer = styled.div`
  display: flex;
  gap: 0.125rem;

  &:nth-child(even) {
    justify-self: end;
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const Divider = styled.hr`
  height: 1px;
  color: #fabcff;
  opacity: 0.2;
  width: 100%;
`;

export const TextWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1rem;
  margin: 0;

  img {
    width: 20px;
    height: 20px;
  }
`;
