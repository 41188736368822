import * as S from "./TextItem.styles.ts";
import { DefaultText } from "../../../../../../components/DefaultText";
import { FunctionComponent } from "react";

type Props = {
  title: string;
  value: string;
};

export const TextItem: FunctionComponent<Props> = ({ title, value }) => (
  <S.TextItem>
    <DefaultText $color="#FFFFFF80">{title}</DefaultText>
    <DefaultText>{value}</DefaultText>
  </S.TextItem>
);
