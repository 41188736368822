import { FunctionComponent } from "react";
import { Cipher } from "../Cipher";
import * as S from "./DailyCipher.styles.ts";
import { Answer } from "../Answer";

type Props = {
  onCancel: () => void;
};

export const DailyCipher: FunctionComponent<Props> = ({ onCancel }) => {
  return (
    <S.Root>
      <Cipher />
      <Answer onCancel={onCancel} />
    </S.Root>
  );
};
