import styled from "styled-components";

export const ItemPriceButton = styled.button`
  display: flex;
  align-items: center;
  border-radius: 0.5rem;
  border: none;
  box-shadow:
    inset 0 4px 4px 0 #ffffff40,
    inset 0 -4px 4px 0 #00000040;
  background-color: ${({ theme }) => theme.fontContrast};
  padding: 0.375rem 0.5rem;
`;