import { useSelector } from "react-redux";
import { selectRegularTasks } from "../../../modules/user/user.selectors.ts";
import { PLATFORMS, RegularTask, TASK_TYPES } from "../../../modules/user/types.ts";
import { formatTitle } from "../utils/formatTitle.ts";
import { formatNumber } from "../../../shared/formatNumber";
import { TaskListItem } from "../ui/tasks-list";

export const useTasks = () => {
  const regularTasks = useSelector(selectRegularTasks);

  const reducedTasks = [...regularTasks].reduce(
    (acc, task) => {
      if (task.regular_task.sponsored) {
        acc.sponsored.push(task);
      } else {
        acc.sui.push(task);
      }
      return acc;
    },
    { sponsored: [], sui: [] }
  );

  const convertTasks = (data: RegularTask[]): TaskListItem[] => {
    return data.map((item) => {
      const isRepeatedTask = item.regular_task.repeat_interval_minutes !== null;

      return {
        id: item.regular_task.id,
        title: formatTitle(item.regular_task.type, item.regular_task.platform),
        reward: isRepeatedTask
          ? `+${formatNumber(item.regular_task.reward_tickets)} tickets reward`
          : `+${formatNumber(item.regular_task.reward)} reward`,
        description: item.regular_task.description,
        url: item.regular_task.url,
        isSui: item.regular_task.platform === PLATFORMS.Sui,
        isRepeatedTask,
        repeatAt: item.next_time_available_at,
        isConnectTelegram:
          item.regular_task.platform === PLATFORMS.Tg &&
          item.regular_task.type === TASK_TYPES.Connect,
        completed: !item.available_now,
        type: item.regular_task.type
      };
    });
  };

  const convertedSuiTasks = convertTasks(reducedTasks.sui);
  const convertedSponsoredTasks = convertTasks(reducedTasks.sponsored);

  return { convertedSponsoredTasks, convertedSuiTasks };
};
