import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { AuthProviders, AuthState, Statuses } from "./types.ts";
import { State } from "../store.ts";
import { getQueryVariable } from "./utils.ts";
import { showModal } from "../../shared/showModal";
import { AuthModal } from "../../components/Modals/AuthModal";
import { api } from "../api.ts";
import { ErrorModal } from "../../components/Modals/ErrorModal";
import { v4 as uuidv4 } from "uuid";
import { getUserData } from "../user/user.slice.ts";
import { Platforms } from "../app/constants.ts";
import { openTgWithStartParams } from "../../shared/openTgWithStartParams.ts";

const myDynamicManifest = {
  name: "Droppy",
  short_name: "Droppy",
  description: "Tap Tap Tap",
  display: "standalone",
  start_url: window.location.origin,
  background_color: "#000000",
  theme_color: "#0f4a73",
  icons: [
    {
      src: "pwa-512x512.png",
      sizes: "512x512",
      type: "image/png"
    }
  ]
};

const INITIAL_STATE: AuthState = {
  token: null,
  status: Statuses.Loading
};

interface IHandleManifestInitPayload {
  myDynamicManifest: any;
  queryJwt?: string | null;
}

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const handleManifestInit = createAsyncThunk<
  void,
  IHandleManifestInitPayload,
  { state: State }
>("auth/handleManifestInit", async ({ myDynamicManifest, queryJwt }, { dispatch }) => {
  if (queryJwt) myDynamicManifest.start_url = window.location.origin + "/?jwt=" + queryJwt;
  const stringManifest = JSON.stringify(myDynamicManifest);
  document
    .querySelector("#my-manifest-placeholder")
    .setAttribute(
      "href",
      "data:application/json;charset=utf-8," + encodeURIComponent(stringManifest)
    );
});

export const setTokenToLocalStorage = createAsyncThunk<void, string, { state: State }>(
  "auth/setJwtToLocalStorage",
  (token) => {
    window.localStorage.setItem("jwt", token);
  }
);

export const deleteTokenFromLocalStorage = createAsyncThunk<void, void, { state: State }>(
  "auth/setJwtToLocalStorage",
  () => {
    window.localStorage.clear();
  }
);

export const authWithTelegram = createAsyncThunk<void, void, { state: State }>(
  "auth/authWithTelegram",
  async (_, { dispatch, getState }) => {
    console.log("Auth with Telegram");
    const queryRef = getQueryVariable("ref");
    const uuidRandom = uuidv4();

    openTgWithStartParams(uuidRandom, queryRef);

    const pollingInterval = 5000; // 5 секунд
    let isAuthorized = false;

    while (!isAuthorized) {
      const result = await dispatch(
        api.endpoints.authWithWebTelegram.initiate({
          telegram_auth_request_id: uuidRandom
        })
      );

      if (result.error) {
        if (result.error.status) {
          await delay(pollingInterval);
        }
      } else {
        isAuthorized = true;
        console.log("Polling result", result.data);
        window.location.href = result.data.front_authorization_url;
      }
    }

    await dispatch(getUserData());

    //dispatch(setStatus(Statuses.Success));
  }
);

export const handleWebAuth = createAsyncThunk<void, void, { state: State }>(
  "auth/handleWebAuth",
  async (_, { dispatch, state }) => {
    const queryJwt = getQueryVariable("jwt");
    const refId = window.localStorage.getItem("referral_id");

    if (!refId && getQueryVariable("ref")) {
      window.localStorage.setItem("referral_id", getQueryVariable("ref"));
    }

    if (queryJwt) {
      await dispatch(setToken(queryJwt));
      await dispatch(setTokenToLocalStorage(queryJwt));
      window.location.href = window.location.origin;
      dispatch(setStatus(Statuses.Success));

      await dispatch(getUserData());
      await dispatch(handleManifestInit({ myDynamicManifest, queryJwt }));
    } else {
      const localStoredJwt = window.localStorage.getItem("jwt");
      const localStoredShort = window.localStorage.getItem("cbo_short_session");

      if (localStoredJwt) {
        await dispatch(setToken(localStoredJwt));
        dispatch(setStatus(Statuses.Success));
        await dispatch(handleManifestInit({ myDynamicManifest, queryJwt: localStoredJwt }));
        await dispatch(getUserData());
      } else {
        if (localStoredShort) {
          const queryRef = getQueryVariable("ref");

          let resultRef = null;

          if (queryRef) {
            resultRef = queryRef;
          } else if (refId) {
            resultRef = refId;
          }
          await dispatch(setToken(localStoredShort));

          await dispatch(handleManifestInit({ myDynamicManifest, queryJwt: localStoredShort }));
          const result = await dispatch(
            api.endpoints.corbadoVerify.initiate({
              token: localStoredShort,
              ...(resultRef ? { referral_id: Number(resultRef) } : {})
            })
          );

          if (result.error) {
            //await dispatch(handleLogout());
          } else {
            dispatch(setStatus(Statuses.Success));
            await dispatch(getUserData());
          }
        } else {
          await dispatch(handleManifestInit({ myDynamicManifest }));
          const { authProvider } = await showModal<{ authProvider: AuthProviders }>(AuthModal);
          if (authProvider === AuthProviders.Telegram) {
            await dispatch(authWithTelegram());
            return;
          }
        }
      }
    }
  }
);

//TODO
export const handleTwaAuth = createAsyncThunk<void, void, { state: State }>(
  "auth/handleTwaAuth",
  async (_, { dispatch, getState }) => {
    const queryJwt = getQueryVariable("jwt");
    if (queryJwt) {
      await dispatch(setToken(queryJwt));
      await dispatch(setTokenToLocalStorage(queryJwt));

      await dispatch(getUserData());
      window.location.href = window.location.origin;
      dispatch(setStatus(Statuses.Success));
    } else {
      const localStoredJwt = window.localStorage.getItem("jwt");

      if (localStoredJwt) {
        await dispatch(setToken(localStoredJwt));
        await dispatch(getUserData());
        dispatch(setStatus(Statuses.Success));
        await dispatch(handleManifestInit({ myDynamicManifest, queryJwt: localStoredJwt }));
      } else {
        try {
          const body = getState().twa.initData;

          const result = await dispatch(api.endpoints.authWithTelegram.initiate(body)).unwrap();

          // window.location.href = front_authorization_url;

          console.log(result);
          if (result.error) {
            await dispatch(setStatus(Statuses.Error));
            await showModal<void, { errorText: string }>(ErrorModal, {
              errorText: result.error.data.detail[0].msg as string
            });
          } else {
            await dispatch(setToken(result.jwt));
            await dispatch(setTokenToLocalStorage(result.jwt));
            await dispatch(getUserData());
            await dispatch(setStatus(Statuses.Success));
          }
        } catch (error) {
          await dispatch(setStatus(Statuses.Error));
          await showModal<void, { errorText: string }>(ErrorModal, {
            errorText: error.data.detail[0].msg as string
          });
        }
      }
    }
  }
);

export const handleLogout = createAsyncThunk<void, void, { state: State }>(
  "auth/handleLogout",
  async (_, { dispatch }) => {
    await dispatch(deleteTokenFromLocalStorage());
    window.location.href = window.location.origin;
  }
);

export const routeAuth = createAsyncThunk<void, Platforms, { state: State }>(
  "auth/routeAuth",
  async (platform, { dispatch }) => {
    if (platform === Platforms.Web || platform === Platforms.Pwa) {
      dispatch(handleWebAuth());
    } else if (platform === Platforms.Twa) {
      dispatch(handleTwaAuth());
    }
  }
);

export const handleAuth = createAsyncThunk<void, void, { state: State }>(
  "auth/handleAuth",
  async (_, { dispatch, getState }) => {
    await dispatch(setStatus(Statuses.Loading));
    await dispatch(routeAuth(getState().app.platform));
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState: INITIAL_STATE,
  reducers: {
    setToken: (state, { payload }: { payload: string }) => {
      state.token = payload;
    },
    setStatus: (state, { payload }: { payload: Statuses }) => {
      state.status = payload;
    }
  }
});

export default authSlice.reducer;

export const { setToken, setStatus } = authSlice.actions;

const selectAuthSliceState = (state: State) => state.auth;

export const selectIsAuthLoading = createSelector(
  [selectAuthSliceState],
  (authSlice) => authSlice.status === Statuses.Loading
);

export const selectAuthStatus = createSelector(
  [selectAuthSliceState],
  (authSlice) => authSlice.status
);

export const selectToken = createSelector([selectAuthSliceState], (authState) => authState.token);
