import * as S from "../sider.styles.ts";

export const GamesIcon = () => (
  <S.Icon width={24} height={24} viewBox="0 0 24 24">
    <path
      d="M12.3994 2C15.5209 2 18.3083 3.43021 20.1422 5.67108L13.8136 12L20.1422 18.3289C18.3083 20.5698 15.5209 22 12.3994 22C6.87656 22 2.39941 17.5228 2.39941 12C2.39941 6.47715 6.87656 2 12.3994 2ZM12.3994 5C11.571 5 10.8994 5.67157 10.8994 6.5C10.8994 7.32843 11.571 8 12.3994 8C13.2278 8 13.8994 7.32843 13.8994 6.5C13.8994 5.67157 13.2278 5 12.3994 5Z"
      fill="currentColor"
    />
  </S.Icon>
);
