import React, { FunctionComponent, useEffect, useRef } from "react";
import { init } from "./scripts/loader.ts";

import startPage from "./images/startPage.png";
import playOn from "./images/play-on.png";
import { Joystick, JoystickShape } from "react-joystick-component";
import { IJoystickUpdateEvent } from "react-joystick-component/build/lib/Joystick";
import { JoystickForce } from "./constants.ts";

type Props = {
  onFinish: (score: number, startedAt: string) => void;
};

const DoodleJump: FunctionComponent<Props> = ({ onFinish, onSetScore }) => {
  const bodyContainerRef = useRef<HTMLDivElement>(null);
  const elementRef = useRef<HTMLCanvasElement>(null);
  const startPageRef = useRef<HTMLDivElement>(null);
  const startPageImgRef = useRef<HTMLImageElement>(null);
  const playButtonRef = useRef<HTMLDivElement>(null);
  const gamepadRef = useRef<HTMLDivElement>(null);
  const instructionsRef = useRef<HTMLDivElement>(null);
  const mobileInstructionsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const CANVAS_HEIGHT = window.innerHeight;
    const CANVAS_WIDTH = window.innerWidth > 500 ? 500 : window.innerWidth;

    if (
      !elementRef.current ||
      !bodyContainerRef.current ||
      !mobileInstructionsRef.current ||
      !instructionsRef.current ||
      !playButtonRef.current ||
      !startPageRef.current ||
      !startPageImgRef.current ||
      !gamepadRef.current
    ) {
      return;
    }

    init({
      element: elementRef.current,
      bodyContainer: bodyContainerRef.current,
      mobileInstruction: mobileInstructionsRef.current,
      instructions: instructionsRef.current,
      playButton: playButtonRef.current,
      startPage: startPageRef.current,
      width: CANVAS_WIDTH,
      height: CANVAS_HEIGHT,
      startPageImage: startPageImgRef.current,
      gamepad: gamepadRef.current,
      onFinish
    });
  }, [onFinish]);

  const pressA = new KeyboardEvent("keydown", { code: JoystickForce.FullLeft });
  const pressD = new KeyboardEvent("keydown", { code: JoystickForce.FullRight });
  const pressLeftSecondThird = new KeyboardEvent("keydown", { code: JoystickForce.TwoThirdLeft });
  const pressLeftFirstThird = new KeyboardEvent("keydown", { code: JoystickForce.OneThirdLeft });
  const pressRightFirstThird = new KeyboardEvent("keydown", { code: JoystickForce.OneThirdRight });
  const pressRightSecondThird = new KeyboardEvent("keydown", {
    code: JoystickForce.TwoThirdsRight
  });

  const handleMove = (event: IJoystickUpdateEvent) => {
    const isLeft = event.direction === "LEFT";
    const isRight = event.direction === "RIGHT";
    const isFirstThird = event.distance >= 0 && event.distance < 33;
    const isSecondThird = event.distance >= 33 && event.distance < 66;

    if (isLeft) {
      if (isFirstThird) {
        document.dispatchEvent(pressLeftFirstThird);
      } else if (isSecondThird) {
        document.dispatchEvent(pressLeftSecondThird);
      } else {
        document.dispatchEvent(pressA);
      }
    } else if (isRight) {
      if (isFirstThird) {
        document.dispatchEvent(pressRightFirstThird);
      } else if (isSecondThird) {
        document.dispatchEvent(pressRightSecondThird);
      } else {
        document.dispatchEvent(pressD);
      }
    }
  };

  const handleStop = (event: IJoystickUpdateEvent) => {
    // todo?
  };

  return (
    <>
      <div id="body-container" ref={bodyContainerRef}>
        <canvas id="body-canvas" ref={elementRef} />
      </div>
      <div id="startpage" ref={startPageRef}>
        <img src={startPage} ref={startPageImgRef} />
        <div id="startplay" ref={playButtonRef}>
          <img src={playOn} />
        </div>
        <div id="pcinstruction" ref={instructionsRef}>
          <span> INSTRUCTIONS</span>
          <span> LeftMove- 'A'</span>
          <span> RightMove- 'D'</span>
          <span> Shoot- 'Space'</span>
        </div>
        <div id="mobileinstruction" ref={mobileInstructionsRef}>
          <span> INSTRUCTIONS:</span>
          <span> Gamepad - 'Move Left'</span>
          <span> Gamepad - 'Move Right'</span>
          <span> Tap - 'Shoot'</span>
        </div>
      </div>

      <div id="gamepad" ref={gamepadRef}>
        <Joystick
          size={100}
          baseColor="#1E90FF"
          stickColor="#FF7F50"
          controlPlaneShape={JoystickShape.AxisX}
          move={handleMove}
          stop={handleStop}
        ></Joystick>
      </div>
    </>
  );
};

export default DoodleJump;
