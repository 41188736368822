import * as S from './build.styles';
import { BuildListItem, BuildList } from "./ui/build-list";
import { showModal } from "../../shared/showModal";
import { BoostModal, BoostModalProps } from "../../components/Modals/BoostModal";
import { useSelector } from "react-redux";
import {  selectCount } from "../../modules/user/user.selectors.ts";
import text from "../../shared/textContent.json";
import { NotificationModal, NotificationModalProps } from "../../components/Modals/NotificationModal";
import { formatNumber } from "../../shared/formatNumber";
import { useBoosters } from "./hooks/useBoosters.ts";
import { PageRoot } from "../../components/PageRoot";
import { DefaultText } from "../../components/DefaultText";


const BuildPage = () => {
  const count = useSelector(selectCount);
  const {freeBoosters, paidBoosters} = useBoosters();

  const {
    build: {
      balance,
      how_it_works: {
          how_it_works_cta,
          modal: {
            title,
            subtitle,
            cta,
          }
        },
      free,
      upgrades,
    }
  } = text;

  const handleHowInWorksClick = () => {
    showModal<NotificationModal, NotificationModalProps>(NotificationModal, {title, subtitle, buttonText: cta } as NotificationModalProps )
  }



  const handleBoosterClick =
    ({id,
     title,
     description,
     icon,
     price,
     lvl,
     effectName,
     boostValueDescription
     }: BuildListItem) =>
      showModal<never, BoostModalProps>(BoostModal, {
        id,
        title,
        description,
        icon,
        price,
        lvl,
        effectName,
        boostValueDescription
      } as BoostModalProps);

  return (
    <PageRoot>
      <S.WrapperBalance>
        <S.BalanceTitle>{balance}</S.BalanceTitle>
        <S.MoneyCount>{formatNumber(count)}</S.MoneyCount>
        <S.GuideButton onClick={handleHowInWorksClick}>{how_it_works_cta}</S.GuideButton>
      </S.WrapperBalance>

      <S.SectionWrapper>
        <S.SectionTitle>
          {free}
        </S.SectionTitle>

        <S.BoosterWrapper>
          {freeBoosters.map(({icon, title, description, id, price, boostValueDescription}, index) => (
            <S.BoosterTab key={index} onClick={() => handleBoosterClick({
              id,
              title,
              description,
              icon,
              price,
              boostValueDescription
            })}>
              <S.BoosterIcon src={`data:image/png;base64, ${icon}`} alt="Icon" />
              <div>
                <S.BoosterTitle>{title}</S.BoosterTitle>
                <DefaultText>{description}</DefaultText>
              </div>
            </S.BoosterTab>
          ))}
        </S.BoosterWrapper>

      </S.SectionWrapper>

      <BuildList
        name={upgrades}
        list={paidBoosters}
        onClick={handleBoosterClick}
        alignTextItems="center"
      />
    </PageRoot>
  );
};

export default BuildPage;