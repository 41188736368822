import * as S from "./WebView.styles.ts";
import text from "../../shared/textContent.json";
import DroppyImg from "../../assets/drop.png";

export const WebView = () => {
  const {
    "web-view": { title, subtitle, cta }
  } = text;

  return (
    <S.Root>
      <S.Title>{title}</S.Title>
      <S.Subtitle>{subtitle}</S.Subtitle>
      <S.Subtitle>{cta}</S.Subtitle>

      <S.Image>
        <img src={DroppyImg as string} alt="Droppy" />
      </S.Image>
    </S.Root>
  );
};
