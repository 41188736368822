import styled from "styled-components";
import { theme } from "../../../../shared/theme";

export const Root = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DaysWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  gap: 0.5rem;
  justify-items: center;
  align-items: start;
`;

export const Days = styled.p<{ isActive?: boolean; isChecked?: boolean }>`
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 2rem;
  text-align: center;
  margin: 0;
  color: #ffffff;
  border: 1px solid ${({ theme }) => theme.borderModal};
  border-radius: 12px;
  background-color: ${({ isChecked, theme }) => (isChecked ? theme.fontContrast : "unset")};

  opacity: ${({ isActive, isChecked }) => (isActive || isChecked ? 1 : 0.5)};

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  width: 80px;
  height: 120px;
  box-sizing: border-box;
`;

export const DayIcon = styled.img`
  width: 40px;
  height: 40px;
  margin-bottom: 0.2rem;
`;
