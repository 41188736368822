import { FunctionComponent } from "react";
import { Game } from "../../../../modules/games/types.ts";
import { GamesContainer } from "./games-catalogue.styles.ts";
import { GameCard } from "../game-card";

type Props = {
  games: Game[];
  onGameSelect: ({ gameId, gameUUID }: { gameId: number; gameUUID: string }) => void;
};

export const GamesCatalogue: FunctionComponent<Props> = ({ games, onGameSelect }) => {
  return (
    <GamesContainer>
      {games.map((game) => (
        <GameCard key={game.id} game={game} onGameSelect={onGameSelect} />
      ))}
    </GamesContainer>
  );
};
