// Shared styles for modals

import styled from "styled-components";
import { AnimatedText } from "../AnimatedText/";

export type ModalsStyles = {
  marginBottom?: "0rem" | "0.25rem" | "1rem" | "1.5rem" | "2rem" | "3rem";
  fontWeight?: "bold" | "semibold" | "normal";
  width?: "100%" | "90%" | "40%" | "max-content";
  maxWidth?: string;
  padding?: string;
};

export const Root = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  flex-grow: 1;
  max-height: calc(100vh - 2rem);
  overflow: hidden;
`;

export const Box = styled.div<ModalsStyles>`
  margin-bottom: ${({ marginBottom }) => marginBottom ?? "2rem"};
  width: ${({ width }) => width ?? "100%"};
  font-weight: ${({ fontWeight }) => fontWeight ?? "bold"};
  padding: ${({ padding }) => padding ?? "0"};
  max-width: ${({ maxWidth }) => maxWidth ?? "100%"};
`;

export const Title = styled(AnimatedText)<ModalsStyles>`
  line-height: 3.2rem;
  text-align: center;
  margin: 0;
  margin-bottom: ${({ marginBottom }) => marginBottom ?? "1rem"};
`;

export const Subtitle = styled.p<ModalsStyles>`
  font-size: 1rem;
  font-weight: ${({ fontWeight }) => fontWeight ?? "bold"};
  line-height: 1.5rem;
  margin: 0;
  text-align: center;
  margin-bottom: ${({ marginBottom }) => marginBottom ?? "1rem"};
`;