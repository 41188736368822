import styled from "styled-components";
import background from "../../assets/background.png";

export const Wrapper = styled.div`
  overflow: hidden;
`;

export const TextContainer = styled.div`
  display: flex;
  height: 50vh;
  justify-content: center;
  align-items: center;
`;

export const LoaderContainer = styled.div<{ isLoading: boolean }>`
  opacity: ${({ isLoading }) => (isLoading ? 1 : 0)};
  display: ${({ isLoading }) => (isLoading ? "block" : "none")};
  visibility: ${({ isLoading }) => (isLoading ? "visible" : "hidden")};
  transition:
    opacity 1s ease,
    visibility 1s ease;
  position: relative;
  background-image: url(${background});
  background-position: center;
  background-size: cover;
  height: 90vh;
  z-index: 1;
`;

export const ChildrenContainer = styled.div<{ isLoading: boolean }>`
  opacity: ${({ isLoading }) => (isLoading ? 0 : 1)};
  display: ${({ isLoading }) => (isLoading ? "none" : "block")};
  visibility: ${({ isLoading }) => (isLoading ? "hidden" : "visible")};
  transition: opacity 1s ease;
`;
