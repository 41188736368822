import { FunctionComponent } from "react";
import * as S from "../../GameCard.styles.ts";
import { Countdown } from "../../GameCard.styles.ts";
import { GameStatus } from "../../../../../../modules/games/types.ts";
import { AnimatedText } from "../../../../../../components/AnimatedText";
import { DefaultText } from "../../../../../../components/DefaultText";
import { TicketIcon } from "../../assets/TicketIcon.tsx";
import { useCountdown } from "../../../../../../app/hooks/useCountdown.ts";
import text from "../../../../../../shared/textContent.json";

type Props = {
  title: string;
  description: string;
  resultsPublishedAt: string;
  icon: string;
  background: string;
  id: number;
  earnedTickets: string;
  status: GameStatus;
  overallTickets: number;
};

export const FinishedGame: FunctionComponent<Props> = ({
  title,
  description,
  resultsPublishedAt,
  icon,
  background,
  earnedTickets,
  status,
  overallTickets
}) => {
  const { hours, minutes, days } = useCountdown(resultsPublishedAt);
  const {
    games: { resolves_in, archived }
  } = text;

  const countdown = `${days ? `${days}D ` : ""} ${hours}H ${minutes}M`;

  const isFinished = status === GameStatus.Finished;

  const footerText = isFinished ? resolves_in : archived;

  return (
    <S.GameRoot $disabled={!isFinished}>
      <S.Header>
        <S.HeaderContainer>
          <S.TitleContainer>
            <S.Icon src={`data:image/png;base64,${icon}`} alt="icon" />
            <AnimatedText $fontSize="1.25rem" $lineHeight="1.75rem">
              {title}
            </AnimatedText>
          </S.TitleContainer>
          <S.TicketContainer>
            <TicketIcon />
            <DefaultText>{`${earnedTickets} / ${overallTickets}`}</DefaultText>
          </S.TicketContainer>
        </S.HeaderContainer>

        <S.TextWrapper dangerouslySetInnerHTML={{ __html: description }} />
      </S.Header>
      <S.Divider />
      <S.Footer>
        <DefaultText>{footerText}</DefaultText>
        {isFinished ? <Countdown>{countdown}</Countdown> : null}
      </S.Footer>
    </S.GameRoot>
  );
};
