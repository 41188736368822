import * as S from "./Game.styles.ts";

import { useSelector } from "react-redux";
import {
  selectCountdown,
  selectIsStarted
} from "../../../modules/daily_game/daily_game.selectors.ts";

const SECONDS = 1000;
const MINUTES = SECONDS * 60;

export const Countdown = () => {
  const countdown = useSelector(selectCountdown);
  const isRunning = useSelector(selectIsStarted);

  const minutes = Math.floor(countdown / MINUTES);
  const seconds = Math.floor((countdown % MINUTES) / SECONDS);

  const isDanger = minutes < 1 && seconds < 10;

  return (
    <S.Countdown isDanger={isDanger} isRunning={isRunning}>{`${minutes}:${seconds}`}</S.Countdown>
  );
};
