import { useNavigate, useParams } from "react-router-dom";
import DoodleJump from "../doodle-jump";
import { useDispatch, useSelector } from "react-redux";
import { GameType } from "../../../modules/games/types.ts";
import { useCallback, useEffect } from "react";
import {
  getGamesData,
  removeGame,
  setGameByUuid,
  updateGameInGames
} from "../../../modules/games/games.slice.ts";
import {
  selectGame,
  selectGames,
  selectIsGamesLoading
} from "../../../modules/games/game.selectors.ts";
import { DroppyLoader } from "../../../components/DroppyLoader";
import { useActionWhenPathnameChanges } from "../../../app/hooks/useActionWhenPathnameChanges.ts";
import { useThemedToaster } from "../../../app/hooks/useThemedToaster.ts";
import { usePostGameScoreMutation } from "../../../modules/api.ts";
import { PageRoot } from "../../../components/PageRoot";
import { useSignature } from "../../../app/hooks/useSignature.ts";
import { useWasmEncrypt } from "../../../app/hooks/useWasmEncrypt.ts";
import { useErrorHandler } from "../../../app/hooks/useErrorHandler.ts";

export const Game = () => {
  const { gameId } = useParams();
  const dispatch = useDispatch();
  const games = useSelector(selectGames);
  const game = useSelector(selectGame);
  const { generateSignature } = useSignature();
  const { encryptData } = useWasmEncrypt();
  const navigate = useNavigate();
  const gamesLoading = useSelector(selectIsGamesLoading);
  const { showError } = useErrorHandler();

  useActionWhenPathnameChanges({ action: removeGame });

  useEffect(() => {
    dispatch(getGamesData());
  }, [dispatch]);

  useEffect(() => {
    if (games.length) {
      dispatch(setGameByUuid(gameId));
    }
  }, [games, gameId, dispatch]);

  const [postGameScore, { isLoading: ispostingGameScore }] = usePostGameScoreMutation();

  const { toastSuccess } = useThemedToaster();

  const handlePost = useCallback(
    async (score, startedAt) => {
      const key = await generateSignature({ score, gameUuid: gameId });
      const body = {
        gameId,
        body: encryptData(
          JSON.stringify({
            game_started_at: startedAt,
            score: score,
            key
          })
        )
      };

      postGameScore(body)
        .unwrap()
        .then((res) => {
          const { id, end_date, earned_tickets } = res.user_game.game;

          const data = {
            will_end_at: end_date,
            current_available_game_uuid: null,
            earned_tickets: earned_tickets
          };

          const payload = { id, data };
          dispatch(updateGameInGames(payload));
          dispatch(removeGame());
          toastSuccess("Tickets claimed!");
          navigate("/games");
        })
        .catch((err) => {
          showError(err);
        });
    },
    [
      encryptData,
      generateSignature,
      gameId,
      postGameScore,
      dispatch,
      toastSuccess,
      navigate,
    ]
  );

  if (ispostingGameScore || gamesLoading) {
    return (
      <PageRoot>
        <DroppyLoader />
      </PageRoot>
    );
  }

  if (game?.type === GameType.DoodleJump) {
    return <DoodleJump onFinish={handlePost} />;
  }

  return null;
};
