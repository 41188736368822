const getRandomNumber = (topBorder: number) => Math.floor(Math.random() * topBorder);

export const getRandomIndexes = () => {
  const keys = ["a", "b", "c"];

  return keys.reduce((acc, key) => {
    let value;

    do {
      value = getRandomNumber(5);
    } while (Object.values(acc).includes(value));

    acc[key] = value;
    return acc;
  }, {});
}