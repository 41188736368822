import * as S from "../sider.styles.ts";

export const FriendsIcon = () => (
  <S.Icon width="25px" height="24px" viewBox="0 0 25 24">
    <path
      d="M12.25 22C6.72715 22 2.25 17.5228 2.25 12C2.25 6.47715 6.72715 2 12.25 2C17.7728 2 22.25 6.47715 22.25 12C22.25 17.5228 17.7728 22 12.25 22ZM12.25 20C16.6683 20 20.25 16.4183 20.25 12C20.25 7.58172 16.6683 4 12.25 4C7.83172 4 4.25 7.58172 4.25 12C4.25 16.4183 7.83172 20 12.25 20ZM7.25 12H9.25C9.25 13.6569 10.5931 15 12.25 15C13.9069 15 15.25 13.6569 15.25 12H17.25C17.25 14.7614 15.0114 17 12.25 17C9.48858 17 7.25 14.7614 7.25 12Z"
      fill="currentColor"
    />
  </S.Icon>
);
