import React, { FunctionComponent, ReactNode } from "react";
import { CorbadoProvider } from "@corbado/react";
import { Provider } from "react-redux";
import { store } from "../modules/store.ts";
import { ThemeProvider } from "styled-components";
import { theme } from "../shared/theme";
import { TwaAnalyticsProvider } from "@tonsolutions/telemetree-react";
import { createNetworkConfig, SuiClientProvider, WalletProvider } from "@mysten/dapp-kit";
import { getFullnodeUrl } from "@mysten/sui/client";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ReactGA from "react-ga4";
import { HideDevToolsProvider } from "./HideDevToolsProvider.tsx";
import { TelegramMiniAppsAnalyticsProvider } from "./TelegramMiniAppsAnalyticsProvider.tsx";

const CORBADO_PROJECT_ID = import.meta.env.VITE_CORBADO_PROJECT_ID;
const GA_VARIABLE = import.meta.env.VITE_GA_VARIABLE;

type Props = {
  children: ReactNode;
};

// Config options for the networks you want to connect to
const { networkConfig } = createNetworkConfig({
  localnet: { url: getFullnodeUrl("localnet") },
  mainnet: { url: getFullnodeUrl("mainnet") }
});

const queryClient = new QueryClient();

ReactGA.initialize(GA_VARIABLE);

export const Providers: FunctionComponent<Props> = ({ children }) => {
  return (
    <CorbadoProvider projectId={CORBADO_PROJECT_ID}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <TwaAnalyticsProvider
            projectId="b08baf66-7b07-4f5b-9e19-e0d7158e59dd"
            apiKey="a13d6355-9630-468b-9d4b-b553189b4ba9"
            appName="clicker"
          >
            <TelegramMiniAppsAnalyticsProvider>
              <QueryClientProvider client={queryClient}>
                <SuiClientProvider networks={networkConfig}>
                  <HideDevToolsProvider>
                    <WalletProvider
                      stashedWallet={{ name: "Droppy", origin: "https://getstashed.com" }}
                    >
                      {children}
                    </WalletProvider>
                  </HideDevToolsProvider>
                </SuiClientProvider>
              </QueryClientProvider>
            </TelegramMiniAppsAnalyticsProvider>
          </TwaAnalyticsProvider>
        </ThemeProvider>
      </Provider>
    </CorbadoProvider>
  );
};
