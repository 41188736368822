import { OverlayCard } from "../../OverlayCard";
import { FunctionComponent } from "react";
import { BaseModalOptions } from "../../../shared/showModal";
import { Root, Box, Title, Subtitle } from "../Modals.styles.ts";
import text from "../../../shared/textContent.json";
import { Button } from "../../Button";
import { handleLogout } from "../../../modules/auth/auth.slice.ts";
import { useDispatch } from "react-redux";
import {useCorbado} from "@corbado/react";

interface ErrorModalProps extends BaseModalOptions {
  errorText?: string;
}

export const ErrorModal: FunctionComponent<ErrorModalProps> = ({ errorText }) => {
  const { logout } = useCorbado();
  const {
    modals: {
      error: { title, subtitle }
    }
  } = text;
  const dispatch = useDispatch();

  const handleProceed = () => {
    logout().then(() => {
      dispatch(handleLogout());
    });
  };

  return (
    <OverlayCard fullScreen>
      <Root>
        <Box marginBottom="5rem">
          <Title marginBottom="1rem">{title}</Title>
          {errorText ? <Subtitle>{errorText}</Subtitle> : null}
        </Box>
        <Box marginBottom="1rem">
          <Subtitle>{subtitle}</Subtitle>
        </Box>
        <Button onClick={handleProceed}>Proceed</Button>
      </Root>
    </OverlayCard>
  );
};
