import { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import {
  selectDailyCipherCipher,
  selectDailyCipherExamples,
  selectDailyCipherIndexes
} from "../../../../../modules/daily_cipher/daily_cipher.slice.ts";
import { mapDailyCipherIndexes } from "../../mapDailyCipher.ts";
import * as S from "./Cipher.styles.ts";
import { DailyCipherRow } from "../DailyCipherRow";

export const Cipher: FunctionComponent = () => {
  const defaultImgIndexes = useSelector(selectDailyCipherIndexes);
  const examples = useSelector(selectDailyCipherExamples);
  const cipher = useSelector(selectDailyCipherCipher);

  return (
    <S.Root>
      {examples.map(({ example, answer }) => {
        const mappedExample = mapDailyCipherIndexes(example, defaultImgIndexes);

        return <DailyCipherRow rowData={mappedExample} answer={answer} />;
      })}
      <DailyCipherRow rowData={mapDailyCipherIndexes(cipher, defaultImgIndexes)} answer="?" />
    </S.Root>
  );
};
