import { FunctionComponent } from "react";
import diamond from "../../assets/diamond.png";
import fruit from "../../assets/fruit.png";
import glass from "../../assets/glass.png";
import lightning from "../../assets/lightning.png";
import plane from "../../assets/plane.png";
import ring from "../../assets/ring.png";
import * as S from "./DailyCipherRow.styles.ts";

const images = [diamond, fruit, glass, lightning, plane, ring];

type Props = {
  rowData: (string | number)[];
  answer: number;
};
export const DailyCipherRow: FunctionComponent<Props> = ({ rowData, answer }) => {
  return (
    <S.Root>
      {rowData.map((item) => {
        if (typeof item === "number") {
          return <S.CipherIcon src={images[item]} />;
        }

        if (item === "*") {
          return <S.Operator>×</S.Operator>;
        }

        return <S.Operator>{item}</S.Operator>;
      })}
      <S.Operator>=</S.Operator>
      <S.Operator>{answer}</S.Operator>
    </S.Root>
  );
};
