import { ChangeEvent, FunctionComponent } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectDailyCipherAnswer,
  selectDailyCipherUserAnswer,
  setUserAnswer
} from "../../../../../modules/daily_cipher/daily_cipher.slice.ts";
import * as S from "./Answer.styles.ts";
import { Button } from "../../../../Button";
import { useThemedToaster } from "../../../../../app/hooks/useThemedToaster.ts";
import { usePostDailyCipherMutation } from "../../../../../modules/api.ts";
import { setCount, setDailyCipherData } from "../../../../../modules/user/user.slice.ts";
import { useWasmEncrypt } from "../../../../../app/hooks/useWasmEncrypt.ts";
import { useSignature } from "../../../../../app/hooks/useSignature.ts";
import { useErrorHandler } from "../../../../../app/hooks/useErrorHandler.ts";

type Props = {
  onCancel: () => void;
};

export const Answer: FunctionComponent<Props> = ({ onCancel }) => {
  const answer = useSelector(selectDailyCipherAnswer);
  const userAnswer = useSelector(selectDailyCipherUserAnswer);
  const { generateSignature } = useSignature();
  const { encryptData } = useWasmEncrypt();
  const { showError } = useErrorHandler();

  const [postDailyCipher, { isLoading }] = usePostDailyCipherMutation();
  const dispatch = useDispatch();
  const setAnswer = (event: ChangeEvent) => dispatch(setUserAnswer(event.target?.value));
  const { toastError, toastSuccess } = useThemedToaster();

  const handleSolveDailyCipher = async () => {
    if (Number(userAnswer) === answer) {
      const key = await generateSignature();
      const body = encryptData(JSON.stringify(key));

      postDailyCipher(body)
        .unwrap()
        .then((res) => {
          dispatch(setCount(res.user.points));
          dispatch(setDailyCipherData(res.user.next_daily_cipher_available_at));
          toastSuccess("Cipher solved!");
        })
        .catch((err) => {
          showError(err);
        })
        .finally(() => onCancel());

      return;
    }

    dispatch(setUserAnswer(""));
    toastError("The answer is incorrect. Please try again");
  };

  return (
    <S.Root>
      <S.Input placeholder="Your answer" value={userAnswer} onChange={setAnswer} />
      <S.ButtonContainer>
        <Button onClick={handleSolveDailyCipher} disabled={isLoading}>
          Submit
        </Button>
      </S.ButtonContainer>
    </S.Root>
  );
};
