import * as S from "../sider.styles.ts";

export const TasksIcon = () => (
  <S.Icon width="25px" height="24px" viewBox="0 0 25 24">
    <path
      d="M19.75 4H5.75V20H19.75V4ZM3.75 2.9918C3.75 2.44405 4.19749 2 4.7485 2H20.7497C21.3019 2 21.7496 2.44772 21.7497 3L21.75 20.9925C21.75 21.5489 21.3051 22 20.7566 22H4.7434C4.19476 22 3.75 21.5447 3.75 21.0082V2.9918ZM12.0429 13.1213L16.2855 8.87868L17.6997 10.2929L12.0429 15.9497L8.15381 12.0607L9.56802 10.6464L12.0429 13.1213Z"
      fill="currentColor"
    />
  </S.Icon>
);
