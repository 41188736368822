import { useEffect, useState } from "react";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { DAYS, HOURS, MINUTES } from "../../modules/app/constants.ts";

export const useCountdown = (cooldown: string) => {
  const [currentDate, setCurrentDate] = useState(dayjs());
  const cooldownDate = dayjs(cooldown);
  dayjs.extend(isSameOrBefore);

  useEffect(() => {
    if (currentDate.isSameOrBefore(cooldownDate)) {
      const interval = setInterval(() => setCurrentDate(currentDate.add(1, "minute")), MINUTES);

      return () => clearInterval(interval);
    }
  }, [currentDate, cooldownDate]);

  const diff = cooldownDate.diff(currentDate, "milliseconds");

  const days = Math.floor(diff / DAYS);
  const hours = Math.floor((diff % DAYS) / HOURS);
  const minutes = Math.floor((diff % HOURS) / MINUTES);

  return { days, hours, minutes };
};
