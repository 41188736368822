import { useThemedToaster } from "./useThemedToaster.ts";

type AccessError = {
  loc: string[] | number[];
  msg: string;
  type: string;
};

type Data = {
  detail: string | AccessError[];
};

type Error = {
  data: string | Data;
};

export const useErrorHandler = () => {
  const { toastError } = useThemedToaster();

  const showError = (error: Error, onErrorFired = () => {}) => {
    console.log(error.data);
    const { data } = error;

    if (typeof data === "string") {
      toastError(data);
    } else if (typeof data?.detail === "string") {
      toastError(data?.detail);
    } else if (typeof data?.detail[0]?.msg === "string") {
      toastError(data.detail[0].msg);
    } else {
      toastError("Unknown error occurred. Please try again later.");
    }

    onErrorFired();
  };

  return { showError };
};
