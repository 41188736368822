import { State } from "../store.ts";
import { createSelector } from "@reduxjs/toolkit";
import { Game, GamesState } from "./types.ts";

const selectGamesSliceState = (state: State) => state.games;

export const selectIsGamesLoading = createSelector(
  [selectGamesSliceState],
  (gamesSlice) => gamesSlice.loading
);

export const selectGames = createSelector(
  [selectGamesSliceState],
  (gamesSlice: GamesState) => gamesSlice.games
);

export const selectGame = createSelector([selectGamesSliceState], (gamesSlice) => gamesSlice.game);

export const selectGameHighestScore = createSelector(
  [selectGame],
  (game?: Game) => game.highest_score
);
