import { throttle } from "lodash";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../modules/store.ts";
import { MERGE_ANIMATION_DURATION, Moves } from "../../../modules/daily_game/constants.ts";
import {
  moveDown,
  moveLeft,
  moveRight,
  moveUp
} from "../../../modules/daily_game/daily_game.slice.ts";
import { SwipeInput } from "../../../modules/daily_game/types.ts";

export const useBoard = () => {
  const dispatch = useDispatch<AppDispatch>();

  const movesMap = {
    [Moves.Up]: moveUp,
    [Moves.Down]: moveDown,
    [Moves.Left]: moveLeft,
    [Moves.Right]: moveRight
  };

  const moveTiles = useCallback(
    throttle(
      (type: Moves) => {
        const action = movesMap[type];

        dispatch(action());
      },
      MERGE_ANIMATION_DURATION * 1.05,
      {
        trailing: false
      }
    ) as unknown as (type: Moves) => void,
    [dispatch]
  );

  const handleSwipe = useCallback(
    ({ deltaX, deltaY }: SwipeInput) => {
      if (Math.abs(deltaX) > Math.abs(deltaY)) {
        if (deltaX > 0) {
          moveTiles(Moves.Right);
        } else {
          moveTiles(Moves.Left);
        }
      } else {
        if (deltaY > 0) {
          moveTiles(Moves.Down);
        } else {
          moveTiles(Moves.Up);
        }
      }
    },
    [moveTiles]
  );

  return { moveTiles, handleSwipe };
};
