import { FunctionComponent } from "react";
import * as S from "./Countdown.styles.ts";
import { useCountdown } from "../../../../app/hooks/useCountdown.ts";

type Props = {
  cooldown?: string;
};

export const Countdown: FunctionComponent<Props> = ({ cooldown }) => {
  const { minutes, hours } = useCountdown(cooldown);

  return (
    <>
      <S.Cooldown>Cooldown:</S.Cooldown>
      <span>{`${hours}H:${minutes}M`}</span>
    </>
  );
};
