import { Direction, DOODLE_HEIGHT, DOODLE_WIDTH } from "../constants.ts";
import { Obstacle } from "./obstacle.ts";

type DoodleOptions = {
  x: number;
  y: number;
  context: CanvasRenderingContext2D;
  canvasHeight: number;
};

export class Doodle {
  width: number;
  height: number;
  gravity: number;
  x: number;
  y: number;
  context: CanvasRenderingContext2D;
  boostJetpack: number;
  direction: Direction;
  offset: number;
  collisionMode: number;
  player: HTMLImageElement;
  jumpRate: number;
  obstacleArray: Obstacle[];
  canvasHeight: number;

  constructor({ x, y, context, canvasHeight }: DoodleOptions) {
    this.width = DOODLE_WIDTH;
    this.height = DOODLE_HEIGHT;
    this.gravity = 0.15;
    this.x = x;
    this.y = y;
    this.context = context;
    this.boostJetpack = 0;
    this.direction = Direction.Falling;
    this.offset = 0.1;
    this.collisionMode = 1;
    this.player;
    this.jumpRate;
    this.obstacleArray;
    this.canvasHeight = canvasHeight;
  }

  drawDoodle(player: HTMLImageElement) {
    this.player = player;
    this.context.drawImage(this.player, this.x, this.y, this.width, this.height);
  }

  doodleJumpSpeed(score: number) {
    if (score >= 800) {
      this.jumpRate = 16;
    } else if (score >= 600 && score < 800) {
      this.jumpRate = 16;
    } else if (score >= 400 && score < 600) {
      this.jumpRate = 15;
    } else if (score >= 200 && score < 400) {
      this.jumpRate = 13;
    } else if (score >= 50 && score < 200) {
      this.jumpRate = 10;
    } else {
      this.jumpRate = 8;
    }
  }

  moveDoodle(Xchange) {
    this.offset += this.gravity;
    this.y = this.y + this.offset;
    this.x += Xchange;
    if (this.offset >= 0) {
      this.direction = Direction.Falling;
    }

    if (this.offset < 0) {
      this.direction = Direction.Flying;
    }

    if (this.offset < 0 && this.y <= this.canvasHeight / 2) {
      this.y = this.canvasHeight / 2 + 1;
    }
  }

  obstacleCollision(obsArray: Obstacle[]) {
    this.obstacleArray = obsArray;
    const yoffset = 15;
    const heightOffset = 15;
    const xoffset = 15;
    const obstacleOffset = 8;
    for (let k = 0; k < this.obstacleArray.length; k++) {
      if (this.collisionMode == 1) {
        if (
          ((this.y + yoffset <
            this.obstacleArray[k].y + this.obstacleArray[k].height - obstacleOffset &&
            this.y + yoffset >= this.obstacleArray[k].y) ||
            (this.y + this.height >= this.obstacleArray[k].y + heightOffset &&
              this.y + this.height <= this.obstacleArray[k].y + this.obstacleArray[k].height)) &&
          this.x + this.width - xoffset >= this.obstacleArray[k].x &&
          this.x + xoffset <= this.obstacleArray[k].x + this.obstacleArray[k].width
        ) {
          if (this.obstacleArray[k].type != 5 && this.obstacleArray[k].type != 6) {
            return 0;
          } else if (this.obstacleArray[k].type == 5) {
            this.obstacleArray.splice(k, 1);
            k--;
            return 1;
          }

          if (this.obstacleArray[k].type == 6) {
            this.obstacleArray.splice(k, 1);
            k--;
            return 2;
          }
        }
      }

      break;
    }
  }

  jump(platArray, boosterArray) {
    const doodleOffset = 12;
    for (let i = 0; i < platArray.length; i++) {
      if (
        this.y + this.height >= platArray[i].yPosition &&
        this.y + this.height <= platArray[i].yPosition + platArray[i].platformHeight &&
        this.x + this.width - doodleOffset >= platArray[i].xPosition &&
        this.x + doodleOffset <= platArray[i].xPosition + platArray[i].platformWidth &&
        this.direction == Direction.Falling
      ) {
        if (platArray[i].platformType != 3) {
          if (platArray[i].platformType == 4) {
            platArray[i].jumpCount = 1;
          }
          this.offset = -this.jumpRate;
          this.collisionMode = 1;
        } else {
          platArray[i].fakePlatDetect = 1;
        }

        break;
      }
    }

    for (let i = 0; i < boosterArray.length; i++) {
      if (
        boosterArray[i].type == 3 &&
        (this.direction === Direction.Falling || this.direction === Direction.Flying)
      ) {
        const doodleOffset = 7;
        if (
          ((this.y + this.height >= boosterArray[i].y &&
            this.y + this.height <= boosterArray[i].y + boosterArray[i].height) ||
            (this.y - doodleOffset <= boosterArray[i].y + boosterArray[i].height &&
              this.y + doodleOffset >= boosterArray[i].y)) &&
          this.x + this.width >= boosterArray[i].x &&
          this.x <= boosterArray[i].x + boosterArray[i].width &&
          this.direction == Direction.Falling
        ) {
          this.offset = -50;
          this.collisionMode = 0;
          boosterArray.splice(i, 1);
          this.boostJetpack = 1;

          i--;

          break;
        }
      }
    }
  }
}
