import { TILE_COUNTS_PER_DIMENSION } from "../../../modules/daily_game/constants.ts";
import * as S from "./Game.styles.ts";

export const GameGrid = () => {
  const cells = new Array(TILE_COUNTS_PER_DIMENSION * TILE_COUNTS_PER_DIMENSION).fill(undefined);

  return (
    <S.GameGrid>
      {cells.map((_, index) => (
        <S.Cell key={index} />
      ))}
    </S.GameGrid>
  );
};
