import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { AppState } from "./types.ts";
import { Platforms } from "./constants.ts";
import { State } from "../store.ts";
import { isWebView } from "./utils.ts";
import { initTWA } from "../twa/twa.slice.ts";
import { handleAuth } from "../auth/auth.slice.ts";
import { initWasm } from "../../shared/wasmExec/index.js";

const INITIAL_STATE: AppState = {
  platform: null
};

export const initApp = createAsyncThunk<void, void, { state: State }>(
  "app/initApp",
  async (_, { dispatch }) => {
    await dispatch(initTWA());
    await dispatch(checkPlatform());
    await dispatch(handleAuth());
    await dispatch(setWasmScript());
  }
);

const checkPlatform = createAsyncThunk<Platforms, void, { state: State }>(
  "auth/checkPlatform",
  async (arg, { getState, dispatch }) => {
    if (Object.keys(getState().twa.initData).length) {
      dispatch(setPlatform(Platforms.Twa));
      return;
    } else if (isWebView()) {
      dispatch(setPlatform(Platforms.WebView));
      return;
    } else if (window.matchMedia("(display-mode: standalone)").matches) {
      dispatch(setPlatform(Platforms.Pwa));
      return;
    } else {
      dispatch(setPlatform(Platforms.Web));
      return;
    }
  }
);

const setWasmScript = createAsyncThunk<void, void, { state: State }>(
  "app/setWasmScripts",
  async (_) => {
    initWasm();
    const go = new window.Go();
    try {
      const result = await WebAssembly.instantiateStreaming(fetch("/cipher.wasm"), go.importObject);
      go.run(result.instance);
      console.log("WASM loaded successfully");
    } catch (err) {
      console.error("Failed to load WASM:", err);
    }
  }
);

const appSlice = createSlice({
  name: "app",
  initialState: INITIAL_STATE,
  reducers: {
    setPlatform: (state, { payload }: { payload: string }) => {
      state.platform = payload;
    }
  }
});

export const { setPlatform } = appSlice.actions;

export default appSlice.reducer;

const selectAppSliceState = (state: State) => state.app;

export const selectPlatform = createSelector(
  [selectAppSliceState],
  (app: AppState) => app.platform
);

export const selectIsWebView = createSelector(
  [selectPlatform],
  (platform) => platform === Platforms.WebView
);

export const selectIsTwa = createSelector(
  [selectPlatform],
  (platform) => platform === Platforms.Twa
);
