import styled from "styled-components";

export const Root = styled.div`
  display: flex;
  gap: 0.25rem;
  justify-content: space-between;
`;

export const CipherIcon = styled.img`
  width: 2rem;
  height: 2rem;
`;

export const Operator = styled.p`
  margin: 0;
  padding: 0;
  font-weight: 700;
  line-height: 1.75rem;
  font-size: 1.25rem;
`;
