import { OverlayCard } from "../../OverlayCard";
import { FunctionComponent, useCallback } from "react";
import { BaseModalOptions, DefaultData } from "../../../shared/showModal";
import { Box, Root, Subtitle, Title } from "../Modals.styles.ts";
import { Button } from "../../Button/";
import { DailyGame } from "../../DailyGame";
import { useDispatch, useSelector } from "react-redux";
import { clearTiles } from "../../../modules/daily_game/daily_game.slice.ts";
import { usePostDailyGameScoreMutation } from "../../../modules/api.ts";
import {
  setCanPlayDailyGameNow,
  setCount,
  setNextDailyGameAvailableAt
} from "../../../modules/user/user.slice.ts";
import { selectScore, selectStartedAt } from "../../../modules/daily_game/daily_game.selectors.ts";
import text from "../../../shared/textContent.json";
import { useThemedToaster } from "../../../app/hooks/useThemedToaster.ts";
import { useSignature } from "../../../app/hooks/useSignature.ts";
import { useWasmEncrypt } from "../../../app/hooks/useWasmEncrypt.ts";
import { useErrorHandler } from "../../../app/hooks/useErrorHandler.ts";

export const DailyGameModal: FunctionComponent<BaseModalOptions> = ({
  onCancelAction,
  onConfirmAction
}) => {
  const dispatch = useDispatch();
  const score = useSelector(selectScore);
  const startedAt = useSelector(selectStartedAt);
  const { generateSignature } = useSignature();
  const { encryptData } = useWasmEncrypt();
  const { showError } = useErrorHandler();
  const [postDailyGame, { isLoading }] = usePostDailyGameScoreMutation();
  const {
    modals: {
      daily_game: { title, subtitle, cta }
    }
  } = text;

  const { toastSuccess } = useThemedToaster();

  const handleCancel = useCallback(
    (data: DefaultData) => {
      onCancelAction(data);
      dispatch(clearTiles());
      return data;
    },
    [dispatch, onCancelAction]
  );

  const handlePost = useCallback(async () => {
    const key = await generateSignature({ score });
    const body = encryptData(
      JSON.stringify({
        game_started_at: startedAt,
        score: score,
        key
      })
    );

    postDailyGame(body)
      .unwrap()
      .then((res) => {
        dispatch(setCount(res.user.points));
        dispatch(setCanPlayDailyGameNow(res.user.can_play_daily_game_now));
        dispatch(setNextDailyGameAvailableAt(res.user.next_daily_game_available_at));
        toastSuccess("Score claimed!");
      })
      .catch((err) => {
        showError(err);
      })
      .finally(() => handleCancel({}));
  }, [
    encryptData,
    generateSignature,
    handleCancel,
    startedAt,
    score,
    postDailyGame,
    dispatch,
    toastSuccess
  ]);

  return (
    <OverlayCard onCancelAction={handleCancel}>
      <Root>
        <Box>
          <Box marginBottom="1.5rem">
            <Title>{title}</Title>
            <Subtitle>{subtitle}</Subtitle>
          </Box>
          <DailyGame />
        </Box>
        <Button onClick={handlePost} disabled={isLoading}>
          {cta}
        </Button>
      </Root>
    </OverlayCard>
  );
};
