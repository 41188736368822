import * as S from "./sider.styles.ts";
import { useLocation } from "react-router-dom";
import { DroppyIcon } from "./assets/DroppyIcon.tsx";
import { BuildIcon } from "./assets/BuildIcon.tsx";
import { TasksIcon } from "./assets/TasksIcon.tsx";
import { FriendsIcon } from "./assets/FriendsIcon.tsx";
import { GamesIcon } from "./assets/GamesIcon.tsx";
import { Routes } from "./constants.ts";

export const Sider = () => {
  const location = useLocation();

  return (
    <>
      <S.Routing>
        <S.LinkRouter to={Routes.Build} active={location.pathname === Routes.Build}>
          <BuildIcon />

          <S.Text>Build</S.Text>
        </S.LinkRouter>

        <S.LinkRouter to={Routes.Tasks} active={location.pathname === Routes.Tasks}>
          <TasksIcon />

          <S.Text>Tasks</S.Text>
        </S.LinkRouter>

        <S.LinkRouter to={Routes.Main} active={location.pathname === Routes.Main}>
          <DroppyIcon />

          <S.Text>Droppy</S.Text>
        </S.LinkRouter>

        <S.LinkRouter
          to={Routes.Games}
          active={location.pathname === Routes.Games}
          $color="#FFD700"
        >
          <GamesIcon />

          <S.Text>Games</S.Text>
        </S.LinkRouter>

        <S.LinkRouter to={Routes.Friends} active={location.pathname === Routes.Friends}>
          <FriendsIcon />

          <S.Text>Friends</S.Text>
        </S.LinkRouter>
      </S.Routing>
    </>
  );
};
