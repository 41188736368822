import dayjs from "dayjs";
import { HOURS, MINUTES } from "../../../../../modules/app/constants.ts";

export const getHoursAndMinutesDiff = (cooldown: string | null) => {
  if (!cooldown) {
    return { hours: 0, minutes: 0 };
  }

  const currentDate = dayjs();
  const cooldownDate = dayjs(cooldown);
  const diff = cooldownDate.diff(currentDate, "milliseconds");
  const hours = Math.floor(diff / HOURS);
  const minutes = Math.floor((diff % HOURS) / MINUTES);

  return { hours, minutes };
};
