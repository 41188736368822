import { createBrowserRouter, Outlet } from "react-router-dom";
import MainPage from "../../pages/main";
import { Sider } from "./sider.tsx";
import BuildPage from "../../pages/build";
import TasksPage from "../../pages/tasks";
import FriendsPage from "../../pages/friends";
import { useSelector } from "react-redux";
import {
  selectBackgroundEarningInfoPointsToClaim,
  selectIsOnboardingCompleted,
  shouldShowBackgroundClaimModal as shouldShowModal
} from "../../modules/user/user.selectors.ts";
import { useEffect } from "react";
import { showModal } from "../../shared/showModal";
import {
  BackgroundClaimModal,
  BackgroundClaimModalOptions
} from "../../components/Modals/BackgroundClaimModal";
import { Games } from "../../pages/games";
import { Game } from "../../pages/games/[id]";
import { OnboardingModal } from "../../components/Modals/OnboardingModal";

export const RouterLayout = () => {
  const shouldShowBackgroundClaimModal = useSelector(shouldShowModal);
  const isOnboardingCompleted = useSelector(selectIsOnboardingCompleted);
  const pointsToClaim = useSelector(selectBackgroundEarningInfoPointsToClaim);

  useEffect(() => {
    if (shouldShowBackgroundClaimModal && isOnboardingCompleted) {
      showModal<never, BackgroundClaimModalOptions>(BackgroundClaimModal, {
        pointsToClaim
      } as BackgroundClaimModalOptions);
    }
  }, [shouldShowBackgroundClaimModal, pointsToClaim, isOnboardingCompleted]);

  useEffect(() => {
    if (!isOnboardingCompleted) {
      showModal<never, BackgroundClaimModalOptions>(OnboardingModal);
    }
  }, [isOnboardingCompleted]);

  return (
    <>
      <Sider />
      <Outlet />
    </>
  );
};

export const router = createBrowserRouter([
  {
    path: "/",
    element: <RouterLayout />,
    children: [
      {
        path: "/",
        element: <MainPage />
      },
      { path: "/games", element: <Games /> },
      { path: "/games/:gameId", element: <Game /> },
      {
        path: "/build",
        element: <BuildPage />
      },
      {
        path: "/tasks",
        element: <TasksPage />
      },
      {
        path: "/friends",
        element: <FriendsPage />
      }
    ]
  }
]);
