import { OverlayCard } from "../../OverlayCard";
import firstScreen from "/onboarding/1.png";
import secondScreen from "/onboarding/2.png";
import thirdScreen from "/onboarding/3.png";
import fourthScreen from "/onboarding/4.png";
import fifthScreen from "/onboarding/5.png";
import * as S from "./OnboardingModal.styles.ts";

import { FunctionComponent, useCallback, useState } from "react";
import { Button } from "../../Button";
import { useCompleteOnboardingMutation } from "../../../modules/api.ts";
import { setOnboardingCompleted } from "../../../modules/user/user.slice.ts";
import { useDispatch } from "react-redux";
import { BaseModalOptions } from "../../../shared/showModal";
import { SwipeInput } from "../../../modules/daily_game/types.ts";
import { MobileSwiper } from "../../MobileSwiper";
import { useWasmEncrypt } from "../../../app/hooks/useWasmEncrypt.ts";
import { useErrorHandler } from "../../../app/hooks/useErrorHandler.ts";

type OnboardingScreen = {
  image: string;
  cta: string;
};

const ONBOARDING_SCREENS: OnboardingScreen[] = [
  { image: firstScreen, cta: "Let's Go!" },
  { image: secondScreen, cta: "Ok, Next" },
  { image: thirdScreen, cta: "Roger That" },
  { image: fourthScreen, cta: "Start EARN" },
  { image: fifthScreen, cta: "Okay, Let’s Gooo!" }
];

export const OnboardingModal: FunctionComponent<BaseModalOptions> = ({ onCancelAction }) => {
  const [step, setStep] = useState(0);
  const [completeOnboarding, { isLoading }] = useCompleteOnboardingMutation();
  const dispatch = useDispatch();
  const { encryptData } = useWasmEncrypt();
  const { showError } = useErrorHandler();
  const isLastStep = step === ONBOARDING_SCREENS.length - 1;
  const image = ONBOARDING_SCREENS[step].image;
  const text = ONBOARDING_SCREENS[step].cta;

  const handleFinishOnboarding = () => {
    const body = encryptData();
    completeOnboarding(body)
      .unwrap()
      .then((res) => {
        dispatch(setOnboardingCompleted(res));
      })
      .catch((err) => {
        showError(err);
      })
      .finally(() => onCancelAction({}));
  };

  const handleButtonClick = useCallback(
    () => (isLastStep ? handleFinishOnboarding() : setStep((step) => step + 1)),
    [isLastStep]
  );

  const handleSwipe = useCallback(
    ({ deltaX, deltaY }: SwipeInput) => {
      if (Math.abs(deltaX) > Math.abs(deltaY)) {
        const shouldSwipeToLeft = deltaX > 0 && step > 0;
        const shouldSwipeToRight = deltaX < 0 && step < ONBOARDING_SCREENS.length - 1;
        if (shouldSwipeToLeft) {
          setStep((step) => step - 1);
        } else if (shouldSwipeToRight) {
          setStep((step) => step + 1);
        }
      }
    },
    [step, setStep]
  );

  return (
    <OverlayCard fullScreen>
      <S.Root>
        <MobileSwiper onSwipe={handleSwipe}>
          <S.ImageContainer $img={image}>
            <S.ActionsContainer>
              <S.Controls>
                {ONBOARDING_SCREENS.map((_, index) => (
                  <S.Control key={index} $isCurrent={index === step} />
                ))}
              </S.Controls>
            </S.ActionsContainer>
          </S.ImageContainer>
        </MobileSwiper>
        <S.ButtonContainer>
          <Button onClick={handleButtonClick} disabled={isLoading}>
            {text}
          </Button>
        </S.ButtonContainer>
      </S.Root>
    </OverlayCard>
  );
};
