import React, { FC } from "react";
import * as S from "./friends-list.styles";
import FriendRow from "./Row";
import { LeaderboardItem } from "../../../../modules/leaderboard/types.ts";

type CurrentUser = {
  id: number;
  username: string;
  points: number;
  position: number;
  photo_base64?: string | null;
};

type Props = {
  friendsList?: LeaderboardItem[];
  currentUser?: CurrentUser;
};

const FriendsList: FC<Props> = ({ friendsList, currentUser }) => {
  return (
    <S.Root>
      <S.ScrollableWrapper>
        <S.WrapperList>
          {currentUser && (
            <FriendRow
              index={currentUser.position}
              username={currentUser.username}
              points={currentUser.points}
              photo_base64={currentUser.photo_base64}
            />
          )}

          {friendsList?.map((user) => (
            <FriendRow
              key={user.id}
              index={user.position}
              username={user.username}
              points={user.points}
              photo_base64={user.photo_base64}
            />
          ))}
        </S.WrapperList>
      </S.ScrollableWrapper>
    </S.Root>
  );
};

export default FriendsList;
