type BoosterOptions = {
  x: number;
  y: number;
  type: number;
  context: CanvasRenderingContext2D;
  booster: HTMLImageElement;
};

export class Booster {
  x: number;
  y: number;
  booster: HTMLImageElement; // You can replace 'any' with a more specific type if you know what 'booster' represents
  height: number;
  width: number;
  type: number;
  context: CanvasRenderingContext2D;

  constructor({ booster, x, y, context, type }: BoosterOptions) {
    this.x = x;
    this.y = y;
    this.booster = booster;
    this.height = 30;
    this.width = 30;
    this.type = type;
    this.context = context;
  }

  drawBooster() {
    this.context.drawImage(this.booster, this.x, this.y, this.width, this.height);
  }

  moveDownBooster(value) {
    this.y += -value;
  }
}
