import styled from "styled-components";

export const Button = styled.button<{
  variant?: string;
  marginBottom?: string;
  disabled?: boolean;
  isCorbadoStyle?: boolean;
  padding?: string;
}>`
  cursor: pointer;
  width: 100%;
  border-radius: 10px;
  background: ${({ variant }) => variant};
  font-family: ${({ fontFamily }) => fontFamily ?? "inherit"};
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1.25rem;
  padding: ${({ padding }) => padding ?? ".875rem 0"};
  text-align: center;
  margin-bottom: ${({ marginBottom }) => marginBottom ?? "0"};
  color: #fff;
  box-shadow:
    inset 0 4px 4px 0 #ffffff40,
    inset 0 -4px 4px 0 #00000040;

  ${({ disabled }) =>
    disabled &&
    `
    opacity: 0.5;
    box-shadow: none;
    cursor: not-allowed;
  `}

  ${({ isCorbadoStyle }) =>
    isCorbadoStyle &&
    `
    box-shadow: none;
    font-family: 'Space Grotesk', sans-serif;
    font-weight: 700;
    font-size: calc(0.65rem * 1.25);
    
    padding: 0.5rem
    `};
`;
