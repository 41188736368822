export const TILE_COUNTS_PER_DIMENSION = 4;

export const MERGE_ANIMATION_DURATION = 100; // ms

export enum Moves {
  Up = "up",
  Down = "down",
  Left = "left",
  Right = "right"
}

export const COUNTDOWN_START_VALUE = 10 * 60 * 1000;
