import { PageRoot } from "../../components/PageRoot";
import text from "../../shared/textContent.json";
import * as S from "./games.styles.ts";
import { AnimatedText } from "../../components/AnimatedText/AnimatedText.styles.ts";
import { DefaultText } from "../../components/DefaultText";
import { GamesCatalogue } from "./ui/games-catalogue";
import "./doodle-jump/css/layout.css";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getGamesData } from "../../modules/games/games.slice.ts";
import { selectGames, selectIsGamesLoading } from "../../modules/games/game.selectors.ts";
import { DroppyLoader } from "../../components/DroppyLoader";
import { useNavigate } from "react-router-dom";
import { Game } from "../../modules/games/types.ts";
import { useBuyGamesMutation } from "../../modules/api.ts";
import { useWasmEncrypt } from "../../app/hooks/useWasmEncrypt.ts";
import { useErrorHandler } from "../../app/hooks/useErrorHandler.ts";
import { AddressContainer } from "./ui/address-container";

export const Games = () => {
  const {
    games: { title, subtitle, description }
  } = text;

  const isLoading = useSelector(selectIsGamesLoading);
  const games: Game[] = useSelector(selectGames);
  const navigate = useNavigate();
  const [purchaseGame] = useBuyGamesMutation();
  const { encryptData } = useWasmEncrypt();
  const { showError } = useErrorHandler();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getGamesData());
  }, [dispatch]);

  const handleGamePurchase = useCallback(
    ({ gameId, gameUUID }: { gameId: number; gameUUID: string }) => {
      if (gameUUID) {
        navigate(`/games/${gameUUID}`);
        return;
      }

      const body = { gameId, body: encryptData() };

      purchaseGame(body)
        .unwrap()
        .then((res) => {
          navigate(`/games/${res}`);
        })
        .catch((err) => {
          showError(err);
        });
    },
    [dispatch, games, navigate]
  );

  return (
    <PageRoot>
      <S.TitleContainer>
        <AnimatedText>{title}</AnimatedText>
        <S.SubtitleContainer>
          <DefaultText>{subtitle}</DefaultText>
          <DefaultText>{description}</DefaultText>
        </S.SubtitleContainer>
      </S.TitleContainer>
      <AddressContainer />
      <S.Divider />
      {isLoading ? (
        <DroppyLoader />
      ) : (
        <GamesCatalogue games={games} onGameSelect={handleGamePurchase} />
      )}
    </PageRoot>
  );
};
