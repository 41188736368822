import { OverlayCard } from "../../OverlayCard";
import { FunctionComponent } from "react";
import { BaseModalOptions } from "../../../shared/showModal";
import { Root, Box, Title } from "../Modals.styles.ts";
import { AuthProviders } from "../../../modules/auth/types.ts";
import { Button } from "../../Button";
import text from "../../../shared/textContent.json";
import { CorbadoAuth } from "@corbado/react";
import { handleWebAuth } from "../../../modules/auth/auth.slice.ts";
import { AppDispatch } from "../../../modules/store.ts";
import { useDispatch } from "react-redux";
import { theme } from "../../../shared/theme";

export const AuthModal: FunctionComponent<BaseModalOptions> = ({
  onConfirmAction,
  onHideModalAction
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    modals: {
      auth: { title, telegram }
    }
  } = text;

  const onLoggedIn = () => {
    dispatch(handleWebAuth());
    onHideModalAction();
  };

  return (
    <OverlayCard fullScreen>
      <Root>
        <Box marginBottom="5rem">
          <Title marginBottom="0">{title}</Title>
        </Box>
        <Box marginBottom="2rem" width={"100%"} maxWidth={"400px"} padding="1rem">
          <Button
            variant={theme.buttonAquamarine}
            onClick={() => onConfirmAction({ authProvider: AuthProviders.Telegram })}
            isCorbadoStyle={true}
          >
            {telegram}
          </Button>
        </Box>
        <CorbadoAuth onLoggedIn={onLoggedIn} />
      </Root>
    </OverlayCard>
  );
};
