import { FunctionComponent } from "react";
import { Game, GameStatus } from "../../../../modules/games/types.ts";
import { NotStartedGame } from "./components/cards/NotStartedGame.tsx";
import { ActiveGame } from "./components/cards/ActiveGame.tsx";
import { FinishedGame } from "./components/cards/FinishedGame.tsx";

type Props = {
  game: Game;
  onGameSelect: ({ gameId, gameUUID }: { gameId: number; gameUUID: string }) => void;
};

export const GameCard: FunctionComponent<Props> = ({ game, onGameSelect }) => {
  const {
    status,
    id,
    icon,
    earned_tickets,
    background,
    title,
    prize_pool,
    price,
    results_published_at,
    will_start_at,
    will_end_at,
    description,
    current_available_game_uuid,
    overall_tickets_earned
  } = game;

  return (
    <>
      {status === GameStatus.NotStarted ? (
        <NotStartedGame
          id={id}
          willStartAt={will_start_at}
          background={background}
          prizePool={prize_pool}
          icon={icon}
          title={title}
          description={description}
        />
      ) : null}
      {status === GameStatus.Active ? (
        <ActiveGame
          icon={icon}
          title={title}
          description={description}
          prizePool={prize_pool}
          background={background}
          id={id}
          earnedTickets={earned_tickets}
          overallTickets={overall_tickets_earned}
          price={price}
          willEndAt={will_end_at}
          onGameSelect={onGameSelect}
          gameUUID={current_available_game_uuid}
        />
      ) : null}
      {status === GameStatus.Finished || status === GameStatus.Archived ? (
        <FinishedGame
          id={id}
          earnedTickets={earned_tickets}
          overallTickets={overall_tickets_earned}
          background={background}
          icon={icon}
          title={title}
          description={description}
          status={status}
          resultsPublishedAt={results_published_at}
        />
      ) : null}
    </>
  );
};
