import React, { FunctionComponent } from "react";
import { useSuiWallet } from "../../../../app/hooks/useSuiWallet.tsx";
import { Item, WrapperList } from "../../../../components/SectionList/SectionList.styles.tsx";
import * as S from "./AddressContainer.styles.ts";
import { Button } from "../../../../components/Button";
import { truncateAddress } from "./utils/truncateAddress.ts";
import { SuiButton } from "../../../../components/Modals/TaskModal/TaskModal.styles.ts";

export const AddressContainer: FunctionComponent = () => {
  const { isConnecting, isLoading, handleDisconnectWallet, address, isConnected } = useSuiWallet({
    connectedNotificationText: "Wallet connected"
  });

  const text = address ? `Address: ${truncateAddress(address)}` : "No wallet connected";
  const buttonText = address ? "Disconnect" : "Connect";

  return (
    <WrapperList>
      <Item>
        <S.Title>{text}</S.Title>
        <S.ButtonContainer>
          {!address && !isConnected ? (
            <SuiButton connectText={buttonText} disabled={isConnecting} />
          ) : (
            <Button onClick={handleDisconnectWallet} disabled={isLoading}>
              {buttonText}
            </Button>
          )}
        </S.ButtonContainer>
      </Item>
    </WrapperList>
  );
};
