import { Game, GameResponse, GameStatus, GameType } from "../types.ts";
import { formatNumber } from "../../../shared/formatNumber";

type GetGameStatusOptions = {
  now: Date;
  startDate: Date;
  endDate: Date;
  resultsPublishedAt: Date;
};

const getGameStatus = ({
  now,
  startDate,
  endDate,
  resultsPublishedAt
}: GetGameStatusOptions): GameStatus => {
  if (now < startDate) {
    return GameStatus.NotStarted;
  } else if (now >= startDate && now < endDate) {
    return GameStatus.Active;
  } else if (now >= endDate && now < resultsPublishedAt) {
    return GameStatus.Finished;
  } else {
    return GameStatus.Archived;
  }
};

const getGameType = (type: string) => {
  switch (type) {
    case "doodle jump":
      return GameType.DoodleJump;
    default:
      return GameType.Unknown;
  }
};

export const mapGameResponseToState = (games: GameResponse[]): Game[] => {
  const now = new Date();

  return games.map((game) => {
    const startDate = new Date(game.game.start_date);
    const endDate = new Date(game.game.end_date);
    const resultsPublishedAt = new Date(game.game.results_published_at);

    const status = getGameStatus({ now, startDate, endDate, resultsPublishedAt });

    const gameType = getGameType(game.game.type.toLowerCase());

    return {
      id: game.game.id,
      type: gameType,
      title: game.game.title,
      description: game.game.description,
      prize_pool: game.game.first_price_points,
      will_start_at: game.game.start_date,
      will_end_at: game.game.end_date,
      results_published_at: game.game.results_published_at,
      price: game.next_price,
      icon: game.game.icon_base64,
      background: game.game.background_base64,
      earned_tickets: game.tickets_earned,
      status: status,
      current_available_game_uuid: game.current_available_game_uuid,
      highest_score: game.highest_score,
      overall_tickets_earned: formatNumber(game.overall_tickets_earned)
    };
  });
};
