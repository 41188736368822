import * as S from "./DailyWidget.styles.ts";
import { FunctionComponent, memo } from "react";
import { Countdown } from "./Countdown.tsx";

type Props = {
  onClick?: () => void;
  disabled?: boolean;
  widgetIcon: string;
  text: string;
  comingSoon?: boolean;
  cooldown?: string | null;
};

const DailyWidget: FunctionComponent<Props> = ({
  onClick,
  disabled,
  widgetIcon,
  text,
  cooldown
}) => {
  return (
    <S.Root>
      <S.DailyWidget onClick={onClick} $disabled={disabled}>
        <S.DailyWidgetIcon src={widgetIcon}></S.DailyWidgetIcon>
        <S.DailyWidgetText>{text}</S.DailyWidgetText>
      </S.DailyWidget>
      {disabled ? <S.DisabledIcon /> : null}
      {disabled ? <Countdown cooldown={cooldown} /> : null}
    </S.Root>
  );
};

export default memo(DailyWidget);
