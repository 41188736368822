import { Sprites } from "../types.ts";
import { Platform } from "./platform.ts";
import { Obstacle } from "./obstacle.ts";
import { getRandom } from "../utils/getRandom.ts";
import { Doodle } from "./doodle.ts";
import { Booster } from "./booster.ts";
import { Bullet } from "./bullet.ts";
import { JoystickForce, Status } from "../constants.ts";
import { Button } from "./claim-points.ts";
import { NAVBAR_HEIGHT } from "../../../../modules/app/constants.ts";

export type GameOptions = {
  element: HTMLCanvasElement;
  canvasWidth: number;
  canvasHeight: number;
  sprites: Sprites;
  playButton: HTMLButtonElement;
  bodyContainer: HTMLBodyElement;
  startPage: HTMLDivElement;
  mobileInstruction: HTMLDivElement;
  instructions: HTMLDivElement;
  gamepad: HTMLDivElement;
  onFinish: (score: number, startedAt: number) => void;
};

export class Game {
  canvas: HTMLCanvasElement;
  context: CanvasRenderingContext2D | null;
  backgroundImage: HTMLImageElement;
  potHole: HTMLImageElement;
  player: HTMLImageElement;
  playerLeft: HTMLImageElement;
  playerUp: HTMLImageElement;
  uglyEnemy: HTMLImageElement;
  flyingEnemy: HTMLImageElement;
  purpleEnemy: HTMLImageElement;
  booster3: HTMLImageElement;
  boosterJetpack: HTMLImageElement;
  boosterType: number;
  boosterArray: Booster[];
  platformArray: Platform[];
  animatingPlatformArray: Platform[];
  yStorage: number[];
  yPlatform?: number;
  score: number;
  bonusScore: number;
  bullet: Bullet;
  enemyBullet: Bullet;
  bulletArray: Bullet[];
  platformTypes: number[];
  obstacleArray: Obstacle[];
  obstacleType?: number;
  obstacle: Obstacle;
  booster: Booster;
  platformType?: number;
  leftRight: number;
  xPlatform?: number;
  checkSum: number;
  boosterCheck: number;
  doodle: Doodle;
  doodleXchange: number;
  spacePressed: number;
  enemyBulletArray: Bullet[];
  startTime: Date;
  currentTime: Date;
  loopCount: number;
  chance: number;
  doodleClicked: number;
  tapTimer: Date;
  tapElapsedTimer: Date;
  aX?: number;
  aY?: number;
  theta: number[];
  status: string;
  animator?: number;
  playOn: HTMLButtonElement;
  bodyContainer: HTMLBodyElement;
  startPage: HTMLDivElement;
  obstacleTypes: number[];
  mobileInstruction: HTMLDivElement;
  instructions: HTMLDivElement;
  sprites: Sprites;
  previousY: number;
  platforms: Platform;
  newY: number;
  gamepad: HTMLDivElement;
  onFinish: (score: number, startedAt: string) => void;
  startedAt: string;
  constructor({
    element,
    canvasWidth,
    canvasHeight,
    sprites,
    playButton,
    bodyContainer,
    startPage,
    instructions,
    mobileInstruction,
    gamepad,
    onFinish
  }: GameOptions) {
    this.canvas = element;
    this.canvas.width = canvasWidth;
    this.canvas.height = canvasHeight - NAVBAR_HEIGHT;
    this.context = element.getContext("2d");
    this.backgroundImage = sprites.background;
    this.potHole = sprites.potHole;
    this.player = sprites.player;
    this.playerLeft = sprites.playerLeft;
    this.playerUp = sprites.playerUp;
    this.uglyEnemy = sprites.uglyEnemy;
    this.flyingEnemy = sprites.flyingEnemy;
    this.purpleEnemy = sprites.purpleEnemy;
    this.booster3 = sprites.booster3;
    this.boosterJetpack = sprites.boosterJetpack;
    this.sprites = sprites;
    this.boosterType;
    this.obstacleTypes;
    this.boosterArray = [];
    this.platformArray = [];
    this.animatingPlatformArray = [];
    this.yStorage = []; //storing the y-value of platforms to avoid multiple platforms creation in same row.
    this.yPlatform; //yValue for new Platform.
    this.score = 0;
    this.bonusScore = 0;
    this.bullet;
    this.bulletArray = [];
    this.platformTypes = [];
    this.obstacleArray = [];
    this.obstacleType;
    this.obstacle;
    this.booster;
    this.platformType;
    this.leftRight = 0; //flag for giving orientation to scripts on pressing keys or ondevice motion.
    this.xPlatform; //x value for newPlatform
    this.checkSum = 0; // flag to ensure only one obstacle created  at a time.
    this.boosterCheck = 0; //flag to ensure only one booster created  at a time.
    this.doodle;
    this.doodleXchange = 0; //the change in value of X.
    this.spacePressed = 0; //flag to check space pressed for shooting in desktop game.
    this.enemyBulletArray = []; //array for storing enemy bullet.
    this.startTime; //variable for storing  started new Date()
    this.currentTime; //variable for storing  current new Date()
    this.loopCount = 0; //flag for checking production of enemy bulet one in every defined time period.
    this.chance = 3; //chance to be hit by enemy's bullet for the scripts before dying.
    this.doodleClicked = 0; //flag for checkin screen tapped or not in mobile game play.
    this.tapTimer; //variable to store time when the screen is tapped.
    this.tapElapsedTimer; //variable to store elapsed timer after screen is tapped.
    this.aX;
    this.aY;
    this.theta = []; //angle between enemy and scripts;
    this.status = "isPlaying";
    this.animator;
    this.bodyContainer = bodyContainer;
    this.playOn = playButton;
    this.startPage = startPage;
    this.instructions = instructions;
    this.mobileInstruction = mobileInstruction;
    this.previousY;
    this.newY;
    this.platforms;
    this.enemyBullet;
    this.gamepad = gamepad;
    this.setUp();
    this.startedAt = new Date().toISOString();
    this.onFinish = onFinish;
  }

  setUp() {
    this.bodyContainer.style.display = "none";
    this.startPage.style.display = "block";
    if (this.canvas.width < 500) {
      this.mobileInstruction.style.display = "block";
    }
    if (this.canvas.width >= 500) {
      this.instructions.style.display = "block";
    }

    this.playOn.onclick = () => {
      if (this.canvas.width <= 1024) {
        this.gamepad.style.display = "block";
      }
      this.startPage.style.display = "none";
      this.bodyContainer.style.display = "flex";
      this.instructions.style.display = "none";
      this.mobileInstruction.style.display = "none";

      this.init();
      this.newCanvas();
      this.gameLoop();
    };
  }

  init() {
    const doodlePlatfixer = 105; //fixing postion of platform which is always formed below scripts when first drawn
    const doodleFixer = 150; //fixer of postion of scripts at first
    this.platformArray.push(
      new Platform({
        x: this.canvas.width / 2,
        y: this.canvas.height - doodlePlatfixer,
        cxt: this.context,
        type: 1,
        sprites: this.sprites,
        canvasWidth: this.canvas.width
      })
    );
    this.yStorage.push(this.canvas.height - doodlePlatfixer);
    this.newPlatform();
    this.platformArray.sort((a, b) => {
      return a.yPosition - b.yPosition;
    });

    this.doodle = new Doodle({
      x: this.canvas.width / 2,
      y: this.canvas.height - doodleFixer,
      context: this.context,
      canvasHeight: this.canvas.height
    });
  }

  platformSelect() {
    // Platform types
    // 1: Normal
    // 2: Moving
    // 3: Breakable (Go through)
    // 4: Vanishable

    if (this.score >= 800) {
      this.platformTypes = [2, 3, 3, 3, 4, 4, 4, 4];
    } else if (this.score >= 600 && this.score < 800) {
      this.platformTypes = [2, 2, 2, 3, 3, 3, 3, 4, 4, 4, 4];
    } else if (this.score >= 400 && this.score < 600) {
      this.platformTypes = [2, 2, 2, 2, 3, 3, 3, 3, 4, 4];
    } else if (this.score >= 200 && this.score < 400) {
      this.platformTypes = [1, 1, 2, 2, 2, 3, 3, 3, 4, 4];
    } else if (this.score >= 50 && this.score < 200) {
      this.platformTypes = [1, 1, 1, 1, 2, 2, 2, 4];
    } else this.platformTypes = [1];

    this.platformType = this.platformTypes[Math.floor(Math.random() * this.platformTypes.length)];
  }

  boosterSelect() {
    // Removed all boosters except the jetpack. Will add if necessary e.g for game update

    this.boosterType = 3;
  }

  obstacleSelect() {
    // obstacles types
    // 1: pothole
    // 2: horizontal moving bugs
    // 3: oscillating bug
    // 4: vertical moving bugs

    if (this.score >= 800) {
      this.obstacleTypes = [1, 2, 3, 2, 2, 4, 4, 1, 2];
    } else if (this.score >= 600 && this.score < 800) {
      this.obstacleTypes = [1, 2, 3, 3, 4, 4, 4, 4, 3, 2, 2];
    } else if (this.score >= 400 && this.score < 600) {
      this.obstacleTypes = [1, 1, 3, 3, 2, 4, 4, 4, 4];
    } else if (this.score >= 200 && this.score < 400) {
      this.obstacleTypes = [1, 2, 1, 2, 3, 3, 4, 4, 4, 3, 3];
    } else if (this.score >= 50 && this.score < 200) {
      this.obstacleTypes = [1, 1, 2, 2, 4, 3, 3, 4, 4, 3, 1, 3];
    } else this.obstacleTypes = [1, 2];

    this.obstacleType = this.obstacleTypes[Math.floor(Math.random() * this.obstacleTypes.length)];
  }

  newPlatform() {
    const yValueOffset = 50;
    this.platformSelect();
    this.previousY = 0;
    for (let i = 0; i < 5; i++) {
      for (let j = 0; j < 5; j++) {
        this.xPlatform = getRandom(0, this.canvas.width - yValueOffset);
        this.yPlatform = this.previousY + getRandom(50, 65);
        if (this.yPlatform <= this.canvas.height) {
          if (this.yStorage.indexOf(this.yPlatform) === -1) {
            this.previousY = this.yPlatform;
            this.yStorage.push(this.yPlatform);
            this.platforms = new Platform({
              x: this.xPlatform,
              y: this.yPlatform,
              cxt: this.context,
              sprites: this.sprites,
              type: this.platformType,
              canvasWidth: this.canvas.width
            });
            if (this.platforms.removeOverlap(this.platformArray)) {
              //checking whether overlapped steps created or not and neglecting overlapped ones.
              this.platformArray.push(this.platforms);
            }
          }
        }
      }
    }
  }

  newObstacle() {
    this.obstacleSelect();
    const yValueOffset = 50;
    const x = getRandom(0, this.canvas.width - yValueOffset);
    const y = this.platformArray[0].yPosition - getRandom(60, 75);
    if (this.obstacleType === 1) {
      this.obstacle = new Obstacle({
        obstacle: this.potHole,
        x,
        y,
        context: this.context,
        type: this.obstacleType,
        canvasWidth: this.canvas.width
      });
    }
    if (this.obstacleType === 2) {
      this.obstacle = new Obstacle({
        obstacle: this.uglyEnemy,
        x,
        y,
        context: this.context,
        type: this.obstacleType,
        canvasWidth: this.canvas.width
      });
    }
    if (this.obstacleType === 3) {
      this.obstacle = new Obstacle({
        obstacle: this.purpleEnemy,
        x,
        y,
        context: this.context,
        type: this.obstacleType,
        canvasWidth: this.canvas.width
      });
    }
    if (this.obstacleType === 4) {
      this.obstacle = new Obstacle({
        obstacle: this.flyingEnemy,
        x,
        y,
        context: this.context,
        type: this.obstacleType,
        canvasWidth: this.canvas.width
      });
    }

    this.obstacleArray.push(this.obstacle);
  }

  newBooster() {
    this.boosterSelect();
    const yValueOffset = 50;
    const x = getRandom(0, this.canvas.width - yValueOffset);
    const y = this.platformArray[0].yPosition - getRandom(60, 75);

    if (this.boosterType === 3) {
      this.booster = new Booster({
        booster: this.booster3,
        x,
        y,
        context: this.context,
        type: this.boosterType
      });
    }

    this.boosterArray.push(this.booster);
  }

  newCanvas() {
    //reseting the canvas
    this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.context.fillStyle = "black";
    this.context.fillRect(0, 0, this.canvas.width, this.canvas.height);
    this.context.drawImage(this.backgroundImage, 0, 0, this.canvas.width, this.canvas.height);
  }

  eventHandler() {
    //detect the alteration of direction with A and D key.
    //For joystick events XChange is increased to give more accuracy

    document.onkeydown = (event) => {
      if (event.code === "KeyA") {
        this.doodleXchange = -3;
        this.leftRight = 1;
      }

      if (event.code === JoystickForce.FullLeft) {
        this.doodleXchange = -4;
        this.leftRight = 1;
      }

      if (event.code === JoystickForce.TwoThirdLeft) {
        this.doodleXchange = -2;
        this.leftRight = 1;
      }

      if (event.code === JoystickForce.OneThirdLeft) {
        this.doodleXchange = -1;
        this.leftRight = 1;
      }

      if (event.code === "KeyD") {
        this.doodleXchange = 3;
        this.leftRight = 0;
      }

      if (event.code === JoystickForce.FullRight) {
        this.doodleXchange = 4;
        this.leftRight = 0;
      }

      if (event.code === JoystickForce.TwoThirdsRight) {
        this.doodleXchange = 2;
        this.leftRight = 1;
      }

      if (event.code === JoystickForce.OneThirdRight) {
        this.doodleXchange = 1;
        this.leftRight = 1;
      }

      if (event.code === "Space") {
        //event listener for bullet  originated by pressing 'Space'

        this.leftRight = 2;
        if (this.spacePressed === 0) {
          this.bullet = new Bullet({
            xCentre: this.doodle.x + this.doodle.width / 2,
            yCentre: this.doodle.y,
            context: this.context
          });
          this.bulletArray.push(this.bullet);
          this.spacePressed = 1;
        }
      }
    };

    this.canvas.onmousedown = () => {
      this.leftRight = 2;
      this.doodleClicked = 1;
      this.bullet = new Bullet({
        xCentre: this.doodle.x + this.doodle.width / 2,
        yCentre: this.doodle.y,
        context: this.context
      });
      this.bulletArray.push(this.bullet);
      this.tapTimer = new Date();
    };

    document.onkeyup = (event) => {
      //returning to the original picture of the scripts which is facing towards right.

      if (
        event.code === "KeyA" ||
        event.code === JoystickForce.OneThirdLeft ||
        event.code === JoystickForce.TwoThirdLeft ||
        event.code === JoystickForce.FullLeft ||
        event.code === "Space"
      ) {
        //this.accelerometerFlag=true;
        this.leftRight = 0;
      }

      if (event.code === "Space") {
        this.spacePressed = 0;
      }
    };
  }

  doodleChecker() {
    //checks if scripts is outside the canvas and draw on the other side
    if (this.doodle.x > this.canvas.width) {
      this.doodle.x = 0;
    }
    if (this.doodle.x + this.doodle.width < 0) {
      this.doodle.x = this.canvas.width - this.doodle.width;
    }
  }

  platformAlternate() {
    //Alternate platform after the platform 4 is spliced off.
    for (let k = 0; k < this.platformArray.length; k++) {
      if (
        (this.platformArray[k].jumpCount === 1 && this.platformArray[k].platformType === 4) ||
        (this.platformArray[k].fakePlatDetect === 1 && this.platformArray[k].platformType === 3)
      ) {
        if (this.platformArray[k].platformType === 3) {
          this.animatingPlatformArray.push(this.platformArray[k]);
          this.fakePlatAnimation(this.platformArray[k]);
        }
        this.platformArray.splice(k, 1);
        k--;
        this.newY = this.platformArray[0].yPosition - getRandom(60, 70);
        if (this.obstPlatOverlap(this.newY) === 0) {
          this.newY = this.newY - 50;
        }
        if (this.obstPlatOverlap(this.newY) === 1) {
          this.newY = this.newY - 60;
        }
        if (this.obstPlatOverlap(this.newY) === 2) {
          this.newY = this.newY + 35;
        }

        if (this.boosterArray.length != 0) {
          const boostCheck = this.boosterPlatOverlap(this.newY);

          if (boostCheck === 0) {
            this.newY = this.newY - 35;
          } else if (boostCheck === 1) {
            this.newY = this.newY - 50;
          } else if (boostCheck === 2) {
            this.newY = this.newY + 30;
          }
        }
        this.platformType = getRandom(2, 4);
        if (
          this.score < 300 &&
          this.platformArray[0].platformType === this.platformArray[1].platformType &&
          this.platformType === 3 &&
          this.platformArray[0].platformType === this.platformType
        ) {
          this.platformType = 2;
        }
        this.platformArray.splice(
          0,
          0,
          new Platform({
            x: getRandom(0, this.canvas.width - 50),
            y: this.newY,
            cxt: this.context,
            sprites: this.sprites,
            type: this.platformType,
            canvasWidth: this.canvas.width
          })
        );
      }
    }
  }

  obstPlatOverlap(
    y // checking overlap between obstacles and platform
  ) {
    const yValue = y;
    for (let m = 0; m < this.obstacleArray.length; m++) {
      if (
        yValue <= this.obstacleArray[m].y &&
        yValue + this.platformArray[0].platformHeight >= this.obstacleArray[m].y
      ) {
        return 0;
      }

      if (
        yValue >= this.obstacleArray[m].y &&
        yValue <= this.obstacleArray[m].y + this.obstacleArray[m].height
      ) {
        return 1;
      }

      if (
        yValue <= this.obstacleArray[m].y + this.obstacleArray[m].height &&
        yValue > this.obstacleArray[m].y
      ) {
        return 2;
      }
      break;
    }
  }

  boosterPlatOverlap(
    y //checking the overlap between booster and jumping platform.
  ) {
    const yValue = y;
    for (let m = 0; m < this.boosterArray.length; m++) {
      if (
        yValue <= this.boosterArray[m].y &&
        yValue + this.platformArray[0].platformHeight >= this.boosterArray[m].y
      ) {
        return 0;
      }

      if (
        yValue >= this.boosterArray[m].y &&
        yValue <= this.boosterArray[m].y + this.boosterArray[m].height
      ) {
        return 1;
      }

      if (
        yValue <= this.boosterArray[m].y + this.boosterArray[m].height &&
        yValue > this.boosterArray[m].y
      ) {
        return 2;
      }
    }
  }

  obstacleSplice() {
    //splicing of obstacles after it crosses vertical end of screen.
    for (let n = 0; n < this.obstacleArray.length; n++) {
      if (this.obstacleArray[n].y > this.canvas.height) {
        this.obstacleArray.splice(n, 1);
        n--;
      }
    }
  }

  boosterSplice() {
    //splicing the booster after it crosses vertical end of screen
    for (let n = 0; n < this.boosterArray.length; n++) {
      if (this.boosterArray[n].y > this.canvas.height) {
        this.boosterArray.splice(n, 1);
        n--;
      }
    }
  }

  obstaclewiseMotion() {
    //every obstacles have different motion and one of them is static too.
    for (let k = 0; k < this.obstacleArray.length; k++) {
      this.obstacleArray[k].individualMotion();
    }
  }

  bulletObstacleCollide() {
    //collision of scripts's bullet with enemy.
    for (let i = 0; i < this.bulletArray.length; i++) {
      for (let j = 0; j < this.obstacleArray.length; j++) {
        if (
          this.obstacleArray[j].type != 1 &&
          this.obstacleArray[j].type != 5 &&
          this.obstacleArray[j].type != 6
        ) {
          if (
            this.bulletArray[i].yCentre - this.bulletArray[i].radius <=
              this.obstacleArray[j].y + this.obstacleArray[j].height &&
            this.bulletArray[i].yCentre - this.bulletArray[i].radius >= this.obstacleArray[j].y &&
            this.bulletArray[i].xCentre > this.obstacleArray[j].x &&
            this.bulletArray[i].xCentre < this.obstacleArray[j].x + this.obstacleArray[j].width
          ) {
            if (this.obstacleArray[j].type != 6) {
              this.bonusScore = this.bonusScore + 50;
            }
            this.obstacleArray.splice(j, 1);

            j--;
            this.bulletArray.splice(i, 1);
            i--;
          }
        }
      }
    }
  }

  obstacleDoodlePath() {
    //vector implementation for initial path of enemy's bullet.
    for (let k = 0; k < this.obstacleArray.length; k++) {
      if (
        this.obstacleArray[k].type != 1 &&
        this.obstacleArray[k].type != 5 &&
        this.obstacleArray[k].type != 6 &&
        this.doodle.y - this.obstacleArray[k].y - this.obstacleArray[k].height <
          this.canvas.height / 2 + 100 &&
        this.doodle.y - this.obstacleArray[k].y - this.obstacleArray[k].height >= 50
      ) {
        const theta = Math.atan2(
          this.doodle.y - (this.obstacleArray[k].y + this.obstacleArray[k].height),
          this.doodle.x +
            this.doodle.width / 2 -
            this.obstacleArray[k].x -
            this.obstacleArray[k].width / 2
        );
        this.enemyBullet = new Bullet({
          xCentre: this.obstacleArray[k].x + this.obstacleArray[k].width / 2,
          yCentre: this.obstacleArray[k].y + this.obstacleArray[k].height,
          context: this.context
        });
        this.enemyBulletArray.push(this.enemyBullet);
        this.theta.push(theta);
      }
    }
  }

  enemyShotDoodle() {
    //scripts hit by the enemy's bullet
    const doodleYoffset = 20;
    const doodleXoffset = 10;
    for (let h = 0; h < this.enemyBulletArray.length; h++) {
      if (
        this.enemyBulletArray[h].xCentre + this.enemyBulletArray[h].radius >=
          this.doodle.x + doodleXoffset &&
        this.enemyBulletArray[h].xCentre - this.enemyBulletArray[h].radius <=
          this.doodle.x + this.doodle.width - doodleXoffset &&
        this.enemyBulletArray[h].yCentre + this.enemyBulletArray[h].radius >
          this.doodle.y + doodleYoffset &&
        this.enemyBulletArray[h].yCentre + this.enemyBulletArray[h].radius <=
          this.doodle.y + this.doodle.height
      ) {
        if (this.chance != 0) {
          this.chance = this.chance - 1;
        }
        if (this.chance === 0) {
          this.gameOver();
        }

        this.enemyBulletArray.splice(h, 1);
        this.theta.splice(h, 1);
        h--;
        //this.loopCount=0;
        break;
      }
    }
  }

  bulletBulletCollision() {
    //collision of enemy's and scripts's bullet
    for (let r = 0; r < this.bulletArray.length; r++) {
      for (let t = 0; t < this.enemyBulletArray.length; t++) {
        if (
          this.bulletArray[r].xCentre + this.bulletArray[r].radius >=
            this.enemyBulletArray[t].xCentre - this.enemyBulletArray[t].radius &&
          this.bulletArray[r].xCentre - this.bulletArray[r].radius <=
            this.enemyBulletArray[t].xCentre + this.enemyBulletArray[t].radius &&
          this.bulletArray[r].yCentre - this.bulletArray[r].radius <=
            this.enemyBulletArray[t].yCentre + this.enemyBulletArray[t].radius &&
          this.bulletArray[r].yCentre - this.bulletArray[r].radius >=
            this.enemyBulletArray[t].yCentre - this.enemyBulletArray[t].radius
        ) {
          this.bulletArray.splice(r, 1);
          r--;
          this.enemyBulletArray.splice(t, 1);
          this.theta.splice(t, 1);
          t--;

          //this.loopCount=0;
          this.bonusScore = this.bonusScore + 100;
        }
      }
    }
  }

  enemyWarning() {
    // Here used to play sound on enemy approaching.
  }

  fakePlatAnimation(value) {
    const platform = value;
    const animatePlat = setInterval(() => {
      platform.index = (platform.index + 1) % 4;
      if (platform.index === 3) {
        clearInterval(animatePlat);
      }
    }, 65);
  }

  drawRoundedRect(x: number, y: number, width: number, height: number, radius: number) {
    this.context.beginPath();
    this.context.moveTo(x + radius, y);
    this.context.lineTo(x + width - radius, y);
    this.context.quadraticCurveTo(x + width, y, x + width, y + radius);
    this.context.lineTo(x + width, y + height - radius);
    this.context.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
    this.context.lineTo(x + radius, y + height);
    this.context.quadraticCurveTo(x, y + height, x, y + height - radius);
    this.context.lineTo(x, y + radius);
    this.context.quadraticCurveTo(x, y, x + radius, y);
    this.context.closePath();
  }

  gameOver() {
    this.status = "notPlaying";
    //this.clearRect()
    cancelAnimationFrame(this.animator);

    this.gamepad.style.display = "none";

    this.context.save();

    // Затемнение всего фона
    this.context.fillStyle = "rgba(0, 0, 0, 0.5)"; // Черный цвет с прозрачностью 0.5
    this.context.fillRect(0, 0, this.canvas.width, this.canvas.height); // Затемнение по всему холсту

    // Сначала добавляем текст "Game Over"
    this.context.font = "3rem Handjet";
    this.context.fillStyle = "#FEC84D"; // Цвет текста "Game Over"
    this.context.textAlign = "center";
    this.context.fillText("Game Over", this.canvas.width / 2, 150);

    // Добавляем закругленную подложку для текста
    const padding = 1 * 16; // 1rem = 16px
    const rectX = this.canvas.width / 2 - 150;
    const rectY = 200;
    const rectWidth = 300;
    const rectHeight = 150;
    const borderRadius = 16; // Закругление подложки на 1rem (16px)

    this.context.fillStyle = "#190534"; // Цвет подложки
    this.drawRoundedRect(rectX, rectY, rectWidth, rectHeight, borderRadius);
    this.context.fill();

    this.context.font = "2rem Handjet";
    this.context.fillStyle = "#fff";
    this.context.textAlign = "center";
    this.context.fillText(`Score: ${this.score}`, this.canvas.width / 2, 250);
    this.context.font = "2rem Handjet";
    this.context.fillStyle = "#fff";
    this.context.textAlign = "center";
    this.context.fillText(`Bonus: ${this.bonusScore}`, this.canvas.width / 2, 290);
    this.context.font = "2rem Handjet";
    this.context.fillStyle = "#fff";
    this.context.textAlign = "center";
    this.context.fillText(`Total: ${this.bonusScore + this.score}`, this.canvas.width / 2, 330);
    this.context.restore();

    new Button(this.canvas, this.context, this.canvas.width, "Claim tickets", () => {
      this.onFinish(this.score, this.startedAt);
    });
  }

  gameLoop() {
    //main drawing and updating code for the game.
    this.newCanvas();

    this.eventHandler();
    this.tapElapsedTimer = new Date();
    if (this.tapTimer != undefined) {
      if (this.tapElapsedTimer - this.tapTimer <= 650) {
        this.leftRight = 2;
      }
    }
    this.animator = window.requestAnimationFrame(() => this.gameLoop());

    if (this.status === "isPlaying") {
      this.context.fillStyle = "rgba(192,192,192,0.7)";
      this.context.fillRect(0, 0, this.canvas.width, 50);
      this.context.font = "30px Handjet";
      this.context.fillStyle = "yellow";
      this.context.fillText(`${this.score}`, 20, 34);
      this.context.font = "20px Handjet";
      this.context.fillStyle = "black";
      //this.context.fillText(`Chance: ${this.chance}`, 75, 32);
      this.context.font = "20px Handjet";
      this.context.fillStyle = "black";
      //this.context.fillText(`Bonus Score: ${this.bonusScore}`, 160, 32);

      for (let i = 0; i < this.platformArray.length; ++i) {
        this.platformArray[i].drawPlatform();
      }

      for (let i = 0; i < this.platformArray.length; ++i) {
        this.platformArray[i].platformSpeed(this.score);
        this.platformArray[i].movePlatform();
      }

      this.doodle.doodleJumpSpeed(this.score);
      this.doodle.jump(this.platformArray, this.boosterArray);
      this.doodle.moveDoodle(this.doodleXchange);
      this.doodleChecker();

      if (this.doodle.direction === "isFalling" && this.doodle.boostJetpack === 1) {
        this.doodle.boostJetpack = 0;
      }

      if (this.doodle.boostJetpack === 0) {
        if (this.leftRight === 1) {
          this.doodle.drawDoodle(this.playerLeft);
        } else if (this.leftRight === 0) {
          this.doodle.drawDoodle(this.player);
        } else if (this.leftRight === 2) {
          this.doodle.drawDoodle(this.playerUp);
        }
      } else {
        this.doodle.drawDoodle(this.boosterJetpack);
      }

      if (this.bulletArray.length != 0) {
        for (let i = 0; i < this.bulletArray.length; ++i) {
          this.bulletArray[i].bulletMove(0, Status.Doodle);
          this.bulletArray[i].bulletDraw(Status.Doodle);
          if (this.bulletArray[i].yCentre + this.bulletArray[i].radius < 0) {
            this.bulletArray.splice(i, 1);

            i--;
          }
        }
      }

      if (this.score > 0 && this.score % 19 === 0 && this.checkSum === 0) {
        this.newObstacle();
        this.checkSum = 1;
      }

      if (this.obstacleArray.length != 0) {
        for (let j = 0; j < this.obstacleArray.length; j++) {
          this.obstacleArray[j].drawObstacle();
        }
      }

      if (this.obstacleArray.length != 0) {
        if (this.loopCount === 0) {
          this.obstacleDoodlePath();
          this.startTime = new Date();
          this.loopCount = 1;
        } else if (this.loopCount === 1) {
          this.currentTime = new Date();
          if (
            this.currentTime - this.startTime >= 1000 &&
            this.currentTime - this.startTime <= 1050 &&
            this.score <= 250
          ) {
            this.obstacleDoodlePath();
            this.startTime = new Date();
          } else if (
            this.currentTime - this.startTime >= 700 &&
            this.currentTime - this.startTime <= 750 &&
            this.score > 250
          ) {
            this.obstacleDoodlePath();
            this.startTime = new Date();
          }
        }
      } else {
        this.loopCount = 0;
      }

      if (this.enemyBulletArray.length != 0) {
        for (let i = 0; i < this.enemyBulletArray.length; ++i) {
          this.enemyBulletArray[i].bulletMove(this.theta[i], Status.Enemy);
          this.enemyBulletArray[i].bulletDraw(Status.Enemy);
          if (
            this.enemyBulletArray[i].yCentre - this.enemyBulletArray[i].radius >
              this.canvas.height ||
            this.enemyBulletArray[i].xCentre + this.enemyBulletArray[i].radius < 0 ||
            this.enemyBulletArray[i].xCentre - this.enemyBulletArray[i].radius > this.canvas.width
          ) {
            this.enemyBulletArray.splice(i, 1);
            this.theta.splice(i, 1);
            i--;
          }
        }
      }

      if (this.score > 0 && this.score % 43 === 0 && this.boosterCheck === 0) {
        this.newBooster();
        this.boosterCheck = 1;
      }

      if (this.boosterArray.length != 0) {
        for (let l = 0; l < this.boosterArray.length; l++) {
          this.boosterArray[l].drawBooster();
        }
      }

      this.platformAlternate();

      if (this.doodle.y <= this.canvas.height / 2 + 1 && this.doodle.offset < 0) {
        if (this.enemyBulletArray.length != 0) {
          for (let z = 0; z < this.enemyBulletArray.length; z++) {
            this.enemyBulletArray[z].yCentre += -this.doodle.offset;
          }
        }

        if (this.obstacleArray.length != 0) {
          for (let k = 0; k < this.obstacleArray.length; k++) {
            this.obstacleArray[k].moveDownObstacle(this.doodle.offset);
          }
        }

        if (this.boosterArray.length != 0) {
          for (let o = 0; o < this.boosterArray.length; o++) {
            this.boosterArray[o].moveDownBooster(this.doodle.offset);
          }
        }

        if (this.animatingPlatformArray.length != 0) {
          for (let c = 0; c < this.animatingPlatformArray.length; c++) {
            this.animatingPlatformArray[c].yPosition += -this.doodle.offset;
          }
        }

        for (let k = 0; k < this.platformArray.length; k++) {
          this.platformArray[k].yPosition += -this.doodle.offset;
          if (this.platformArray[k].yPosition >= this.canvas.height) {
            this.checkSum = 0;
            this.boosterCheck = 0;

            this.newY = this.platformArray[0].yPosition - getRandom(50, 70);
            if (this.obstacleArray.length != 0) {
              const overlapCheck = this.obstPlatOverlap(this.newY);

              if (overlapCheck === 0) {
                this.newY = this.newY - 35;
              } else if (overlapCheck === 1) {
                this.newY = this.newY - 50;
              } else if (overlapCheck === 2) {
                this.newY = this.newY + 30;
              }
            }

            if (this.boosterArray.length != 0) {
              const boostCheck = this.boosterPlatOverlap(this.newY);

              if (boostCheck === 0) {
                this.newY = this.newY - 35;
              } else if (boostCheck === 1) {
                this.newY = this.newY - 50;
              } else if (boostCheck === 2) {
                this.newY = this.newY + 30;
              }
            }
            this.platformArray.splice(k, 1);
            if (
              this.score < 300 &&
              this.platformArray[0].platformType === this.platformArray[1].platformType &&
              this.platformType === 3 &&
              this.platformArray[0].platformType === this.platformType
            ) {
              this.platformType = 2;
            }
            this.platformArray.splice(
              0,
              0,
              new Platform({
                x: getRandom(0, this.canvas.width - 50),
                y: this.newY,
                cxt: this.context,
                type: this.platformType,
                sprites: this.sprites,
                canvasWidth: this.canvas.width
              })
            );
            this.score += 1;
          }
        }
      }

      this.enemyWarning();
      const value = this.doodle.obstacleCollision(this.obstacleArray);

      if (value === 0) {
        this.gameOver();
      }
      if (value === 2) {
        this.chance = 3;
      }

      this.obstaclewiseMotion();

      if (this.doodle.y + this.doodle.height >= this.canvas.height) {
        this.gameOver();
      }

      if (this.obstacleArray.length != 0 && this.bulletArray != 0) {
        this.bulletObstacleCollide();
      }

      if (this.enemyBulletArray.length != 0) {
        this.enemyShotDoodle();
      }

      if (this.enemyBulletArray.length != 0 && this.bulletArray != 0) {
        this.bulletBulletCollision();
      }

      this.platformSelect();
      this.obstacleSplice();
      this.boosterSplice();

      for (let i = 0; i < this.animatingPlatformArray.length; i++) {
        this.animatingPlatformArray[i].drawPlatform();
        if (this.animatingPlatformArray[i].index === 3) {
          this.animatingPlatformArray.splice(i, 1);
        }
      }
    }
  }
}
