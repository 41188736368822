import { FunctionComponent, ReactNode, useEffect } from "react";

const isProd = import.meta.env.PROD;
import DisableDevtool from "disable-devtool";

type Props = {
  children: ReactNode;
};

export const HideDevToolsProvider: FunctionComponent<Props> = ({ children }) => {
  useEffect(() => {
    if (isProd) {
      /*DisableDevtool({
        disableIframeParents: false // обязательно отключать в mini apps, иначе черный экран при запуске приложения
      });*/
    }
  }, []);

  return children;
};
