import { TOGGLE_MODAL } from "../emitter/constants";
import emitter from "../emitter";

import type { ComponentType } from "react";

export interface BaseModalOptions<R extends DefaultData = DefaultData> extends DefaultOptions {
  onHideModalAction: () => void;
  onConfirmAction: (data: R) => R;
  onCancelAction: (data: R) => R;
  isShow: boolean;
}

export type DefaultData = Record<string, never | unknown>;

type DefaultOptions =
  | (BaseModalOptions & Record<string, string | number | boolean | object>)
  | undefined;

type Result<R> = R & {
  confirm?: boolean;
  cancel?: true;
};

export const showModal = function <
  R extends DefaultData = DefaultData,
  T extends DefaultOptions = DefaultOptions
>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ModalComponent: ComponentType<any>,
  options: T = {} as T
): Promise<Result<R>> {
  return new Promise((resolve, reject) => {
    try {
      emitter.emit(TOGGLE_MODAL, {
        Component: ModalComponent,
        isShow: true,
        options: {
          onHideModalAction: () => {
            emitter.emit(TOGGLE_MODAL, { isShow: false, options: {} });
            resolve({} as Result<R>);
          },
          onConfirmAction: (data: R) => {
            resolve({ confirm: true, ...data });
            emitter.emit(TOGGLE_MODAL, { isShow: false, options: {} });
          },
          onCancelAction: (data: R) => {
            resolve({ cancel: true, ...data });
            emitter.emit(TOGGLE_MODAL, { isShow: false, options: {} });
          },
          ...options
        }
      });
    } catch (error) {
      reject(error);
    }
  });
};
