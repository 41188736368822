import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DailyGameState, Tile, TileMap } from "./types.ts";
import { State } from "../store.ts";
import { flattenDeep, isEqual, isNil } from "lodash";
import { uid } from "uid";
import { createBoard } from "./utils/createBoard.ts";
import { COUNTDOWN_START_VALUE, TILE_COUNTS_PER_DIMENSION } from "./constants.ts";
import { selectEmptyCells, selectIsStarted } from "./daily_game.selectors.ts";

const INITIAL_STATE: DailyGameState = {
  board: createBoard(),
  tiles: {},
  tilesByIds: [],
  hasChanged: false,
  score: 0,
  isStarted: false,
  countdown: COUNTDOWN_START_VALUE,
  startedAt: null
};

export const initGame = createAsyncThunk<void, undefined, { state: State }>(
  "daily_game_slice/initGame",
  async (_, { dispatch, getState }) => {
    if (!selectIsStarted(getState())) {
      await dispatch(toggleGame());
      await dispatch(setStartedAtValue());
      await dispatch(createTile({ tile: { position: [0, 1], value: 2 } }));
      await dispatch(createTile({ tile: { position: [0, 2], value: 2 } }));
    }
  }
);

export const appendRandomTile = createAsyncThunk<void, undefined, { state: State }>(
  "daily_game_slice/appendRandomTile",
  async (_, { dispatch, getState }) => {
    const emptyCells = selectEmptyCells(getState());

    if (emptyCells.length > 0) {
      const cellIndex = Math.floor(Math.random() * emptyCells.length);
      const newTile = {
        position: emptyCells[cellIndex],
        value: 2
      };
      await dispatch(createTile({ tile: newTile }));
    }
  }
);

const gameSlice = createSlice<DailyGameState>({
  name: "daily_game",
  initialState: INITIAL_STATE,
  reducers: {
    clearTiles: (state: DailyGameState) => {
      state.tiles = INITIAL_STATE.tiles;
      state.tilesByIds = INITIAL_STATE.tilesByIds;
      state.score = INITIAL_STATE.score;
      state.isStarted = INITIAL_STATE.isStarted;
      state.countdown = INITIAL_STATE.countdown;
      state.startedAt = INITIAL_STATE.startedAt;
      state.board = INITIAL_STATE.board;
    },
    cleanUp: (state: DailyGameState) => {
      const flattenBoard = flattenDeep(state.board);
      const newTiles: TileMap = flattenBoard.reduce((result, tileId: string) => {
        if (isNil(tileId)) {
          return result;
        }

        return {
          ...result,
          [tileId]: state.tiles[tileId]
        } as TileMap;
      }, {} as TileMap);

      state.tiles = newTiles;
      state.tilesByIds = Object.keys(newTiles);
      state.hasChanged = false;
    },
    toggleGame: (state: DailyGameState) => {
      state.isStarted = !state.isStarted;
    },
    setNewCountdownValue: (state: DailyGameState, { payload }: PayloadAction<number>) => {
      state.countdown = state.countdown - payload;
    },
    setStartedAtValue: (state: DailyGameState) => {
      state.startedAt = new Date().toISOString();
    },
    createTile: (state: DailyGameState, { payload }: PayloadAction<{ tile: Tile }>) => {
      const tileId = uid();
      const [x, y] = payload.tile.position;
      const newBoard = JSON.parse(JSON.stringify(state.board));
      newBoard[y][x] = tileId;

      const tiles = {
        ...state.tiles,
        [tileId]: {
          id: tileId,
          ...payload.tile
        }
      };

      const tilesByID = [...state.tilesByIds, tileId];

      state.board = newBoard;
      state.tiles = tiles;
      state.tilesByIds = tilesByID;
    },
    moveUp: (state: DailyGameState) => {
      const newBoard = createBoard();
      const newTiles: TileMap = {};
      let hasChanged = false;
      let { score } = state;

      for (let x = 0; x < TILE_COUNTS_PER_DIMENSION; x++) {
        let newY = 0;
        let previousTile: Tile | undefined;

        for (let y = 0; y < TILE_COUNTS_PER_DIMENSION; y++) {
          const tileId = state.board[y][x];
          const currentTile = state.tiles[tileId];

          if (!isNil(tileId)) {
            if (previousTile?.value === currentTile.value) {
              score += previousTile.value * 2;
              newTiles[previousTile.id as string] = {
                ...previousTile,
                value: previousTile.value * 2
              };
              newTiles[tileId] = {
                ...currentTile,
                position: [x, newY - 1]
              };
              previousTile = undefined;
              hasChanged = true;
              continue;
            }

            newBoard[newY][x] = tileId;
            newTiles[tileId] = {
              ...currentTile,
              position: [x, newY]
            };
            previousTile = newTiles[tileId];
            if (!isEqual(currentTile.position, [x, newY])) {
              hasChanged = true;
            }
            newY++;
          }
        }
      }

      state.board = newBoard;
      state.tiles = newTiles;
      state.hasChanged = hasChanged;
      state.score = score;
    },
    moveDown: (state: DailyGameState) => {
      const newBoard = createBoard();
      const newTiles: TileMap = {};
      let hasChanged = false;
      let { score } = state;

      for (let x = 0; x < TILE_COUNTS_PER_DIMENSION; x++) {
        let newY = TILE_COUNTS_PER_DIMENSION - 1;
        let previousTile: Tile | undefined;

        for (let y = TILE_COUNTS_PER_DIMENSION - 1; y >= 0; y--) {
          const tileId = state.board[y][x];
          const currentTile = state.tiles[tileId];

          if (!isNil(tileId)) {
            if (previousTile?.value === currentTile.value) {
              score += previousTile.value * 2;
              newTiles[previousTile.id as string] = {
                ...previousTile,
                value: previousTile.value * 2
              };
              newTiles[tileId] = {
                ...currentTile,
                position: [x, newY + 1]
              };
              previousTile = undefined;
              hasChanged = true;
              continue;
            }

            newBoard[newY][x] = tileId;
            newTiles[tileId] = {
              ...currentTile,
              position: [x, newY]
            };
            previousTile = newTiles[tileId];
            if (!isEqual(currentTile.position, [x, newY])) {
              hasChanged = true;
            }
            newY--;
          }
        }
      }
      state.board = newBoard;
      state.tiles = newTiles;
      state.hasChanged = hasChanged;
      state.score = score;
    },
    moveRight(state: DailyGameState) {
      const newBoard = createBoard();
      const newTiles: TileMap = {};
      let hasChanged = false;
      let { score } = state;

      for (let y = 0; y < TILE_COUNTS_PER_DIMENSION; y++) {
        let newX = TILE_COUNTS_PER_DIMENSION - 1;
        let previousTile: Tile | undefined;

        for (let x = TILE_COUNTS_PER_DIMENSION - 1; x >= 0; x--) {
          const tileId = state.board[y][x];
          const currentTile = state.tiles[tileId];

          if (!isNil(tileId)) {
            if (previousTile?.value === currentTile.value) {
              score += previousTile.value * 2;
              newTiles[previousTile.id as string] = {
                ...previousTile,
                value: previousTile.value * 2
              };
              newTiles[tileId] = {
                ...currentTile,
                position: [newX + 1, y]
              };
              previousTile = undefined;
              hasChanged = true;
              continue;
            }

            newBoard[y][newX] = tileId;
            newTiles[tileId] = {
              ...state.tiles[tileId],
              position: [newX, y]
            };
            previousTile = newTiles[tileId];
            if (!isEqual(currentTile.position, [newX, y])) {
              hasChanged = true;
            }
            newX--;
          }
        }
      }
      state.board = newBoard;
      state.tiles = newTiles;
      state.hasChanged = hasChanged;
      state.score = score;
    },
    moveLeft(state: DailyGameState) {
      const newBoard = createBoard();
      const newTiles: TileMap = {};
      let hasChanged = false;
      let { score } = state;

      for (let y = 0; y < TILE_COUNTS_PER_DIMENSION; y++) {
        let newX = 0;
        let previousTile: Tile | undefined;

        for (let x = 0; x < TILE_COUNTS_PER_DIMENSION; x++) {
          const tileId = state.board[y][x];
          const currentTile = state.tiles[tileId];

          if (!isNil(tileId)) {
            if (previousTile?.value === currentTile.value) {
              score += previousTile.value * 2;
              newTiles[previousTile.id as string] = {
                ...previousTile,
                value: previousTile.value * 2
              };
              newTiles[tileId] = {
                ...currentTile,
                position: [newX - 1, y]
              };
              previousTile = undefined;
              hasChanged = true;
              continue;
            }

            newBoard[y][newX] = tileId;
            newTiles[tileId] = {
              ...currentTile,
              position: [newX, y]
            };
            previousTile = newTiles[tileId];
            if (!isEqual(currentTile.position, [newX, y])) {
              hasChanged = true;
            }
            newX++;
          }
        }
      }

      state.board = newBoard;
      state.tiles = newTiles;
      state.hasChanged = hasChanged;
      state.score = score;
    }
  }
});

export default gameSlice.reducer;

export const {
  createTile,
  moveRight,
  moveLeft,
  moveUp,
  moveDown,
  cleanUp,
  setNewCountdownValue,
  toggleGame,
  clearTiles,
  setStartedAtValue
} = gameSlice.actions;
