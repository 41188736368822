export enum Platforms {
  Web = "web",
  Pwa = "pwa",
  Twa = "twa",
  WebView = "web-view"
}

export const SECONDS = 1000;
export const MINUTES = SECONDS * 60;
export const HOURS = MINUTES * 60;
export const DAYS = HOURS * 24;

export const NAVBAR_HEIGHT = 76;
