import { capitalizeFirstLetter } from "../../../shared/capitalizeFirstLetter";
import { PLATFORMS, TASK_TYPES } from "../../../modules/user/types.ts";

export const formatTitle = (type: TASK_TYPES, platform: PLATFORMS) => {
  if (type === TASK_TYPES.Connect) {
    return `${capitalizeFirstLetter(type)} ${capitalizeFirstLetter(platform)}`;
  }

  if (type === TASK_TYPES.Explore) {
    return `${capitalizeFirstLetter(type)} Telegram Apps`;
  }

  if (type === TASK_TYPES.Hold) {
    return "Make a deposit on the AlphaFi-Sui vault";
  }

  return `${capitalizeFirstLetter(type)} on ${capitalizeFirstLetter(platform)}`;
};
