export const formatNumber = (num: number) => {
  if (num > 999) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return num.toString();
};

export function abbreviateNumber(number) {
  if (number === 0) return "0";
  const suffixes = ["", "K", "M", "B", "T"];
  const tier = Math.floor(Math.log10(Math.abs(number)) / 3);

  // Проверка, чтобы не выйти за пределы массива
  if (tier === 0) return number.toString();

  const suffix = suffixes[tier];
  const scale = Math.pow(10, tier * 3);
  const scaledNumber = number / scale;

  return scaledNumber.toFixed(1) + suffix;
}
