import { FunctionComponent, ReactNode, useEffect } from "react";

type Props = {
  children: ReactNode;
};

const TG_ANALYTICS_TOKEN = import.meta.env.VITE_TG_ANALYTICS_TOKEN;

export const TelegramMiniAppsAnalyticsProvider: FunctionComponent<Props> = ({ children }) => {
  useEffect(() => {
    window?.telegramAnalytics?.init({
      token: TG_ANALYTICS_TOKEN,
      appName: "Droppy_On_Sui"
    });
  }, []);

  return children;
};
