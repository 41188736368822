import { FunctionComponent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectIsWebView, selectPlatform } from "../../modules/app/app.slice.ts";

//This is trick to fix broken app in WebView
export const FirebaseNotificationProvider = () => {
  const platform = useSelector(selectPlatform);
  const isWebView = useSelector(selectIsWebView);
  const [Component, setComponent] = useState<null | FunctionComponent>(null);

  useEffect(() => {
    const getComponent = async () => {
      if (platform === null || isWebView) {
        return;
      }

      const module = await import("./FirebaseNotification.tsx");

      const FirebaseNotification = module.default;
      setComponent(FirebaseNotification);
    };

    getComponent();
  }, [platform, isWebView, Component]);

  if (!Component) {
    return null;
  }

  return <Component />;
};
