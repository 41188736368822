import styled from "styled-components";

export const Root = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
`;

export const Input = styled.input`
  border: 2px solid #ffffff26;
  border-radius: 12px;
  padding: 0.75rem 1rem;
  background-color: transparent;
  width: 70%;
  color: #fabcff;
  font-weight: 700;
  line-height: 1.25rem;
  font-size: 1rem;

  &::placeholder {
    color: #ffffff80;
    opacity: 1;
  }

  &::-ms-input-placeholder {
    color: #ffffff80;
  }

  &:active,
  &:focus,
  &:focus-visible {
    border-color: #fabcff;
    outline: none;
  }
`;

export const ButtonContainer = styled.div`
  width: 30%;
`;
