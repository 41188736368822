import { useSelector } from "react-redux";

import { selectId, selectSessionId } from "../../modules/user/user.selectors.ts";
import { useCallback } from "react";
import { PostRequest } from "../../modules/types.ts";

export const useWasmEncrypt = () => {
  const userId = useSelector(selectId);
  const sessionId = useSelector(selectSessionId);

  const encryptData = useCallback(
    (payload = JSON.stringify({})) => {
      try {
        // Ensure payload is valid JSON
        JSON.parse(payload);
      } catch (e) {
        console.error("Invalid JSON payload");
        return;
      }

      if (globalThis.encrypt) {
        try {
          return globalThis.encrypt(userId, sessionId, payload) as PostRequest;
        } catch (err) {
          console.error("Encryption failed:", err);
        }
      } else {
        console.error("WASM module not loaded yet. Please try again in a moment.");
      }
    },
    [userId, sessionId]
  );

  return { encryptData };
};
